/* eslint-disable import/first */
import { useState, useEffect } from "react";
import "./adsEdit.css";
import { useNavigate, useParams } from "react-router-dom";
import "react-dropdown/style.css";
import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../../assets/icons/calendar.png";
import AdsService from "../../../services/adsService";

import { GiCancel } from "react-icons/gi";
import { BiImageAdd } from "react-icons/bi";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import schema from "utils/validation/registers/ads/createAds";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { isAllowAction } from "utils/helper/workerHelper";
import ja from "date-fns/locale/ja";
import { RootState } from "store";
import { isImage, isImageUpload } from "utils/helper/checkImage";
registerLocale("ja", ja);

export type IFileList = {
    file_path: string;
    content_type: string;
    signed_id: string;
}[];

const AdsEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema(t)),
    });

    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAllowAction(userData);

    const [defaultValue, setDefaultValue] = useState<{
        name: string;
        start_date: undefined | Date;
        message: string;
    }>({
        name: "",
        start_date: undefined,
        message: "",
    });
    const [filesListOld, setFilesListOld] = useState<IFileList>([]);
    const [filesList, setFilesList] = useState<IFileList>([]);
    const [files, setFiles] = useState<File[]>([]);

    const handleStartAtChange = (date: Date) => {
        setStartDate(date);
    };

    const getCompanyDetail = async (id: string) => {
        try {
            const response = await AdsService.getAds(id);
            if (response !== null && response !== undefined) {
                let start = new Date(response.ads_management.start_date);
                setStartDate(start);
                setDefaultValue({
                    name: response?.ads_management?.name,
                    start_date: start,
                    message:  response?.ads_management?.message
                });
                setFilesList(response?.ads_management?.files);
                setFilesListOld(response?.ads_management?.files);
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    const handleDeleteFile = (name: string, id: number) => {
        if (files) {
            let current = [...files];
            current = current.filter(
                (file: File, index) => index !== id || file.name !== name
            );
            setFiles(current);
        }
    };

    const handleDeleteFileUploaded = (signed_id: string) => {
        let current = [...filesList];
        current = current.filter(
            (file: { signed_id: string; file_path: string }, index) =>
                file.signed_id !== signed_id
        );
        setFilesList(current);
    };

    const uploadFileList = async (listFiles: FileList | File[]) => {
        let formData = new FormData();
        const filesArr = [...listFiles];
        filesArr.forEach((file, i) => {
            formData.append(`files[]`, file);
        });
        return new Promise(async (resolve, reject) => {
            await AdsService.updateAdsFiles(formData, id)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === "undefined") {
                        reject(err);
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    const deleteFileList = async (listFileDelete: string[]) => {
        listFileDelete &&
            listFileDelete.map(async (item) => {
                await AdsService.deleteAdsFile(id, item);
            });
    };

    const updateFileList = async (files: IFileList, fileOlds: IFileList) => {
        let listFileDelete: string[] = [];
        fileOlds &&
            fileOlds.map((fileOld) => {
                let check = true;
                for (var i = 0; i < files.length; i++) {
                    if (fileOld.signed_id === files[i].signed_id) {
                        check = false;
                        break;
                    }
                }
                if (check) listFileDelete.push(fileOld.signed_id);
            });
        await deleteFileList(listFileDelete);
    };
    useEffect(() => {
        id && getCompanyDetail(id);
    }, [id]);

    useEffect(() => {
        reset(defaultValue);
    }, [defaultValue]);

    const onUpdate = async (data: { name: string; start_date: Date; message: string | undefined }) => {
        await updateFileList(filesList, filesListOld);
        files && files?.length > 0 && (await uploadFileList(files));
        return new Promise(async (resolve, reject) => {
            await AdsService.updateAds(data, id)
                .then(() => {
                    navigate("/ads");
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === "undefined") {
                        // window.location.href = '/login';
                        reject(err);
                    }
                    reject(err);
                });
            resolve(true);
        });
    };
    return (
        <form
            onSubmit={handleSubmit(onUpdate)}
            className="container-company-create-detail d-flex flex-row"
        >
            <div className="company-create-content d-flex flex-column">
                <div
                    className="worker-create-title d-flex flex-column"
                    style={{ alignItems: "flex-start" }}
                >
                    {defaultValue?.name} 編集
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>タイトル</p>
                    <input
                        autoComplete="off"
                        type="text"
                        // required
                        className="create-company-input"
                        {...register("name")}
                    ></input>
                    {errors.name && (
                        <span className="error">{errors.name.message}</span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>メッセージ</p>
                    <textarea
                        autoComplete="off"
                        // required
                        className="create-company-input"
                        {...register("message")}
                    ></textarea>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>開始日</p>
                    <div
                        className="input-date create-company-input"
                        style={{ position: "relative" }}
                    >
                        <Controller
                            name="start_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    selected={startDate}
                                    {...register("start_date")}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        handleStartAtChange(date);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <img
                            className="input-date-img"
                            width={"16px"}
                            height={"16px"}
                            src={calendarIcon}
                        ></img>
                    </div>
                    {errors.start_date && (
                        <span className="error">
                            {errors.start_date.message}
                        </span>
                    )}
                </div>

                <div className="input-add-user margin-input d-flex flex-column multi-file-select">
                    <p>アップロード</p>

                    <>
                        <input
                            type="file"
                            accept="image/*, video/*"
                            multiple
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                e.target.files &&
                                setFiles(files.concat([...e.target.files]))
                            }
                            className="create-company-input"
                            id="files"
                            style={{ display: "none" }}
                        />
                        <label htmlFor="files" className="btn-upload">
                            <BiImageAdd className="add-file" /> アップロード
                        </label>
                    </>
                </div>
                {(filesList?.length > 0 ||
                    (files && [...files]?.length > 0)) && (
                    <div className="image-list">
                        {filesList?.map(
                            (
                                item: {
                                    file_path: string;
                                    signed_id: string;
                                    content_type: string;
                                },
                                id
                            ) => (
                                <div className="img-container-edit" key={id}>
                                    {isImageUpload(
                                        item?.content_type?.split("/")
                                    ) ? (
                                        <img
                                            key={id}
                                            src={item?.file_path}
                                            className="image-ads-edit"
                                        ></img>
                                    ) : (
                                        <video
                                            className="img-container-edit"
                                            width="300px"
                                            height="200px"
                                            controls
                                        >
                                            <source
                                                src={item?.file_path}
                                                type="video/mp4"
                                                className="image-ads-edit"
                                            />
                                        </video>
                                    )}
                                    <GiCancel
                                        className="delete-file"
                                        onClick={() =>
                                            handleDeleteFileUploaded(
                                                item.signed_id
                                            )
                                        }
                                    />
                                </div>
                            )
                        )}

                        {files &&
                            [...files]?.map((item: File, id) => {
                                let type = item.type.split("/");
                                return (
                                    <div
                                        className="img-container-edit"
                                        key={id}
                                    >
                                        {isImageUpload(type) ? (
                                            <img
                                                src={URL.createObjectURL(item)}
                                                className="image-ads-edit"
                                            ></img>
                                        ) : (
                                            <video
                                                className="img-container-edit"
                                                width="300px"
                                                height="200px"
                                                controls
                                            >
                                                <source
                                                    src={URL.createObjectURL(
                                                        item
                                                    )}
                                                    type="video/mp4"
                                                    className="image-ads-edit"
                                                />
                                            </video>
                                        )}
                                        <GiCancel
                                            className="delete-file"
                                            onClick={() =>
                                                handleDeleteFile(item.name, id)
                                            }
                                        />
                                    </div>
                                );
                            })}
                    </div>
                )}

                <div
                    className="create-button-company"
                    style={{ marginTop: "14px" }}
                >
                    <button
                        className="modal-create-user-button1"
                        onClick={() => navigate("/ads")}
                    >
                        キャンセル
                    </button>
                    <button
                        className="modal-create-user-button2"
                        style={{ marginLeft: "5px" }}
                        type="submit"
                        disabled={!isAllow}
                    >
                        登録
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AdsEdit;
