import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Radio, RadioGroup, FormControlLabel, Autocomplete, CircularProgress } from '@mui/material'
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from "../../common/DialogHeader";
import { StyledTextField } from "components/StyledTextField";
import CommonStyledSugorkuDialogWrapper from "../../common/commonSugorokuDialogStyle";
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from "utils/constants";
import { SafetyDocumentTypeEnum, SugorokuIndexEnum } from "utils/enums";
import sugorokuService from "services/sugorokuService";
import { formatDateTime } from 'utils/helper/dateHelper';
import { useFormik, FormikProps } from "formik";
import { IPreparationSafetyDocumentsPayload } from "models"

interface IProps {
  step: number;
  onClose?: any;
  data_id?: number;
  workerOptions: any[];
  onFinish: () => void;
}

interface IPreparationSafetyDocumentsFormikValues {
  safety_document_type?: number | null;
  submitted_at?: Date | null;
  assigned_worker?: any;
  completed_at?: Date | null;
}

const SafetyDocsPrepModal = (props: IProps) => {
  const { step, onClose, data_id, workerOptions, onFinish } = props;
  const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<any>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hasData = !!data_id;
  const { id } = useParams();

  const formik: FormikProps<IPreparationSafetyDocumentsFormikValues> = useFormik<IPreparationSafetyDocumentsFormikValues>({
    initialValues: {
      safety_document_type: null,
      submitted_at: null,
      assigned_worker: null,
      completed_at: null,
    },
    onSubmit: (values: IPreparationSafetyDocumentsFormikValues) => {
      setSubmitLoading(true);
      let data: IPreparationSafetyDocumentsPayload = pick(values);
      if (!id) {
        setSubmitLoading(false);
        return;
      }
    
      data.project_id = +id;
      data.sugoroku_index = SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.value;

      if (values.safety_document_type !== null && values.safety_document_type !== undefined) {
        data.safety_document_type = +values.safety_document_type;
      } else {
        data.safety_document_type = null;
      }

      if (values.completed_at) {
        data.completed_at = formatDateTime(values.completed_at, "yyyy-MM-DD");
      } else {
        data.completed_at = null;
      }
  
      if (values.submitted_at) {
        data.submitted_at = formatDateTime(values.submitted_at, "yyyy-MM-DD");
      } else {
        data.submitted_at = null;
      }
  
      if (values.assigned_worker) {
        data.assigned_worker_id = +values.assigned_worker.value;
      } else {
        data.assigned_worker_id = null;
      }
      
      if (hasData) {
        updateSugoroku(data);
      } else {
        createSugoroku(data);
      }
    }
  });

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const data = await sugorokuService.getSugorokuEventDetail(data_id, Number(id));
      if (data && data.sugoroku_event) {
        const sugorokuData = data.sugoroku_event;
        
        formik.setValues({
          'safety_document_type': sugorokuData.safety_document_type !== null 
            ? SafetyDocumentTypeEnum[sugorokuData.safety_document_type].value 
            : null,
          'submitted_at': sugorokuData.submitted_at
            ? new Date(sugorokuData.submitted_at) 
            : null,
          'assigned_worker': sugorokuData.assigned_worker ? {
            value: String(sugorokuData.assigned_worker.id),
            label: sugorokuData.assigned_worker.name,
            name: sugorokuData.assigned_worker.name,
          } : null,
          'completed_at': sugorokuData.completed_at 
            ? new Date(sugorokuData.completed_at)
            : null,
        });
        setIsEmpty(false)
      } else {
        setIsEmpty(true)
      }
    } catch (error) {
      // error
    } finally {
      setFetchLoading(false);
    }
  }

  const createSugoroku = async (data: IPreparationSafetyDocumentsPayload) => {
    try {
      const responseData = await sugorokuService.createSugorokuEvent(data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // empty
    }
  }

  const updateSugoroku = async (data: IPreparationSafetyDocumentsPayload) => {
    try {
      const responseData = await sugorokuService.updateSugorokuEvent(data_id, data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // epmty
    }
  }

  const onChangeDocumentType = (e) => {
    if (e.target.value === formik.values.safety_document_type) {
      formik.setFieldValue('safety_document_type', null);
    } else {
      formik.setFieldValue('safety_document_type', e.target.value);
    }
  }

  useEffect(() => {
    if (data_id && id) {
      fetchData();
    }
  }, [])

  return (
    <CommonStyledSugorkuDialogWrapper>
        <DialogHeader 
          step={step}
          title={SugorokuIndexEnum.PREPARATION_OF_SAFETY_DOCUMENTS.title}
          onClose={onClose}
          mode={mode}
          setMode={setMode}
          completed={!!formik.values.completed_at}
        />

        {
          fetchLoading && (
            <div className="d-flex justify-center">
              <CircularProgress />
            </div>
          )
        }

        {
          mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
            <>
              <div className="dialog-field-row">
                  <div className="field-label">安全書類</div>
                  <div className="value">
                    {
                      formik.values.safety_document_type === SafetyDocumentTypeEnum['safety_document_paper'].value && data_id 
                        ? "紙"
                        : formik.values.safety_document_type === SafetyDocumentTypeEnum['safety_document_green_sight'].value && data_id
                        ? "グリーンサイト"
                        : !formik.values.safety_document_type && !data_id
                        ? ""
                        : ""
                    }
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">日付</div>
                  <div className="value">{formatDateTime(formik.values.submitted_at, 'yyyy-MM-DD')}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">作成者</div>
                  <div className="value">{formik.values.assigned_worker?.label || ""}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="value">{formatDateTime(formik.values.completed_at, 'yyyy-MM-DD')}</div>
              </div>
            </>
          )
        }

        {
          mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
            <form onSubmit={formik.handleSubmit}>
              <div className="dialog-field-row">
                  <div className="field-label">安全書類</div>
                  <div className="radio-group-wrapper">
                      <RadioGroup
                          aria-label="委託契約書"
                          // name="safety_document_type"
                          value={formik.values.safety_document_type}
                          // onChange={(e) => console.log('e', e)}
                          className="radio-group"
                      >
                          <FormControlLabel
                              value={0}
                              control={<Radio onClick={onChangeDocumentType} />}
                              label="紙"
                          />
                          <FormControlLabel
                              value={1}
                              control={<Radio onClick={onChangeDocumentType} />}
                              label="グリーンサイト"
                          />
                      </RadioGroup>
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">日付</div>
                  <div className="datepicker-wrapper">
                      <DatePicker
                          selected={formik.values.submitted_at}
                          onChange={(date) => {
                            formik.setFieldValue('submitted_at', date);
                          }}
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                      />
                      <img
                          className="calendar-datepicker-icon"
                          src={calendarIcon}
                      ></img>
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">作成者</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={workerOptions.map((item) => ({
                          label: item.label,
                          value: String(item.value),
                          name: item.name
                        }))}
                        value={formik.values.assigned_worker}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('assigned_worker', newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="datepicker-wrapper">
                    <DatePicker 
                        selected={formik.values.completed_at}
                        onChange={(date) => {
                          formik.setFieldValue('completed_at', date);
                        }}
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        locale="ja"
                    />
                    <img
                        className="calendar-datepicker-icon"
                        src={calendarIcon}
                    ></img>
                  </div>
              </div>
              <div className="action-section">
                  <button
                      type="button"
                      className="back-step-edit-button"
                      onClick={onClose}
                  >
                    キャンセル
                  </button>
                  <button 
                    type="submit"
                    className="submit-step-edit-button" 
                    disabled={submitLoading}
                  >
                      {
                        submitLoading ? <CircularProgress color="inherit" size={18} /> : "登録"
                      }
                  </button>
              </div>
            </form>
          )
        }
    </CommonStyledSugorkuDialogWrapper>
  )
}

export default SafetyDocsPrepModal