import { useEffect, useState } from 'react';
import './WorkingReport.css';
import 'react-step-progress/dist/index.css';
import reportsService from 'services/reportsService';
import { formatDateTime } from 'utils/helper/dateHelper';
import WorkingReportCreate from './WorkingReportCreate';
import { RootState, useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
    setAddSuccess,
    setEditSuccess,
    setDeleteSuccess,
    setFetching,
    setReports,
    setSelectedReport,
    setShowModalDelete,
    setShowModalDetail,
    setShowModalUpdate,
    setTempFilterReset
} from 'store/workingReport/workingReport';
import WorkingReportDelete from './WorkingReportDelete';
import WorkingReportFilter from './WorkingReportFilter';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import styled from 'styled-components';
import {
    Paper,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableBody,
} from '@mui/material';
import { IPageQueries } from 'models';
import CustomPagination from 'components/CustomPagination';

const StyledTable = styled(Table)(({ theme }) => ({
    tableLayout: 'fixed',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const WorkingReportList = ({ currentProject }) => {
    const [Modal, open, close, isOpen] = useModal('root', {
        // preventScroll: true,
        closeOnOverlayClick: false,
    });
    const [ModalDelete, openDeleteModal, closeDeleteModal, isOpenDelete] =
        useModal('root', {
            // preventScroll: true,
            closeOnOverlayClick: false,
        });
    const {
        showModalDetail = false,
        showModalCreate = false,
        showModalUpdate = false,
        showModalDelete = false,
        addSuccess = false,
        editSuccess = false,
        deleteSuccess = false,
    } = useSelector((state: RootState) => state.workingReport);
    let { id } = useParams();
    const dispatch = useAppDispatch();
    const {
        reports = [] as any[],
        filter = {},
        fetching = false,
    } = useSelector((state: RootState) => state.workingReport);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
      page: 1,
      per_page: 20
    });

    const getListReports = async ({ page = 1, per_page = 0, data = {} }) => {
        try {
            dispatch(setFetching(true));
            const response = await reportsService.getListReports({
                project_id: id,
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                dispatch(setReports(response.working_reports));
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        } finally {
            dispatch(setFetching(false));
        }
    };

    const openShow = async (record) => {
        try {
            const response = await reportsService.getReport(record?.id, {
                project_id: id,
            });
            if (response) {
                dispatch(setSelectedReport(response.working_report));
                dispatch(setShowModalDetail(true));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const openEdit = async (record) => {
        try {
            const response = await reportsService.getReport(record?.id, {
                project_id: id,
            });
            if (response) {
                dispatch(setSelectedReport(response.working_report));
                dispatch(setShowModalUpdate(true));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const openDelete = (record) => {
        dispatch(setSelectedReport(record));
        dispatch(setShowModalDelete(true));
    };

    useEffect(() => {
        if (id) {
            getListReports({ page: pageQueries.page, per_page: pageQueries.per_page });
        }
    }, [id]);

    useEffect(() => {
        if (filter) {
            if (pageQueries.page !== 1) {
              setPageQueries((prevState) => ({ ...prevState, page: 1 }))
            }
            getListReports({ 
              page: 1, 
              per_page: pageQueries.per_page, 
              data: { ...filter }
            });
        }
    }, [filter]);

    useEffect(() => {
        if (showModalCreate || showModalDetail || showModalUpdate) {
            open();
        } else {
            dispatch(setSelectedReport({}));
            close();
        }
    }, [showModalDetail, showModalCreate, showModalUpdate]);

    useEffect(() => {
        if (showModalDelete) {
            openDeleteModal();
        } else {
            closeDeleteModal();
        }
    }, [showModalDelete]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(setAddSuccess(false));
            if (pageQueries.page !== 1) {
              setPageQueries((prevState) => ({ ...prevState, page: 1 }))
            }
            // getListReports({
            //   page: 1, 
            //   per_page: pageQueries.per_page, 
            //   data: { ...filter }
            // });

            dispatch(setTempFilterReset(true));
        }
    }, [addSuccess]);

    useEffect(() => {
        if (editSuccess) {
            dispatch(setEditSuccess(false));
            if (pageQueries.page !== 1) {
              setPageQueries((prevState) => ({ ...prevState, page: 1 }))
            }
            // getListReports({
            //   page: 1, 
            //   per_page: pageQueries.per_page, 
            //   data: { ...filter }
            // });
            dispatch(setTempFilterReset(true));
        }
    }, [editSuccess]);

    useEffect(() => {
      if (deleteSuccess) {
        dispatch(setDeleteSuccess(false));
        if (pageQueries.page !== 1) {
          setPageQueries((prevState) => ({ ...prevState, page: 1 }))
        }
        getListReports({
          page: 1,
          per_page: pageQueries.per_page,
          data: { ...filter }
        })
      }
    }, [deleteSuccess])

    return (
        <>
            <WorkingReportFilter />
            <div className="table-workers d-flex flex-column mt-[16px]">
                <div className="table-content d-flex flex-column">
                    <TableContainer
                        component={Paper}
                        // table-layout={'fixed'}
                    >
                        <StyledTable
                            sx={{ minWidth: 1350 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        style={{width: '20%'}}
                                    >
                                        作業日
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        style={{width: '10%'}}
                                    >
                                        発行日
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        style={{width: '10%'}}
                                    >
                                        送信日
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="center"
                                        >
                                        作業内容
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow"
                                        align="right"
                                        style={{width: '20%'}}
                                    >
                                        <div
                                            style={{ marginRight: '110px' }}
                                        >
                                            操作
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reports !== null &&
                                    reports.map((record, ind) => (
                                        <StyledTableRow key={ind}>
                                            <StyledTableCell
                                                // className="text-overflow"
                                                align="center"
                                                scope="row"
                                            >
                                                {`${formatDateTime(
                                                    record?.from_at,
                                                    'YYYY/MM/DD',
                                                )} - ${formatDateTime(
                                                    record?.to_at,
                                                    'YYYY/MM/DD',
                                                )}`}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                            >
                                                {formatDateTime(
                                                    record?.published_at,
                                                    'YYYY/MM/DD',
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                            >
                                                {formatDateTime(
                                                    record?.submitted_at,
                                                    'YYYY/MM/DD',
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="whitespace-nowrap text-ellipsis overflow-hidden"
                                                align="center"
                                            >
                                                {
                                                  record?.working_logs?.reduce((accu, log, idx, workingLogsArray) => {
                                                    if (idx === workingLogsArray.length - 1) {
                                                      accu = accu + log?.name
                                                    } else {
                                                      accu = accu + log?.name + ", "
                                                    }
                                                    return accu
                                                  }, "")
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow"
                                                align="center"
                                            >
                                                <div className="flex gap-x-[16px] justify-end">
                                                    <button
                                                        className="bg-main px-[20px] py-[4px] rounded-[6px] text-white"
                                                        onClick={() =>
                                                            openShow(record)
                                                        }
                                                    >
                                                        詳細
                                                    </button>
                                                    <button
                                                        className="bg-main px-[20px] py-[4px] rounded-[6px] text-white"
                                                        onClick={() =>
                                                            openEdit(record)
                                                        }
                                                    >
                                                        編集
                                                    </button>
                                                    <button
                                                        className="bg-red px-[20px] py-[4px] rounded-[6px] text-white"
                                                        onClick={() =>
                                                            openDelete(record)
                                                        }
                                                    >
                                                        削除
                                                    </button>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                {/* {reports?.length === 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            className="text-overflow"
                                            align="center"
                                            colSpan={5}
                                        >
                                            データなし
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )} */}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </div>
            </div>
            {
              reports && reports.length > 0 && (
                <CustomPagination 
                  totalCount={totalCount}
                  pageQueries={pageQueries}
                  setPageQueries={setPageQueries}
                  fetchList={(page) => getListReports({ page, per_page: pageQueries.per_page, data: { ...filter }})}
                />
              )
            }
            <Modal>
                <WorkingReportCreate currentProject={currentProject} />
            </Modal>
            <ModalDelete>
                <WorkingReportDelete />
            </ModalDelete>
        </>
    );
};

export default WorkingReportList;
