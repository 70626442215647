import { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from "@mui/material/CircularProgress";
import DeleteConfirmDialogWrapper from './style';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type IProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClick: () => void;
    deleteLoading: boolean;
};

const DeleteConfirmationDialog: FC<IProps> = ({ open, setOpen, onClick, deleteLoading }) => {
    return (
        <BootstrapDialog
            className="customBootstrapDialog deleteConfirmationDialog"
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DeleteConfirmDialogWrapper>
                <DialogContent>
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか？
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        className="modal-create-user-button2 d-flex flex-row"
                        style={{
                            background: '#FF5045',
                            width: 152,
                        }}
                        onClick={onClick}
                        type="button"
                        disabled={deleteLoading}
                    >
                        {deleteLoading ? <CircularProgress size={16} sx={{ color: "#fff" }} /> : "はい"}
                    </button>
                    <button
                        className="modal-create-plan-button1 d-flex flex-row"
                        style={{
                            width: 152,
                        }}
                        type="button"
                        onClick={() => setOpen(false)}
                    >
                        いいえ
                    </button>
                </DialogActions>
            </DeleteConfirmDialogWrapper>
        </BootstrapDialog>
    );
};

export default DeleteConfirmationDialog;
