import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { DataManager } from '@syncfusion/ej2-data';
import { RootState } from '../../../store/index';
import scheduleService from 'services/scheduleService';
import { getDateTimeUtc, getMondayOfWeek, getSundayOfWeek } from 'utils/helper/dateHelper';
import { uniqBy } from "utils/helper/helpers";
import { isUserGuest } from 'utils/helper/workerHelper';
import { IDeparment2 } from 'pages/schedules/FilterBox/hook';
import { alertError } from 'utils/helper/appHelper';

export type ReceivedProps = {
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteRepeatScheduleDialog: Dispatch<SetStateAction<boolean>>;
    setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
    searchParam: any;
    openDeleteConfirmationDialog: boolean;
    openDeleteRepeatScheduleDialog: boolean;
    openScheduleFormDialog: boolean;
    openPlanUpdateModal: boolean;
    workerOptions: {
        id: any;
        name: string;
        departments: any;
        position: any;
        departments2: IDeparment2[]
    }[];
    activeTab: number;
    isShowDefaultDate: boolean;
    setIsShowDefaultDate: Dispatch<SetStateAction<boolean>>;
};

export const SWITCHER_LIST = [
    {
        id: 'Day',
        name: '日',
    },
    {
        id: 'Week',
        name: '週',
    },
    {
        id: 'Month',
        name: '月',
    },
];

export interface CustomizedState {
    editingId: number;
}

const useSchedulerBox = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const { scheduleView, scheduleCurrentDate } = useSelector((state: RootState) => state.schedule);

    const [currentViewName, setCurrentViewName] = useState<string>('Week');
    const [monthAndListView, setMonthAndListView] = useState(false);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [localScheduleDate, setLocalScheduleData] = useState<any[]>();
    const [dataManager, setDataManager] = useState<DataManager>();
    const [currentItemId, setCurrentItemId] = useState<any>(null);
    const [scheduleList, setScheduleList] = useState<any>({
        data: [],
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (scheduleView && scheduleCurrentDate) {
            setCurrentViewName(scheduleView);
            setCurrentDate(scheduleCurrentDate);
        }
    }, [scheduleView, scheduleCurrentDate]);

    const commitChanges = () => { };
    let checkUserGuest = isUserGuest(userData);

    const fetchSchedulesForMonthAndList = async () => {
      const queryFilter = {
          ...props.searchParam,
          attribute_worker_ids:
              props.activeTab === 0
                  ? [userData.worker?.id]
                  : props.searchParam.attribute_worker_ids,
      };

      Object.keys(queryFilter).forEach((key) => {
          if ([undefined, null, ''].includes(queryFilter[key])) {
              delete queryFilter[key];
          }
      });

      try {
          setLoading(true);
          const { schedules, meta } = await scheduleService.getSchedulesSP({
              page: 1,
              per_page: 9999,
              ...queryFilter,
          });

          // console.log('response schedules', schedules);
          // console.log('response plans', meta);

          const plansData = meta.plans.map((item) => ({
              ...item,
              color: '#009F08',
              startDate: item.start_at,
              endDate: moment(item.end_at).add(1, 'day').format('YYYY-MM-DD'),
              allDay: true,
              projectName:
                  meta.projects.find((obj) => obj.id === item.project_id)
                      ?.name || '',
              workerId: item.assigned_worker_id,
          }));

          const schedulesData = schedules.map((item) => ({
              ...omit(item, ['start_date', 'end_date']),
              startDate: moment(item.start_date).zone('+0900').format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment(item.end_date).zone('+0900').format('YYYY-MM-DD HH:mm:ss'),
              allDay: item.is_all_day,
              workerId: item.worker?.id,
              title2: item?.title
          }));

          const newArr = !checkUserGuest ? [...schedulesData, ...plansData] : [...schedulesData, ...plansData]?.filter(item => item?.meeting_rooms?.length > 0);

          setScheduleList({
              data: newArr.sort(
                  (d1, d2) =>
                      new Date(d1.startDate).getTime() -
                      new Date(d2.startDate).getTime(),
              ),
          });
          setLoading(false);
      } catch (error) {
          setLoading(false);
      }
    }

    const fetchSchedules = useCallback(async () => {
        // if (!userData.worker) return;
        if (currentViewName === "Month" || currentViewName === "List") {
          return;
        }
        let startDate = '';
        let endDate = '';
         if (currentViewName === 'Day') {
             const formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD');
             startDate = formattedCurrentDate;
             endDate = formattedCurrentDate;
         }

        if (currentViewName === 'Week') {
            const mondayOfTheWeek = getMondayOfWeek(currentDate);
            startDate = moment(mondayOfTheWeek).format('YYYY-MM-DD');

            const sundayOfTheWeek = getSundayOfWeek(currentDate);
            endDate = moment(sundayOfTheWeek).format('YYYY-MM-DD')
        }

        const queryFilter = (startDate !== '' && endDate !== '') ? {
            ...props.searchParam,
            attribute_worker_ids:
                props.activeTab === 0
                    ? [userData.worker?.id]
                    : props.searchParam.attribute_worker_ids,
            start_date: startDate,
            end_date: endDate,
        } : {
            ...props.searchParam,
            attribute_worker_ids:
                props.activeTab === 0
                    ? [userData.worker?.id]
                    : props.searchParam.attribute_worker_ids,
        };

        Object.keys(queryFilter).forEach((key) => {
            if ([undefined, null, ''].includes(queryFilter[key])) {
                delete queryFilter[key];
            }
        });

        try {
            setLoading(true);
            const { schedules, meta } = await scheduleService.getSchedulesSP({
                page: 1,
                per_page: 9999,
                ...queryFilter,
            });

            // console.log('response schedules', schedules);
            // console.log('response plans', meta);

            const plansData = meta.plans.map((item) => ({
                ...item,
                color: '#009F08',
                startDate: item.start_at,
                endDate: moment(item.end_at).add(1, 'day').format('YYYY-MM-DD'),
                allDay: true,
                projectName:
                    meta.projects.find((obj) => obj.id === item.project_id)
                        ?.name || '',
                workerId: item.assigned_worker_id,
            }));

            const schedulesData = schedules.map((item) => ({
                ...omit(item, ['start_date', 'end_date']),
                startDate: moment(item.start_date).zone('+0900').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(item.end_date).zone('+0900').format('YYYY-MM-DD HH:mm:ss'),
                allDay: item.is_all_day,
                workerId: item.worker?.id,
                title2: item?.title
            }));

            const newArr = !checkUserGuest ? [...schedulesData, ...plansData] : [...schedulesData, ...plansData]?.filter(item => item?.meeting_rooms?.length > 0);

            setScheduleList({
                data: newArr.sort(
                    (d1, d2) =>
                        new Date(d1.startDate).getTime() -
                        new Date(d2.startDate).getTime(),
                ),
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [currentViewName, props.searchParam, props.activeTab, userData.worker?.id, currentDate, checkUserGuest]);

    const deleteSchedule = async () => {
        setDeleteLoading(true);
        try {
            await scheduleService.deleteScheduleSP(currentItemId);
            if (currentViewName === "Day" || currentViewName === "Week") {
              fetchSchedules();
            } else if (currentViewName === "Month" || currentViewName === "List") {
              fetchSchedulesForMonthAndList();
            }
          } catch (error) {
            const errorMessage = error?.response?.data?.error;
            alertError(errorMessage);
          } finally {
          props.setOpenDeleteConfirmationDialog(false);
          props.setOpenScheduleFormDialog(false);
          setDeleteLoading(false);
        }
    };

    const deleteRepeatSchedule = async (duplicate_apply_type: string) => {
      setDeleteLoading(true);
      try {
        await scheduleService.deleteSchedule(currentItemId, duplicate_apply_type);
        if (currentViewName === "Day" || currentViewName === "Week") {
          fetchSchedules();
        } else if (currentViewName === "Month" || currentViewName === "List") {
          fetchSchedulesForMonthAndList();
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.error;
        alertError(errorMessage);
      } finally {
        props.setOpenDeleteRepeatScheduleDialog(false);
        props.setOpenScheduleFormDialog(false);
        setDeleteLoading(false);
      }
    }  

    useEffect(() => {
        if (currentViewName === 'Month') {
            let counting = 0;
            let prevItemDate: any = null;

            let schedulerDataForMonth: any = [];
            let scheduleWorker: any = [];

            scheduleList.data.forEach((item) => {
                const currentItemDate = moment(item.startDate).format(
                    'YYYY-MM-DD',
                );
                const workerScheduleIds = item?.worker_schedules?.map((item: { worker_id: number; }) => item?.worker_id);
                const workerScheduleAttributesAgree = item?.worker_schedule_attributes?.filter((item: { is_agree: boolean; }) => item.is_agree === true);
                if(item?.schedule_maker_is_agree) {
                    workerScheduleAttributesAgree.push({name: item?.worker?.name, worker_id: item?.worker?.id})
                }
                if (currentItemDate === prevItemDate && counting < 2) {
                    counting += 1;
                    if (props.activeTab === 0) {
                        if (item?.worker?.id === userData?.worker?.id || workerScheduleIds?.includes(userData?.worker?.id)) {
                            schedulerDataForMonth = [
                                ...schedulerDataForMonth,
                                {
                                    ...item,
                                    WorkerId: item.worker && item.worker.id,
                                    title:
                                        item.project_id || item.allDay
                                            ? `${moment(item.startDate).format("DD")}日 ~ ${moment(
                                                item.endDate,
                                            ).format("DD")}日 ${item.projectName
                                                ? `${item.projectName} ~ ${item.name}`
                                                : `${item.title}`
                                            } ${workerScheduleAttributesAgree?.length > 0
                                                ? workerScheduleAttributesAgree
                                                    ?.map((v) => v.name)
                                                    .toString().replaceAll(',', ' ')
                                                : ""
                                            }`
                                            : `${moment(item.startDate).format("HH:mm")} ${item.title} ${workerScheduleAttributesAgree?.length > 0
                                                ? workerScheduleAttributesAgree
                                                    ?.map((v) => v.name)
                                                    .toString().replaceAll(',', ' ')
                                                : ""
                                            }`,
                                },
                            ];
                        }
                    } else {
                        schedulerDataForMonth = [
                            ...schedulerDataForMonth,
                            {
                                ...item,
                                WorkerId: item.worker && item.worker.id,
                                title:
                                    item.project_id || item.allDay
                                        ? `${moment(item.startDate).format("DD")}日 ~ ${moment(
                                            item.endDate,
                                        ).format("DD")}日 ${item.projectName
                                            ? `${item.projectName} ~ ${item.name}`
                                            : `${item.title}`
                                        } ${workerScheduleAttributesAgree?.length > 0
                                            ? workerScheduleAttributesAgree
                                                ?.map((v) => v.name)
                                                .toString().replaceAll(',', ' ')
                                            : ""
                                        }`
                                        : `${moment(item.startDate).format("HH:mm")} ${item.title} ${workerScheduleAttributesAgree?.length > 0
                                            ? workerScheduleAttributesAgree
                                                ?.map((v) => v.name)
                                                .toString().replaceAll(',', ' ')
                                            : ""
                                        }`,
                            },
                        ];
                        scheduleWorker = [
                            ...scheduleWorker,
                            {
                                worker: item.worker && item.worker.id,
                            }
                        ]
                    }
                } else if (currentItemDate === prevItemDate && counting === 2) {
                    counting += 1;

                    const getCurrentDateLength = scheduleList.data.filter(
                        (childItem) =>
                            moment(childItem.startDate).format('YYYY-MM-DD') ===
                            currentItemDate,
                    );
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: `他の${getCurrentDateLength.length - 3}件`,
                            showWeekView: true,
                        },
                    ];
                } else if (currentItemDate !== prevItemDate) {
                    counting = 0;
                    prevItemDate = moment(item.startDate).format('YYYY-MM-DD');
                    if (props.activeTab === 0) {
                        if (item?.worker?.id === userData?.worker?.id || workerScheduleIds?.includes(userData?.worker?.id)) {
                            schedulerDataForMonth = [
                                ...schedulerDataForMonth,
                                {
                                    ...item,
                                    WorkerId: item.worker && item.worker.id,
                                    title:
                                        item.project_id || item.allDay
                                            ? `${moment(item.startDate).format("DD")}日 ~ ${moment(
                                                item.endDate,
                                            ).format("DD")}日 ${item.projectName
                                                ? `${item.projectName} ~ ${item.name}`
                                                : `${item.title}`
                                            } ${workerScheduleAttributesAgree?.length > 0
                                                ? workerScheduleAttributesAgree
                                                    ?.map((v) => v.name)
                                                    .toString().replaceAll(',', ' ')
                                                : ""
                                            }`
                                            : `${moment(item.startDate).format("HH:mm")} ${item.title} ${workerScheduleAttributesAgree?.length > 0
                                                ? workerScheduleAttributesAgree
                                                    ?.map((v) => v.name)
                                                    .toString().replaceAll(',', ' ')
                                                : ""
                                            }`,
                                },
                            ];
                        }
                    } else {
                        schedulerDataForMonth = [
                            ...schedulerDataForMonth,
                            {
                                ...item,
                                WorkerId: item.worker && item.worker.id,
                                title:
                                    item.project_id || item.allDay
                                        ? `${moment(item.startDate).format("DD")}日 ~ ${moment(
                                            item.endDate,
                                        ).format("DD")}日 ${item.projectName
                                            ? `${item.projectName} ~ ${item.name}`
                                            : `${item.title}`
                                        } ${workerScheduleAttributesAgree?.length > 0
                                            ? workerScheduleAttributesAgree
                                                ?.map((v) => v.name)
                                                .toString().replaceAll(',', ' ')
                                            : ""
                                        }`
                                        : `${moment(item.startDate).format("HH:mm")} ${item.title} ${workerScheduleAttributesAgree?.length > 0
                                            ? workerScheduleAttributesAgree
                                                ?.map((v) => v.name)
                                                .toString().replaceAll(',', ' ')
                                            : ""
                                        }`,
                            },
                        ];
                        scheduleWorker = [
                            ...scheduleWorker,
                            {
                                worker: item.worker && item.worker.id,
                            }
                        ]
                    }
                }
            });

            setLocalScheduleData(schedulerDataForMonth);
            const formattedEj2ScheduleList = schedulerDataForMonth.map((item) => {
                if (item.project_id) {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.projectName,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1],
                    };
                } else {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.title2,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1],
                    };
                }
            })
            const manager = new DataManager(formattedEj2ScheduleList);
            setDataManager(manager);
        } else {
            let scheduleWorker: any = [];

            setLocalScheduleData(scheduleList.data);
            const formattedEj2ScheduleList = scheduleList.data.map((item) => {
                scheduleWorker = [
                    ...scheduleWorker,
                    {
                        worker: item.worker && item.worker.id,
                    }
                ]
                if (item.project_id) {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.projectName,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1],
                    };
                } else {
                    return {
                        ...item,
                        WorkerId: item.worker && item.worker.id,
                        Id: item.id,
                        Subject: item.title2,
                        StartTime: item.startDate,
                        EndTime: item.endDate,
                        color: item.color || "#195192",
                        ConferenceId:
                            item?.worker_schedule_attributes?.length > 0
                                ? (item?.worker_schedule_attributes
                                    .map((v) => v.worker_id)
                                    .includes(item.worker && item.worker.id || -1)
                                    ? item?.worker_schedule_attributes
                                    : [
                                        ...item?.worker_schedule_attributes,
                                        ...[{
                                            id: item?.worker_schedule_attributes?.length + 1,
                                            name: item.worker && item.worker.name,
                                            worker_id: item.worker && item.worker.id
                                        }]]
                                )?.map((v) => v.worker_id)
                                : [item.worker && item.worker.id || -1],
                    };
                }
            })
            const manager = new DataManager(formattedEj2ScheduleList);
            setDataManager(manager);
        }
    }, [scheduleList.data, currentViewName]);

    useEffect(() => {
      if (currentViewName === "Month" || currentViewName === "List") {
        setMonthAndListView(true);
      } else {
        setMonthAndListView(false);
      }
    }, [currentViewName]);

    useEffect(() => {
        fetchSchedules();
    }, [fetchSchedules]);

    useEffect(() => {
      if (monthAndListView) {
        fetchSchedulesForMonthAndList();
      }
    }, [monthAndListView, props.searchParam, props.activeTab, userData.worker?.id, checkUserGuest]);

    return {
        ...props,
        loading,
        currentViewName,
        currentDate,
        localScheduleDate,
        scheduleList,
        deleteSchedule,
        setCurrentItemId,
        fetchSchedules,
        fetchSchedulesForMonthAndList,
        commitChanges,
        setLocalScheduleData,
        setCurrentDate,
        setCurrentViewName,
        dataManager,
        userData,
        deleteRepeatSchedule,
        deleteLoading,
    };
};

export type Props = ReturnType<typeof useSchedulerBox>;

export default useSchedulerBox;
