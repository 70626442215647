import type { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import {
    MonthView,
    WeekView,
    DayView,
    AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import classNames from 'classnames';
import { useAppDispatch } from 'store';
import { updateScheduleCurrentDate, updateScheduleView } from 'store/schedule/schedule';

export const WeekViewTimeTableCell = (props) => {
    const { startDate } = props;
    const date = new Date(startDate);

    const today = date.getDate() === new Date().getDate();
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    return (
        <WeekView.TimeTableCell
            {...props}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const WeekViewDayScaleCell = (props) => {
    const dispatch = useAppDispatch();
    const { startDate, today } = props;
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    const weekDays = ['日', '月', '火', '水', '木', '金', '土'];

    const formatDate = (nextDate: SchedulerDateTime, nextOptions: Intl.DateTimeFormat) => {
        if (nextOptions.hasOwnProperty('weekday')) {
            const weekDayIndex = new Date(nextDate).getDay();
            return weekDays[weekDayIndex];
        } else {
            return String(new Date(nextDate).getDate());
        }
    }

    return (
        <WeekView.DayScaleCell
            {...props}
            formatDate={formatDate}
            onClick={() => {
                dispatch(updateScheduleView("Day"));
                dispatch(updateScheduleCurrentDate(new Date(startDate)));
            }}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const WeekViewTimeScaleLayout = (props) => {
    return (
        <WeekView.TimeScaleLayout 
            {...props}
            height={50}
            style={{
                // visibility: 'hidden'
                display: 'none'
            }}
        />
    )
}

export const MonthViewTimeTableCell = (props) => {
    const { startDate } = props;
    const date = new Date(startDate);

    const today =
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth();
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    const formatDate = (nextDate: SchedulerDateTime, nextOptions) => {
        return String(new Date(nextDate).getDate());
    }

    return (
        <MonthView.TimeTableCell
            {...props}
            onDoubleClick={() => props.onDoubleClick(startDate)}
            formatDate={formatDate}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const MonthViewDayScaleCell = (props) => {
    const { startDate, today } = props;
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    return (
        <MonthView.DayScaleCell
            {...props}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const DayViewTimeTableCell = (props) => {
    const { startDate } = props;
    const date = new Date(startDate);

    const today =
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth();
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    return (
        <DayView.TimeTableCell
            {...props}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const DayViewDayScaleCell = (props) => (
    <DayView.DayScaleCell {...props} className={classNames('dayViewTH')} />
);

export const AllDayViewTimeTableCell = (props) => {
    const { startDate } = props;
    const date = new Date(startDate);

    const today =
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth();
    const saturday = startDate.getDay() === 6;
    const sunday = startDate.getDay() === 0;

    return (
        <AllDayPanel.Cell
            {...props}
            className={classNames(
                today ? 'today' : '',
                saturday ? 'saturday' : '',
                sunday ? 'sunday' : '',
            )}
        />
    );
};

export const AllDayViewTimeTableRow = (props) => (
    <AllDayPanel.Row {...props} className="allDayRow" />
);
