import Api from './api/adsApi';
import pick from 'lodash/pick';

type InputType = {
    page: number;
    per_page: number;
}

const getAdsManagements = async (input: InputType) => {
    let data = pick(input, ['page', 'per_page']);
    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const adsService = {
  getAdsManagements,
};

export default adsService;
