import Api from './api/workingLogApi';
import pick from 'lodash/pick';
// import projectList from '../shared/mocks/projectLists.json';
import moment from 'moment';

const getListWorkingLogs = async (input: any = {}) => {
    // return projectList; // TODO remove debug
    let data = pick(input, ['page', 'per_page']);
    if (input.project_id) {
        data.project_id = input.project_id;
    }
    if (input?.worker) {
        data.worker_id = input.worker.value;
    }
    if (input?.start_at) {
        data.start_at = moment(input.start_at)
            .startOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.end_at) {
        data.end_at = moment(input.end_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.plan) {
        data.plan_id = input.plan.value;
    }
    if (input?.free_word) {
        data.free_word = input?.free_word;
    }
    const params = new URLSearchParams(data);
    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        // window.location.href = '/login';
        return null;
    }
};

const getWorkingLog = async (id: Number, input: any = {}) => {
    let data = pick(input);
    if (input.project_id) {
        data.project_id = input.project_id;
    }
    const params = new URLSearchParams(data);
    return await Api()
        .get(`${id}?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createWorkingLog = async (input) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .post('', input, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return Promise.reject();
        }
    } else {
        window.location.href = '/login';
    }
};

const updateWorkingLog = async (body, id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .put(`/${id}`, body, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return Promise.reject();
        }
    } else {
        window.location.href = '/login';
    }
};

const deleteWorkingLog = async (id, project_id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(`/${id}?project_id=${project_id}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            // window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const updateImages = async (body, id, project_id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .put(
                    `/${id}/add_images?project_id=${project_id}&id=${id}`,
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return Promise.reject();
        }
    } else {
        window.location.href = '/login';
    }
};

const deleteImage = async (id, project_id, signed_id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(
                    `/${id}/delete_image?project_id=${project_id}&id=${id}&signed_id=${signed_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                    },
                )
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            // window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const workingLogService = {
    getListWorkingLogs,
    getWorkingLog,
    createWorkingLog,
    updateWorkingLog,
    deleteWorkingLog,
    deleteImage,
    updateImages,
};

export default workingLogService;
