import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const CaretUp: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 10l5-5 5 5"
            ></path>
        </svg>
    );
};

export default CaretUp;
