// Lib
import { required } from "utils/validation/registers/validator";

// Type
import { TypeOfT } from "utils/type/translations/Translation.type";
import * as yup from "yup";

const schema = (t: TypeOfT) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(required("company_name", t))
      .trim(required("company_name", t)),
    parent_company_id: yup
      .object()
      .required(required("parent_company", t)),
    email: yup
      .string()
      .required(required("email", t))
      .trim(required("email", t)),
    fax: yup.string().required(required("fax", t)).trim(required("fax", t)),
    zipcode: yup
      .string()
      .required(required("zipcode", t))
      .trim(required("zipcode", t)),
    address: yup
      .string()
      .required(required("address", t))
      .trim(required("address", t)),
    date_of_establishment: yup
      .string()
      .required(required("date_of_establishment", t))
      .trim(required("date_of_establishment", t)),
    capital: yup
      .string()
      .required(required("capital", t))
      .trim(required("capital", t)),
    number_of_employees: yup
      .string()
      .required(required("number_of_employees", t))
      .matches(/^[0-9]*$/, `従業員数は無効な形式です。`),
    phone: yup
      .string()
      .required(required("phone", t))
      .trim(required("phone", t)),
    business_content: yup
      .string()
      .required(required("business_content", t))
      .trim(required("business_content", t)),
  });
};
export default schema;
