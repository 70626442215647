import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ChatBox from '../ChatBox';
import { Main } from './style';
import useChat, { Props } from './hook';

const ChatControlView: FC<Props> = ({
    projectOptions,
    workerOptions,
    positionOptions,
    formik,
    isCreate,
    data,
    searchParams,
    fromProject,
    project_id,
}) => {
    const navigate = useNavigate();
    const matchesSP = window?.innerWidth < 992;
    return (
        <Main>
            <div className="chat-detail-content">
                {matchesSP && (
                    <Typography sx={{ mb: '28px', mt: '8px' }}>
                        チャット新規作成
                    </Typography>
                )}
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-chat-detail">
                        <div className="input-chat d-flex">
                            <p className="d-flex">タイトル</p>
                            <div className="d-flex content-right">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    value={String(formik.values.title)}
                                    className="input-text height36"
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                    onChange={(e: any) =>
                                        formik.setFieldValue(
                                            'title',
                                            e.target.value,
                                        )
                                    }
                                />
                                {formik.touched.title &&
                                    formik.errors.title && (
                                        <span className="error">
                                            {formik.errors.title}
                                        </span>
                                    )}
                            </div>
                        </div>
                        {!searchParams.get('project_id') && (
                            <div className="input-chat d-flex">
                                <p className="d-flex">案件</p>
                                <div className="d-flex content-right">
                                    <Autocomplete
                                        value={formik.values.project_id}
                                        disabled={fromProject}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'project_id',
                                                newValue,
                                            );
                                        }}
                                        classes={{
                                            root: 'search-select-style',
                                            input: 'search-select-style',
                                        }}
                                        noOptionsText="該当なし"
                                        options={projectOptions || []}
                                        getOptionLabel={(option: any) =>
                                            option.name
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="input-chat d-flex">
                            <p className="d-flex">参加者</p>
                            <div className="d-flex content-right ">
                                <Autocomplete
                                    className="multi-select"
                                    multiple
                                    disabled={isCreate ? false : !data.is_owner}
                                    options={workerOptions || []}
                                    onChange={(_event, newValue) => {
                                        formik.setFieldValue(
                                            'chat_worker_ids',
                                            newValue,
                                        );
                                    }}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    value={formik.values.chat_worker_ids}
                                    noOptionsText="該当なし"
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="input-chat d-flex">
                            <p className="d-flex">権限</p>
                            <div className="d-flex content-right">
                                <Autocomplete
                                    className="multi-select"
                                    multiple
                                    options={positionOptions || []}
                                    onChange={(_event, newValue) => {
                                        formik.setFieldValue(
                                            'chat_position_ids',
                                            newValue,
                                        );
                                    }}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    value={formik.values.chat_position_ids}
                                    noOptionsText="該当なし"
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    {!isCreate && <ChatBox chatRoom={data} />}

                    <div className="group-buttons d-flex flex-row">
                        <Button
                            className="bnt bnt-cancel d-flex flex-row"
                            onClick={() => {
                                if (fromProject) {
                                    navigate(
                                        `/projects/detail/${project_id}/plan?tab=6`,
                                    );
                                    return;
                                }
                                navigate(-1);
                            }}
                        >
                            戻る
                        </Button>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            type="submit"
                        >
                            登録
                        </Button>
                    </div>
                </form>
            </div>
        </Main>
    );
};

const ChatControl: FC = () => <ChatControlView {...useChat()} />;

export default ChatControl;
