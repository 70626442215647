import React from 'react';
import Carousel from 'react-slick';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface ISlider {
  name: string;
  file_path: string;
  content_type: string;
  start_date: Date;
  message?: string;
}

const SlideAds = (props) => {
  const {
    listSliders,
    classNameCustom,
  } = props;

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '506px',
    color: '#fff',
    textAlign: 'center',
    width: 'inherit',
    objectFit: 'contain'
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick} >
        <ArrowForwardIosIcon className='icon-arrow-slider' />
      </div>
    );
  }

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick} >
        <ArrowBackIosNewIcon className='icon-arrow-slider' />
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    //pauseOnHover:false,
    nextArrow: <NextArrow className='next-arrow-custom' />,
    prevArrow: <PrevArrow className='pre-arrow-custom' />
  };
  return (
    <div className='dashboard-slider'>
      <Carousel arrows={true} {...settings}>
        {
          listSliders?.map((item: ISlider, index: number) => (
            <div key={index}>
              <div className='card-slider w-full inline-block'>
                {
                  item?.content_type?.includes('image/') ? <img className={classNameCustom} style={contentStyle} src={item?.file_path} alt="ads" /> : (
                    <video autoPlay muted className={classNameCustom} style={contentStyle} controls>
                      <source src={item?.file_path} type="video/mp4" />
                      <source src={item?.file_path} type="video/ogg" />
                      <source src={item?.file_path} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                  )
                }
              </div>
              <p className='text-center mt-[4px] font-bold'>{item?.name}</p>
              {item?.message && <p className='text-center'>{item?.message}</p>}
            </div>
          ))
        }
      </Carousel>
    </div>
  );
}
export default SlideAds;
