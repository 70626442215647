import {
    FC,
    memo,
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import { isArray, isEmpty } from 'lodash';
import {
    Widget,
    renderCustomComponent,
    toggleWidget,
    dropMessages,
} from 'react-chat-widget';
import moment from 'moment';
import 'react-chat-widget/lib/styles.css';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Main } from './style';
import useChatBox, { Props, ReceivedProps } from './hook';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
} from '@chatscope/chat-ui-kit-react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';

const ChatBoxLayout: FC<Props> = ({
    workerId,
    listMessage,
    onSubmit,
    handleFileChange,
    listImageMessage,
    setListMessage,
    refInput,
    removeImage,
    message,
    setUpdateChat,
    setMessage,
    handleReceived,
    chatRoomId,
}) => {
    const messageInputRef: any = useRef(null);

    useEffect(() => {
        const element = document.getElementsByClassName('cs-button--send');
        element[0]?.removeAttribute('disabled');
    }, []);

    const handleButtonClick = () => {
        const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
        messageInputRef?.current?.inputRef?.current.dispatchEvent(
            enterKeyEvent,
        );
    };

    const [open, setOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState();

    const downloadImage = () => {
        saveAs(imagePreview, 'image.jpeg');
    };

    const listMessageItem = useMemo(() => {
        return listMessage?.map((message) => (
            <>
                <Message
                    key={message.id}
                    model={{
                        message: message.body,
                        sender: message?.sender?.name,
                        sentTime: message?.publish_at,
                        direction: message?.is_owner ? 'incoming' : 'outgoing',
                        position: 'first',
                        type: 'custom',
                    }}
                >
                    <Message.CustomContent>
                        <div className="message">
                            <p>{message?.body || ''}</p>

                            {message?.images &&
                                Object.values(message?.images).map(
                                    (img: any, id) => (
                                        <img
                                            src={img?.image_path}
                                            style={{
                                                marginTop: '5px',
                                                width: '324px',
                                            }}
                                            key={id}
                                            onClick={() => {
                                                setOpen(true);
                                                setImagePreview(
                                                    img?.image_path,
                                                );
                                            }}
                                        />
                                    ),
                                )}
                        </div>
                    </Message.CustomContent>
                </Message>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: message?.is_owner ? 'start' : 'end',
                    }}
                    className="message-footer"
                >
                    <p>{message?.sender?.name || ''}</p>
                    <p>
                        {message.publish_at &&
                            moment(message.publish_at)
                                .zone('+09:00')
                                .format('YYYY/MM/DD HH:mm:ss')}
                    </p>
                </div>
            </>
        ));
    }, [listMessage]);
    const handleInputChange = (val) => {
        setMessage(val);
        // let objDiv = document.getElementById('chat-container');
        // objDiv?.scrollIntoView();
    };
    return (
        <Main className="chat-box" style={{ position: 'relative' }}>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <div className="flex justify-end px-2 py-1">
                        <FileDownloadOutlinedIcon
                            className="cursor-pointer"
                            onClick={() => downloadImage()}
                        />
                    </div>
                    <img
                        src={imagePreview}
                        style={{
                            width: '600px',
                        }}
                    />
                </Box>
            </Dialog>
            <ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            >
                <div style={{ position: 'relative', height: '500px' }}>
                    <MainContainer>
                        <ChatContainer id="chat-container">
                            <MessageList>{listMessageItem}</MessageList>
                            {/* <MessageInput
                                autoFocus
                                onKeyPress={(e) => {
                                    e.code === 'Enter' && onSubmit(message);
                                }}
                                attachButton={false}
                                placeholder="メッセージを入力..."
                                value={message}
                                ref={messageInputRef}
                                onChange={handleInputChange}
                                onSend={() => onSubmit}
                            /> */}
                        </ChatContainer>
                    </MainContainer>
                    <div className="action-container">
                        <label
                            className="upload-image-chat"
                            htmlFor="fileInput"
                        >
                            <input
                                onChange={(e) => handleFileChange(e)}
                                id="fileInput"
                                name="file"
                                type="File"
                                multiple
                                ref={refInput}
                                accept="image/*"
                                style={{ display: 'none' }}
                            ></input>
                            <BiImageAdd size={20} />
                        </label>
                        <input
                            onKeyPress={(e) => {
                                e.code === 'Enter' && onSubmit(message);
                            }}
                            className="input-custom-chat"
                            type="text"
                            placeholder="メッセージを入力..."
                            autoFocus
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <button
                            style={{
                                // position: 'absolute',
                                bottom: '16px',
                                zIndex: 100000,
                                right: '16px',
                            }}
                            type="button"
                            onClick={() => onSubmit(message)}
                        >
                            <BiSend size={25} />
                        </button>
                        <div className="preview-img-upload">
                            {listImageMessage?.length > 0 &&
                                listImageMessage.map((item, id) => {
                                    return (
                                        <div className="img-upl-ct">
                                            <img
                                                src={URL.createObjectURL(item)}
                                                key={id}
                                            />
                                            <TiCancel
                                                onClick={() => removeImage(id)}
                                                size={15}
                                                className="delete-image-upl"
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </ActionCableConsumer>
        </Main>
    );
};

const ChatBox: FC<ReceivedProps> = (props) => (
    <ChatBoxLayout {...useChatBox(props)} />
);

export default memo(ChatBox);
