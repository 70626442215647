import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import styled from 'styled-components';

export const Styles = styled.div`
    .btn-group {
        display: flex;
        align-items: center;
        padding: 10px;

        button {
            min-width: 130px;

            > svg {
                transform: rotate(135deg);
            }
        }

        .btn-clear {
            min-width: 100px;
            margin-right: 20px;
        }
    }

    .table-wrapper {
        border: 1px solid rgba(224, 224, 224, 1);
        border-radius: 4px;
    }

    nav {
        display: flex;
        justify-content: center;
    }
`;

export const TableWrapper = styled.div`
    .ag-root {
        overflow: auto;
    }

    .ag-overlay-loading-wrapper {
        background-color: unset;
    }

    .ag-root-wrapper {
        border: none;
        border-radius: 4px;
    }

    .ag-header {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        position: relative;
        z-index: 3;
    }

    .ag-body-viewport {
        position: relative;
        z-index: 2;
    }

    .ag-header-cell:not(.ag-header-cell-auto-height) {
        .ag-header-cell-comp-wrapper {
            background-color: #c7dcf5;
        }
    }

    .ag-header-cell-label {
        justify-content: center;
    }

    .ag-pinned-left-header {
        border-right: 1px solid rgba(224, 224, 224, 1) !important;
        border-top: 1px solid rgba(224, 224, 224, 1);
    }

    .ag-pinned-right-cols-container {
        border-left: 1px solid rgba(224, 224, 224, 1);
    }

    .ag-floating-filter-button {
        display: none;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 0;
        padding-right: 0;
        background-color: #ffffff;
    }

    .ag-header-group-cell-label,
    .ag-header-cell-label {
        padding: 0 20px;
    }

    .ag-header-cell-label {
        justify-content: space-between;
    }

    .ag-ltr .ag-header-select-all {
        margin-left: 18px;
    }

    .ag-header-icon,
    .ag-icon {
        display: none;
    }

    .ag-icon {
        &.ag-icon-none {
            display: block;

            &::before {
                color: #777777;
            }
        }
    }

    .ag-icon-asc,
    .ag-icon-desc {
        display: block;

        &::before {
            color: #777777;
        }
    }

    .ag-header-row {
        &.ag-header-row-column-filter {
            background-color: #ffffff;
        }
    }

    .ag-header-cell {
        &:hover {
            background-color: #ffffff !important;
        }

        &.ag-floating-filter {
            border-top: 1px solid rgba(224, 224, 224, 1) !important;
            padding-left: 10px;
            padding-right: 10px;
        }

        &:first-child {
            .ag-header-cell-comp-wrapper {
                border-top-left-radius: 4px;
            }
        }
        &:last-child {
            .ag-header-cell-comp-wrapper {
                border-top-right-radius: 4px;
            }
        }

        &:last-child {
            .ag-header-cell-label {
                justify-content: center;
            }
        }
    }

    .ag-row {
        border-bottom: 1px solid rgba(224, 224, 224, 1) !important;

        &.ag-row-even {
            background-color: rgba(0, 0, 0, 0.04);

            &.ag-row-hover {
                &::before {
                    background: rgba(0, 0, 0, 0) !important;
                }
            }
        }

        &.ag-row-hover {
            &::before {
                background-color: #ffffff;
            }
        }

        &.ag-row-odd {
            background-color: #ffffff;

            &.ag-row-last {
                border-bottom: none !important;
            }
        }

        &.ag-row-even,
        &.ag-row-odd {
            &.ag-row-last.ag-row-position-absolute {
                border-bottom: 1px solid rgba(224, 224, 224, 1);
            }
        }
    }

    .ag-input-field-input {
        border: none !important;
        border-bottom: 1px solid #c9c9c9 !important;
        border-radius: 0 !important ;
        position: relative;

        &.ag-text-field-input {
            font-weight: 500;
            font-size: 15px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .ag-header-cell-text {
        color: #1c1e21;
        font-size: 14px;
        font-weight: 600;
    }

    .ag-cell {
        line-height: 50px;
        color: #1c1e21;
        font-size: 16px;

        &:nth-child(4) {
        }
    }

    .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
        border-color: transparent !important;
    }

    .grid-cell-centered {
        display: flex;
        justify-content: center;
    }

    input[type='checkbox'] {
        cursor: pointer;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
        border: 1px solid rgba(224, 224, 224, 1);
        box-sizing: content-box;
    }

    .ag-checkbox-input-wrapper:focus-within,
    .ag-checkbox-input-wrapper:active {
        box-shadow: none;
    }

    .ag-checkbox-input-wrapper.ag-indeterminate::after {
        background-color: #000000;
        color: #ffffff;
    }

    .ag-checkbox-input-wrapper::after {
        background-color: #ffffff;
        border-radius: 4px;
        color: #ffffff;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: #ffffff;
        background-color: #000000;
        border-radius: 4px;
    }

    .ag-paging-panel.ag-unselectable {
        display: none;
    }

    .ag-header-cell-text {
        overflow: initial;
        text-overflow: unset;
        white-space: normal !important;
    }

    .empty-data {
        color: rgba(224, 224, 224, 1);
        opacity: 0.8;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > svg {
            margin-right: 10px;
        }
    }
`;
