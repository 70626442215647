import React, { useState, useEffect } from "react";
import "./header.css";
import iconNoti from "../../assets/icons/bell.svg";
import { useSelector } from "react-redux";
import { getUser } from "../../store/user/user";
import { RootState, AppDispatch, useAppDispatch } from "../../store/index";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { logout } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";

declare let window: any;

const Header = (props) => {
    const { isChangeTab } = props;
    const [hide, setHide] = useState(false);
    const [title, setTitle] = useState("");
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const { userData } = useSelector((state: RootState) => state.users);

    const getMe = async () => {
        const response = dispatch(getUser());
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    useEffect(() => {
        getMe();
        let path = window.location.pathname;
        if (path == "/login") {
            setHide(true);
        }
        let pathTemp = window.location.pathname;
        if (pathTemp == "/") {
            setTitle("ホーム");
        }
        if (pathTemp == "/users") {
            setTitle("アカウント");
        }
        if (pathTemp == "/bills") {
            setTitle("請求");
        }
        if (pathTemp == "/estimates") {
            setTitle("見積");
        }
        if (pathTemp == "/departments") {
            setTitle("部署");
        }
        if (pathTemp == "/positions") {
            setTitle("役職");
        }
        if (
            pathTemp == "/workers" ||
            path === "/workers/detail" ||
            path === "/workers/create" ||
            path === "/workers/edit"
        ) {
            setTitle("社員");
        }
        if (pathTemp == "/projects/create") {
            setTitle("ダッシュボード");
        }
        if (
            pathTemp == "/projects" ||
            path.includes("/projects/detail") ||
            path.includes("/projects/estimation")
        ) {
            setTitle("案件");
        }
        if (
            path === "/companies" ||
            path === "/companies/detail" ||
            path === "/companies/create" ||
            path === "/companies/edit"
        ) {
            setTitle("顧客会社");
        }

        if (path?.includes("/ads")) {
            setTitle("ダッシュボード");
        }

        if (
            path === "/cooperative-companies" ||
            path === "/cooperative-companies/detail" ||
            path === "/cooperative-companies/create" ||
            path === "/cooperative-companies/edit"
        ) {
            setTitle("協力会社");
        }
        if (path === "/calendar") {
            setTitle("カレンダー");
        }
        if (
            [
                "/suggestions",
                "/suggestions/create",
                "/suggestion/detail",
            ].includes(pathTemp)
        ) {
            setTitle("目安箱");
        }
        if (["/news"].includes(pathTemp)) {
            setTitle("案件");
        }
        if (["/setting"].includes(path)) {
            setTitle("設定");
        }
    }, [isChangeTab]);

    return (
        <div
            className="header"
            style={{
                display: hide ? "none" : "",
            }}
        >
            <div className="header-left">{title}</div>
            <div className="header-right d-flex flex-row">
                <img width={"18px"} height={"20px"} src={iconNoti}></img>
                <div className="line"></div>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            fontFamily: "Zen Kaku Gothic Antique",
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                left: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                >
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        ログアウト
                    </MenuItem>
                </Menu>
                <div
                    className="username"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {userData !== null && userData!.email}
                </div>
            </div>
        </div>
    );
};
export default Header;
