import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from "../../../common/DialogHeader";
import CommonStyledSugorkuDialogWrapper from "../../../common/commonSugorokuDialogStyle";
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from "utils/constants";
import { WasteSugorokuIndexEnum } from "utils/enums";
import sugorokuService from "services/sugorokuService";
import { formatDateTime } from 'utils/helper/dateHelper';
import { IFinishedPaymentPayload } from "models";
import { useFormik, FormikProps } from "formik";

interface IProps {
  step: number;
  onClose?: any;
  data_id?: number;
  workerOptions: any[];
  companyOptions: any[];
  onFinish: () => void;
}

interface IFinishedPaymentFormikValues {
  company?: any;
  payment_planned_at?: Date | null;
  payment_at?: Date | null;
  confirmer_worker?: any;
  completed_at?: Date | null;
}

const FinishedPaymentModal = (props: IProps) => {
  const { step, onClose, data_id, workerOptions, companyOptions, onFinish } = props;
  const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<any>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hasData = !!data_id;
  const { id } = useParams();

  const formik: FormikProps<IFinishedPaymentFormikValues> = useFormik<IFinishedPaymentFormikValues>({
    initialValues: {
      company: null,
      payment_planned_at: null,
      payment_at: null,
      confirmer_worker: null,
      completed_at: null,
    },
    onSubmit: (values: IFinishedPaymentFormikValues) => {
      setSubmitLoading(true);
      let data: IFinishedPaymentPayload = pick(values);
      if (!id) {
        setSubmitLoading(false);
        return;
      }
    
      data.project_id = +id;
      data.sugoroku_index = WasteSugorokuIndexEnum.FINISHED_PAYMENT.value;

      if (values.company) {
        data.company_id = +values.company.value;
      } else {
        data.company_id = null;
      }
  
      if (values.payment_planned_at) {
        data.payment_planned_at = formatDateTime(values.payment_planned_at, "yyyy-MM-DD");
      } else {
        data.payment_planned_at = null;
      }

      if (values.payment_at) {
        data.payment_at = formatDateTime(values.payment_at, "yyyy-MM-DD");
      } else {
        data.payment_at = null;
      }
  
      if (values.confirmer_worker) {
        data.confirmer_worker_id = +values.confirmer_worker.value;
      } else {
        data.confirmer_worker_id = null;
      }

      if (values.completed_at) {
        data.completed_at = formatDateTime(values.completed_at, "yyyy-MM-DD");
      } else {
        data.completed_at = null;
      }
      
      if (hasData) {
        updateSugoroku(data);
      } else {
        createSugoroku(data);
      }
    }
  });

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const data = await sugorokuService.getWasteTypeSugorokuEventDetail(data_id, Number(id));
      if (data && data.sugoroku_event) {
        const sugorokuData = data.sugoroku_event;
        
        formik.setValues({
          'company': sugorokuData.company ? { 
            value: String(sugorokuData.company.id), 
            label: sugorokuData.company.name,
            name: sugorokuData.company.name,
          } : null,
          'payment_planned_at': sugorokuData.payment_planned_at 
            ? new Date(sugorokuData.payment_planned_at) 
            : null,
          'payment_at': sugorokuData.payment_at 
            ? new Date(sugorokuData.payment_at) 
            : null,
          'confirmer_worker': sugorokuData.confirmer_worker ? {
            value: String(sugorokuData.confirmer_worker.id),
            label: sugorokuData.confirmer_worker.name,
            name: sugorokuData.confirmer_worker.name,
          } : null,
          'completed_at': sugorokuData.completed_at 
            ? new Date(sugorokuData.completed_at)
            : null,
        });
        setIsEmpty(false)
      } else {
        setIsEmpty(true)
      }
    } catch (error) {
      // error
    } finally {
      setFetchLoading(false);
    }
  }

  const createSugoroku = async (data: IFinishedPaymentPayload) => {
    try {
      const responseData = await sugorokuService.createSugorokuEvent(data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // empty
    }
  }

  const updateSugoroku = async (data: IFinishedPaymentPayload) => {
    try {
      const responseData = await sugorokuService.updateSugorokuEvent(data_id, data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // epmty
    }
  }

  useEffect(() => {
    if (data_id && id) {
      fetchData();
    }
  }, [])

  return (
    <CommonStyledSugorkuDialogWrapper>
        <DialogHeader
          step={step}
          title={WasteSugorokuIndexEnum.FINISHED_PAYMENT.title}
          onClose={onClose}
          mode={mode}
          setMode={setMode}
          completed={!!formik.values.completed_at}
        />

        {
          fetchLoading && (
            <div className="d-flex justify-center">
              <CircularProgress />
            </div>
          )
        }

        {
          mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
            <>
              <div className="dialog-field-row">
                  <div className="field-label">企業名</div>
                  <div className="value">{formik.values.company?.label || ""}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">入金予定日</div>
                  <div className="value">{formatDateTime(formik.values.payment_planned_at, 'yyyy-MM-DD')}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">入金日</div>
                  <div className="value">{formatDateTime(formik.values.payment_at, 'yyyy-MM-DD')}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">確認者</div>
                  <div className="value">{formik.values.confirmer_worker?.name || ""}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="value">{formatDateTime(formik.values.completed_at, 'yyyy-MM-DD')}</div>
              </div>
            </>
          )
        }

        {
          mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
            <form onSubmit={formik.handleSubmit}> 
                <div className="dialog-field-row">
                  <div className="field-label">企業名</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={formik.values.company}
                        options={companyOptions?.map((item) => ({
                          label: item.label,
                          value: String(item.value),
                          name: item.name
                        }))}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('company', newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
                </div>

                <div className="dialog-field-row">
                    <div className="field-label">入金予定日</div>
                    <div className="datepicker-wrapper">
                        <DatePicker
                            selected={formik.values.payment_planned_at}
                            onChange={(date) => {
                              formik.setFieldValue('payment_planned_at', date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                            locale="ja"
                        />
                        <img
                            className="calendar-datepicker-icon"
                            src={calendarIcon}
                        ></img>
                    </div>
                </div>

                <div className="dialog-field-row">
                    <div className="field-label">入金日</div>
                    <div className="datepicker-wrapper">
                        <DatePicker
                            selected={formik.values.payment_at}
                            onChange={(date) => {
                              formik.setFieldValue('payment_at', date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                            locale="ja"
                        />
                        <img
                            className="calendar-datepicker-icon"
                            src={calendarIcon}
                        ></img>
                    </div>
                </div>

                <div className="dialog-field-row">
                    <div className="field-label">確認者</div>
                    <div className="autocomplete-wrapper">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={workerOptions.map((item) => ({
                              label: item.label,
                              value: String(item.value),
                              name: item.name
                            }))}
                            value={formik.values.confirmer_worker}
                            onChange={(event, newValue) => {
                              formik.setFieldValue('confirmer_worker', newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            classes={{
                                root: 'autocomplete-select-style',
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="" />
                            )}
                            noOptionsText="該当なし"
                        />
                    </div>
                </div>
                <div className="dialog-field-row">
                    <div className="field-label">完了日付</div>
                    <div className="datepicker-wrapper">
                      <DatePicker 
                          selected={formik.values.completed_at}
                          onChange={(date) => {
                            formik.setFieldValue('completed_at', date);
                          }}
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                      />
                      <img
                          className="calendar-datepicker-icon"
                          src={calendarIcon}
                      ></img>
                    </div>
                </div>
                <div className="action-section">
                    <button
                      type="button"
                      className="back-step-edit-button"
                      onClick={onClose}
                    >
                      キャンセル
                    </button>
                    <button
                        type="submit"
                        className="submit-step-edit-button"
                        disabled={submitLoading}
                    >
                        {
                          submitLoading ? <CircularProgress color="inherit" size={18} /> : "登録"
                        }
                    </button>
                </div>
            </form>
          )
        }
    </CommonStyledSugorkuDialogWrapper>
  )
}
  
export default FinishedPaymentModal