import { useState, useEffect } from 'react';
import './bill.css';
import { useNavigate } from 'react-router-dom';
import positionService from '../../services/positionService';
import { RootState } from '../../store/index';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import CustomPagination from 'components/CustomPagination';
import calendarIcon from '../../assets/icons/calendar.png';
import BillList from './components/BillList';
import projectsService from 'services/projectsService';
import billService from 'services/billService';
import workersService from 'services/wokersService';
import clientCompanyService from 'services/clientCompanyService';
import BillingCreateDialog from 'pages/project/projectPlan/Billings/BillingCreate';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Box } from '@mui/material';
import EditBillingDialog from 'pages/project/projectPlan/Billings/edit';
const Bill = (props) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );

    const [projectNameOptions, setProjectNameOptions] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 20,
    });

    const [searchedState, setSearchedState] = useState({
        end_payment_deadline: '',
        end_submitted_at: '',
        start_payment_deadline: '',
        start_submitted_at: '',
        project_id: '',
        free_word: '',
    });

    const [bills, setBills] = useState<any[]>([]);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [selectedBill, setSelectedBill] = useState<any>(null);

    const [positionOption, setPositionOption] = useState<any>([]);
    const [workers, setWorkers] = useState<any[]>([]);
    const [companies, setCompanies] = useState<any[]>([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startSubmitAt, setStartSubmitAt] = useState(null);
    const [endSubmitAt, setEndSubmitAt] = useState(null);
    const [startPaymentDeadline, setStartPaymentDeadline] = useState(null);
    const [endPaymentDeadline, setEndPaymentDeadline] = useState(null);

    const fetchBills = async ({ page = 1, per_page = 0 }) => {
        try {
            const data = await billService.getListBill({
                page,
                per_page,
                ...searchedState,
            });
            if (data) {
                setBills(data.bills);
                setTotalCount(data.meta.total_count);
            }
        } catch (error) {
            // error
        }
    };

    const onSearch = (data) => {
        setSearchedState({
            end_payment_deadline: data.end_payment_deadline || '',
            end_submitted_at: data.end_submitted_at || '',
            start_payment_deadline: data?.start_payment_deadline || '',
            start_submitted_at: data?.start_submitted_at || '',
            project_id: data?.project_id || '',
            free_word: data?.free_word || '',
        });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
    };

    const fetchWorkers = async () => {
        try {
            const data = await workersService.getListWorkers();
            if (data) {
                setWorkers(data.workers);
            }
        } catch (error) {
            // empty
        }
    };

    const fetchClientCompanies = async () => {
        try {
            const data = await clientCompanyService.getListCompanies();
            if (data) {
                setCompanies(data.client_companies);
            }
        } catch (error) {
            // epmty
        }
    };

    const getListProjects = async ({ page = 1, per_page = 5, data = {} }) => {
        try {
            const response = await projectsService.getListProjects({
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setProjectNameOptions(
                    response?.projects?.map((project) => ({
                        value: project?.id,
                        label: project?.name,
                    })),
                );
            }
        } catch (err) {
            // empty
        }
    };

    const getListPositions = async () => {
        try {
            const response = await positionService.getListPositions();
            // console.log(response)
            if (response !== null && response !== undefined) {
                setPositionOption(
                    convertObjectToDropdownOptions(response.positions),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        if (positionsList !== undefined && positionsList.length == 0)
            getListPositions();
        else {
            setPositionOption(convertObjectToDropdownOptions(positionsList));
        }
    }, []);

    useEffect(() => {
        fetchBills({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, [searchedState]);

    useEffect(() => {
        fetchWorkers();
        fetchClientCompanies();
    }, []);

    const handleEndAtChange = (date: any) => {
        setEndDate(date);
    };
    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    useEffect(() => {
        getListProjects({ page: 1, per_page: 0 });
    }, []);

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                {/* <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/bills/create')}
                    >
                        新規作成
                    </button>
                </div> */}
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers d-flex flex-column">
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>宛先</p>
                                    <Controller
                                        name="free_word"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    '.MuiInputBase-root ': {
                                                        borderRadius: '8px',
                                                    },
                                                    input: {
                                                        border: 'none',
                                                    },
                                                    fieldset: {
                                                        borderColor:
                                                            'rgba(0, 0, 0, 0.23) !important',
                                                        borderWidth:
                                                            '1px !important',
                                                    },
                                                }}
                                                {...field}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>顧客</p>
                                    <Autocomplete
                                        sx={{
                                            fieldset: {
                                                borderColor:
                                                    'rgba(0, 0, 0, 0.23) !important',
                                                borderWidth: '1px !important',
                                            },
                                        }}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={projectNameOptions}
                                        getOptionLabel={(option: any) =>
                                            option.label || option.value
                                        }
                                        onChange={(event, newValue: any) => {
                                            if (newValue?.value) {
                                                setValue(
                                                    'project_id',
                                                    newValue?.value,
                                                );
                                            } else {
                                                setValue('project_id', '');
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-button margin-left d-flex flex-column">
                                <button
                                    className="search-user-button d-flex flex-column"
                                    type="submit"
                                    style={{
                                        width: '150px',
                                        height: '36px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    検索
                                </button>
                            </div>
                        </div>
                        <div className="search-workers-row d-flex flex-row">
                            <div
                                className="input-add-user d-flex flex-column"
                                style={{ width: '33.33%' }}
                            >
                                <p style={{ marginTop: '8px' }}>請求日</p>
                                <div className="datepicker-bill">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '44%',
                                            }}
                                        >
                                            <Controller
                                                name="start_submitted_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={startSubmitAt}
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            setStartSubmitAt(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={
                                                            startSubmitAt
                                                        }
                                                        endDate={endSubmitAt}
                                                        selectsStart
                                                        maxDate={endSubmitAt}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                        <span className="symbol">~</span>
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '44%',
                                            }}
                                        >
                                            <Controller
                                                name="end_submitted_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={endSubmitAt}
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            setEndSubmitAt(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={
                                                            startSubmitAt
                                                        }
                                                        endDate={endSubmitAt}
                                                        selectsEnd
                                                        minDate={startSubmitAt}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="input-add-user d-flex flex-column"
                                style={{ marginLeft: 10, width: '33.33%' }}
                            >
                                <p style={{ marginTop: '8px' }}>振込期限</p>
                                <div className="datepicker-bill">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '44%',
                                            }}
                                        >
                                            <Controller
                                                name="start_payment_deadline"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={
                                                            startPaymentDeadline
                                                        }
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            setStartPaymentDeadline(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={
                                                            startPaymentDeadline
                                                        }
                                                        endDate={
                                                            endPaymentDeadline
                                                        }
                                                        selectsStart
                                                        maxDate={
                                                            endPaymentDeadline
                                                        }
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                        <span className="symbol">~</span>
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '44%',
                                            }}
                                        >
                                            <Controller
                                                name="end_payment_deadline"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={
                                                            endPaymentDeadline
                                                        }
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            setEndPaymentDeadline(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={
                                                            startPaymentDeadline
                                                        }
                                                        endDate={
                                                            endPaymentDeadline
                                                        }
                                                        selectsEnd
                                                        minDate={
                                                            startPaymentDeadline
                                                        }
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <div className='table-workers d-flex flex-column'> */}
                <div className="table-content d-flex flex-column">
                    <BillList
                        data={bills}
                        setOpenEdit={setOpenEdit}
                        setSelectedBill={setSelectedBill}
                        setProjectId={setProjectId}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchBills={fetchBills}
                    />
                </div>
                {openEdit && projectId && selectedBill && (
                    <EditBillingDialog 
                      open={openEdit}
                      setOpen={setOpenEdit}
                      project_id={projectId}
                      selectedBill={selectedBill}
                      onFinishEdit={() => {
                        fetchBills({
                          page: 1,
                          per_page: pageQueries.per_page
                        });
                        setOpenEdit(false);
                      }}
                      workers={workers}
                      companies={companies}
                    />
                )}
                {/* </div> */}
                {/* {workersList && workersList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListWorkers({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )} */}
            </div>
        </div>
    );
};

export default Bill;
