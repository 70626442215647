import Api from './api/scheduleApi';
import ApiSP from './api/scheduleApiSP';
import pick from 'lodash/pick';
import { convertNestedObjToSearchParamsArray } from 'utils/helper/helpers';

interface IScheduleUpdateStatus {
    worker_id: number | undefined;
    is_agree: boolean;
}

const getSchedules = async (input?: any) => {
    let data = pick(input, [
        'page',
        'per_page',
        'attribute_department_ids',
        'attribute_position_ids',
        'attribute_worker_ids',
        'free_word',
        'start_date',
        'end_date',
    ]);
    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${convertNestedObjToSearchParamsArray(data)}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getSchedulesSP = async (input?: any) => {
    let data = pick(input, [
        'page',
        'per_page',
        'attribute_department_ids',
        'attribute_position_ids',
        'attribute_worker_ids',
        'free_word',
        'start_date',
        'end_date',
    ]);
    const params = new URLSearchParams(data);

    try {
        return await ApiSP()
            .get(`?${convertNestedObjToSearchParamsArray(data)}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getSchedule = async (id: number) => {
    try {
        return await Api()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        window.location.href = '/login';
        return null;
    }
};

const getScheduleSP = async (id: number) => {
    try {
        return await ApiSP()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        window.location.href = '/login';
        return null;
    }
};

const createSchedule = async (payload: any) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createScheduleSP = async (payload: any) => {
    return await ApiSP()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateSchedule = async (id: number, payload: any) => {
    return await Api()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateScheduleSP = async (id: number, payload: any) => {
    return await ApiSP()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateScheduleStatus = async (id: number, payload: IScheduleUpdateStatus) => {
    return await Api()
        .put(`${id}/update_status`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
}

const deleteSchedule = async (id: number, duplicate_apply_type?: string) => {
    let deleteParams: { duplicate_apply_type?: string} = {};
    if (duplicate_apply_type) {
      deleteParams.duplicate_apply_type = duplicate_apply_type;
    }
    return await Api().delete(`${id}`, {
      params: deleteParams
    });
};

const deleteScheduleSP = async (id: number) => {
    return await ApiSP().delete(`${id}`);
};


const scheduleService = {
    updateSchedule,
    getSchedules,
    getSchedule,
    createSchedule,
    deleteSchedule,
    deleteScheduleSP,
    updateScheduleSP,
    createScheduleSP,
    getScheduleSP,
    getSchedulesSP,
    updateScheduleStatus
};

export default scheduleService;
