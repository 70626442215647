import './newsModal.css'

declare let window: any;

const NewsModal = (props) => {
  const {
    close,
    news,
    convertDateToJapan,
    type,
    handleAgree,
    handleRefuse,
    userData,
    classNameCustom,
    handleAgreeSendApiFirst,
    handleRefuseSendApiFirst,
    nameWorkerCreate
  } = props;
  const isScheduleMarker = userData?.worker?.id === news?.worker?.id;
  return (
    <div className={`modal-news-detail d-flex flex-column ${classNameCustom}`}>
      <div className='modal-news-detail-title d-flex flex-row'>{type !== 'schedule' ? convertDateToJapan(news?.publish_at) : convertDateToJapan(news?.start_date)}</div>
      <div className='modal-news-title d-flex flex-row'>{news?.title}</div>
      <div className='modal-news-content d-flex flex-column'>
        {type !== 'schedule' ? news?.content : <>{nameWorkerCreate} から{convertDateToJapan(news?.start_date, news?.is_all_day)} - {convertDateToJapan(news?.end_date, news?.is_all_day)}に{news?.title} {news?.content}の参加依頼がありました。</>}
      </div>
      {
        type !== 'schedule' ? (
          <div className='modal-create-user-buttons d-flex flex-row'>
            <button className='modal-cancel-news d-flex flex-row' onClick={close}>キャンセル</button>
          </div>
        ) : <div className='flex gap-x-[12px] items-center mt-[20px]'>
          <button
            disabled={isScheduleMarker ? news.schedule_maker_is_agree : news.is_agree}
            className='btn-agree btn-schedule'
            onClick={
              (isScheduleMarker && news?.schedule_maker_is_agree === null) ||
              (!isScheduleMarker && news?.is_agree === null) 
                ? handleAgreeSendApiFirst
                : handleAgree
            }
          >
            承認
          </button>
          <button
            disabled={isScheduleMarker ? news.schedule_maker_is_agree === false : news.is_agree === false}
            className='btn-refuse btn-schedule' 
            onClick={
              (!isScheduleMarker && news?.is_agree === null) ||
              (isScheduleMarker && news?.schedule_maker_is_agree === null)
               ? handleRefuseSendApiFirst
               : handleRefuse
              }
          >
            却下
          </button>
        </div>
      }
    </div>
  );
}
export default NewsModal;
