import { useState, useEffect } from 'react';
import './news.css';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import NewsService from '../../services/newsService';
import { convertDateToJapan } from 'utils/helper/convertDate';
import 'react-dropdown/style.css';
import { textTrim } from 'utils/helper/appHelper';
import CreateNewsDialog from './create';
import EditNewsDialog from './edit';
import DeleteNewsDialog from './delete';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { isAllowAction } from 'utils/helper/workerHelper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRowCustom = (props) => {
    const { row, setOpenDelete, setSelectedNews, setOpenEdit, setIsDetail, isAllow } = props;

    return (
        <StyledTableRow>
            <StyledTableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: '25%' }}
            >
                <div style={{ color: '#448CDF' }}>
                    {textTrim(row?.title, 20)}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '50%' }}>
                <div>{textTrim(row?.content, 45)}</div>
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {convertDateToJapan(row?.publish_at)}
                </div>
            </StyledTableCell>

            <StyledTableCell align="center" style={{ width: '15%' }}>
                <button
                    className="button-tb"
                    style={{ width: '72px', margin: '5px' }}
                    onClick={() => {
                        setOpenEdit(true);
                        setSelectedNews(row);
                        setIsDetail(true);
                    }}
                >
                    詳細
                </button>
                {isAllow &&
                    <button
                        className="button-tb"
                        style={{ width: '72px', margin: '5px' }}
                        onClick={() => {
                            setOpenEdit(true);
                            setSelectedNews(row);
                            setIsDetail(false);
                        }}
                    >
                        編集
                    </button>
                }
                {isAllow &&
                    <button
                        className="button-tb"
                        style={{
                            width: '72px',
                            margin: '5px',
                            backgroundColor: '#ff5045',
                        }}
                        onClick={() => {
                            setOpenDelete(true);
                            setSelectedNews(row);
                        }}
                    >
                        削除
                    </button>
                }
            </StyledTableCell>
        </StyledTableRow>
    );
};

const News = () => {
    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAllowAction(userData);

    const [isDetail, setIsDetail] = useState<boolean>(false);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [selectedNews, setSelectedNews] = useState<any>(null);

    const [newsList, setNewsList] = useState<any[]>([]);
    const [searchingText, setSearchingText] = useState<any>('');

    const getListNews = async (free_word = '') => {
        try {
            const response = await NewsService.getListNews({
                page: 1,
                per_page: 0,
                free_word,
            });
            if (response !== null && response !== undefined) {
                setNewsList(response.news);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        getListNews(searchingText);
    };

    useEffect(() => {
        getListNews();
    }, []);

    useEffect(() => {
        if (!openEdit) {
            setSelectedNews(null);
        }
    }, [openEdit]);

    useEffect(() => {
        if (!openDelete) {
            setSelectedNews(null);
        }
    }, [openDelete]);

    return (
        <>
            {openCreate && (
                <CreateNewsDialog
                    open={openCreate}
                    setOpen={setOpenCreate}
                    onFinish={() => {
                        getListNews();
                        setOpenCreate(false);
                    }}
                />
            )}
            {openEdit && selectedNews && (
                <EditNewsDialog
                    open={openEdit}
                    setOpen={setOpenEdit}
                    onFinish={() => {
                        getListNews();
                        setOpenEdit(false);
                    }}
                    selectedNews={selectedNews}
                    isDetail={isDetail}
                />
            )}
            {openDelete && selectedNews && (
                <DeleteNewsDialog
                    open={openDelete}
                    setOpen={setOpenDelete}
                    onFinish={() => {
                        getListNews();
                        setOpenDelete(false);
                    }}
                    selectedNews={selectedNews}
                />
            )}

            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <div
                        className="create-button-news d-flex flex-row"
                        style={{ height: '53px' }}
                    >
                        <div className="news-title">お知らせ</div>
                        {isAllow &&
                            <button
                                className="create-user-button d-flex flex-row"
                                onClick={() => setOpenCreate(true)}
                            >
                                <img
                                    width={'16px'}
                                    height={'16px'}
                                    src={plus}
                                ></img>
                                新規作成
                            </button>
                        }
                    </div>
                    <div className="search-client-company-button d-flex flex-row">
                        <div
                            className="input-add-user margin-input d-flex flex-column"
                            style={{ width: '343px' }}
                        >
                            <p>キーワード</p>
                            <input
                                style={{ width: '343px' }}
                                autoComplete="off"
                                type="text"
                                // placeholder="&#xf002;"
                                value={searchingText}
                                onChange={(e) =>
                                    setSearchingText(e.target.value)
                                }
                            ></input>
                        </div>
                        <div className="search-client-company-row-button margin-left d-flex flex-column">
                            <button
                                className="search-user-button d-flex flex-column"
                                onClick={handleSearch}
                                style={{
                                    width: '150px',
                                    height: '36px',
                                    marginBottom: '12px',
                                }}
                            >
                                検索
                            </button>
                        </div>
                    </div>
                    <div
                        className="table-workers table-company d-flex flex-column"
                        style={{ marginTop: '10px' }}
                    >
                        <div className="table-content d-flex flex-column">
                            <TableContainer
                                component={Paper}
                                table-layout={'auto'}
                            >
                                <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="left"
                                                style={{ width: '25%' }}
                                            >
                                                <div>タイトル</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="left"
                                                style={{ width: '50%' }}
                                            >
                                                <div>本文</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '10%' }}
                                            >
                                                <div>お知らせ日</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{
                                                    width: '15%',
                                                    minWidth: '200px',
                                                }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {newsList !== null &&
                                            newsList.map((row, ind) => (
                                                <TableRowCustom
                                                    key={ind}
                                                    row={row}
                                                    setOpenDelete={
                                                        setOpenDelete
                                                    }
                                                    setOpenEdit={setOpenEdit}
                                                    setSelectedNews={
                                                        setSelectedNews
                                                    }
                                                    setIsDetail={setIsDetail}
                                                    isAllow={isAllow}
                                                ></TableRowCustom>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default News;
