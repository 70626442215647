import { useState, useMemo, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import estimateService from 'services/estimationService';
import calendarIcon from 'assets/icons/calendar.png';

import { useLogicEstimate } from '../hooks';
import { validate } from './validation'
// import './styles.css';
import { Main } from './style';
// import { EstimateStatusEnum } from 'utils/enums';

const EstimationCreate = (props) => {
    const navigate = useNavigate();
    const { control } = useForm();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            project_id: 0,
            name: '',
            status: {
                value: '',
                label: ''
            },
            assigned_worker: {
                value: 0,
                label: ''
            },
            approver_worker: {
                value: 0,
                label: ''
            },
            approve_expired_at: '',
            isGotoEdit: false,
        },
        validate,
        onSubmit: values => {
            submitForm(values);
        },
    });

    const {
        id,
        project = {},
        workers = [],
        loading = false,
        statusOptions = [],
        getProjectDetail,
        getListWorkers,
        setLoading
    } = useLogicEstimate()

    useEffect(() => {
        if (id) {
            getProjectDetail(Number(id));
            formik.setValues({
                ...formik.values,
                project_id: Number(id)
            })
        }
        getListWorkers();
    }, [])

    const submitForm = async (data) => {
        setLoading(true)
        let formData = new FormData();
        formData.append("project_id", data.project_id);
        formData.append("status", data.status.value);
        formData.append("assigned_worker_id", data.assigned_worker.value);
        formData.append("approver_worker_id", data.approver_worker.value);
        // formData.append("approve_expired_at", moment(data.approve_expired_at).startOf('day').utc().format('YYYY-MM-DD'));
        formData.append("approve_expired_at", moment(data.approve_expired_at).format('YYYY-MM-DD'));

        return await estimateService
            .createEstimate(formData)
            .then((res) => {
                if (res?.estimate?.id && !data.isGotoEdit) {
                    return navigate(`/projects/detail/${id}/plan?tab=1`)
                }

                if (res?.estimate?.id && data.isGotoEdit) {
                    return navigate(`/projects/estimation/${id}/edit/${res?.estimate?.id}`)
                }
            })
            .catch((err) => {
            }).finally(() => setLoading(false));
    };

    return (
        <Main>
            <div className='container-form-estimation'>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form-create-estimation d-flex flex-column"
                >
                    <div className="title">
                        見積新規作成
                    </div>
                    <div className="form-create-estimation-form d-flex flex-column">
                        <div className="input-add-estimation d-flex">
                            <p className='d-flex'>案件名</p>
                            <div className='d-flex content-right'>
                                {project?.name}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className='d-flex'>見積担当</p>
                            <div className='d-flex content-right'>
                                <Controller
                                    control={control}
                                    name="assigned_worker"
                                    render={() => (
                                        <Dropdown
                                            disabled={loading}
                                            className="modal-dropdown-create-estimation height36"
                                            controlClassName="dropdown-control-plan height36"
                                            options={workers}
                                            value={String(formik.values.assigned_worker.label)}
                                            onChange={(value: any) => formik.setFieldValue('assigned_worker', { ...value })}
                                            placeholder=""
                                        />
                                    )}
                                />
                                {formik.touched.assigned_worker && formik.errors.assigned_worker && (
                                    <span className="error ml-3">{formik.errors.assigned_worker}</span>
                                )}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className='d-flex'>状態</p>
                            <div className='d-flex content-right'>
                                <Controller 
                                    control={control}
                                    name="status"
                                    render={() => (
                                      <Dropdown 
                                          disabled={loading}
                                          className="modal-dropdown-create-estimation height36"
                                          controlClassName="dropdown-control-plan height36"
                                          options={statusOptions}
                                          value={formik.values.status}
                                          onChange={(value: any) => formik.setFieldValue('status', { ...value })}
                                          placeholder=""
                                      />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className='d-flex'>承認担当</p>
                            <div className='d-flex content-right'>
                                <Controller
                                    control={control}
                                    name="approver_worker"
                                    render={() => (
                                        <Dropdown
                                            disabled={loading}
                                            className="modal-dropdown-create-estimation height36"
                                            controlClassName="dropdown-control-plan height36"
                                            options={workers}
                                            value={String(formik.values.approver_worker.label)}
                                            onChange={(value: any) => formik.setFieldValue('approver_worker', { ...value })}
                                            placeholder=""
                                        />
                                    )}
                                />
                                {formik.touched.approver_worker && formik.errors.approver_worker && (
                                    <span className="error ml-3">{formik.errors.approver_worker}</span>
                                )}
                            </div>
                        </div>
                        <div className="input-add-estimation d-flex">
                            <p className='d-flex'>承認期限</p>
                            <div className='content-right d-flex'>
                                <div className="datepicker-wrap">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{ position: 'relative' }}
                                        >
                                            <Controller
                                                control={control}
                                                name="approve_expired_at"
                                                render={() => (
                                                    <DatePicker
                                                        selectsStart
                                                        dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                        selected={formik.values.approve_expired_at}
                                                        startDate={formik.values.approve_expired_at}
                                                        onChange={(date) => {
                                                            formik.setFieldValue("approve_expired_at", date)
                                                        }}
                                                        disabled={loading}
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                                {formik.touched.approve_expired_at && formik.errors.approve_expired_at && (
                                    <span className="error ml-3">{formik.errors.approve_expired_at}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="group-buttons d-flex flex-row">
                        <Link
                            className={`bnt bnt-cancel d-flex flex-row ${loading && 'disable-link'}`}
                            to={`/projects/detail/${id}/plan?tab=1`}
                        >
                            戻る
                        </Link>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            type="submit"
                            disabled={loading}
                        >
                            登録
                        </Button>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            onClick={() => {
                                formik.setFieldValue("isGotoEdit", true)
                                formik.handleSubmit()
                            }}
                            disabled={loading}
                        >
                            登録して見積編集
                        </Button>
                    </div>
                </form>
            </div>
        </Main>
    );
};

export default EstimationCreate;
