import styled from 'styled-components';

export const Main = styled.div`
    background-color: #e3e6e8;
    padding: 28px;
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';

    .suggestion-detail-content {
        background: #fff;
        border-radius: 12px;
        min-height: calc(100% - 56px);
        padding: 28px;
        width: 100%;
        /* display: flex; */
        /* gap: 30px; */
    }

    .form-suggestion-detail {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #cdd1d5;
        border-radius: 8px;
        border-left: none;
    }

    .input-suggestion {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        background: #c7dcf5;

        .search-select-style {
            width: 350px !important;
            height: 36px !important;

            .MuiOutlinedInput-root {
                height: 36px !important;
            }

            fieldset {
                height: 43px !important;
            }
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .content-right {
                border-top-right-radius: 8px;
            }
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .content-right {
                border-bottom-right-radius: 8px;
            }

            .content-right {
                border-bottom: none;
                position: relative;
            }
        }

        .input-text,
        .input-number {
            background: #fff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
            width: 350px;
            padding: 10px 12px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-number {
            position: relative;
            padding-left: 26px;
        }

        .modal-dropdown-suggestion {
            width: 350px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            height: 36px;
            background: #ffffff;
        }

        .dropdown-control-suggestion {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            height: 38px;
            background: #ffffff;
            border: 1px solid #cdd1d5 !important;
            border-radius: 8px !important;
        }

        p {
            width: 200px;
            min-height: 58px;
            /* background: #c7dcf5; */
            margin-bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding-left: 12px;
            align-items: center;
        }

        .content-right {
            width: 100%;
            /* align-items: center; */
            background: #ffffff;
            flex-direction: column;
            padding: 10px 0;
            padding-left: 12px;
            min-height: 58px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #1c1e21;
            border-bottom: 1px solid #f5f5f5;
            position: relative;

            .error {
                margin-top: 10px;
            }

            .character {
                position: absolute;
                z-index: 1;
                margin-left: 10px;
                top: 16px;
            }
        }
    }

    .group-buttons {
        width: 100%;
        height: 20%;
        align-items: center;
        margin-top: 20px;
    }

    .bnt.Mui-disabled {
        background: rgb(0 0 0 / 13%) !important;
    }

    .bnt {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #215493;
        background: #ffffff;
        width: 167.5px;
        align-items: center;
        justify-content: center;
        height: 44px;
        margin-right: 8px;
        border-radius: 8px;
    }

    .bnt-cancel {
        border: 1px solid rgba(33, 84, 147, 0.3);
    }

    .bnt-submit {
        background: #215493 !important;
        color: #ffffff !important;
    }
`;
