import { FC } from 'react';

type IProps = {
  strokeColor?: string;
};

const FileArrowDown: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21Z" 
        stroke={strokeColor} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M14.25 3V8.25H19.5" 
        stroke={strokeColor}
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9.375 14.625L12 17.25L14.625 14.625" 
        stroke={strokeColor}
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M12 11.25V17.25" 
        stroke={strokeColor}
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FileArrowDown;