import { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useFormik } from 'formik';
import { ApplyRuleEnum } from 'utils/enums';
import UpdateRepeatScheduleDialogWrapper from './style';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type IProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClick: (duplicate_apply_type: string) => void;
    changedDuplicateType: boolean;
};

const UpdateRepeatScheduleDialog: FC<IProps> = ({ open, setOpen, onClick, changedDuplicateType }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            duplicate_apply_type: changedDuplicateType ? 'this_and_following' : 'only_schedule',
        },
        onSubmit: values => {
          onClick(values.duplicate_apply_type);
        },
    });

    return (
        <BootstrapDialog
            className="customBootstrapDialog deleteConfirmationDialog"
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <UpdateRepeatScheduleDialogWrapper>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <div className="modal-create-user-title d-flex flex-row">
                          定期的なイベントを編集する
                        </div>

                        <div className="wrapRadioField">
                          <FormControl fullWidth>
                            <RadioGroup
                              row
                              name="duplicate_apply_type"
                              value={formik.values.duplicate_apply_type}
                              onChange={e => {
                                formik.setFieldValue('duplicate_apply_type', e.target.value);
                              }}
                            >
                              {ApplyRuleEnum.map(category => {
                                if (changedDuplicateType && category.id === "only_schedule") {
                                  return <></>;
                                }
                                return (
                                  <FormControlLabel
                                    key={category.id}
                                    value={category.id}
                                    control={
                                      <Radio
                                        checked={formik.values.duplicate_apply_type === category.id}
                                      />
                                    }
                                    label={category.name}
                                  />
                                )
                              })}
                            </RadioGroup>
                          </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions className="!justify-center">
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{
                                width: 152,
                            }}
                            type="submit"
                        >
                            はい
                        </button>
                        <button
                            className="modal-create-plan-button1 d-flex flex-row"
                            style={{
                                width: 152,
                            }}
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            いいえ
                        </button>
                    </DialogActions>
                </form>
            </UpdateRepeatScheduleDialogWrapper>
        </BootstrapDialog>
    );
};

export default UpdateRepeatScheduleDialog;
