const ProjectTabelRow = (props) => {
    return (
        <div className="project-detail-inf-row d-flex flex-row">
            {props.titles.map((title, index) => (
                <>
                    <div className="project-detail-inf-row-title">{title}</div>
                    <div className="project-detail-inf-row-content text-table">
                        {props?.values?.[index]}
                    </div>
                </>
            ))}
        </div>
    );
};

const ProjectTable = ({ currentProject }) => {
    return (
        <div className="d-flex">
            <img
                style={{
                    height:
                        currentProject?.business_type == '産廃'
                            ? '100%'
                            : '282px',
                }}
                className="project-detail-inf-image"
                src={
                    currentProject?.image?.image_path ||
                    process.env.PUBLIC_URL + '/img/login-background.png'
                }
                alt="project_image"
            />
            <div className="project-detail-inf d-flex flex-column">
                {currentProject?.business_type == '産廃' ? (
                    <>
                        <ProjectTabelRow
                            // titles={['顧客', '産廃']}
                            titles={['顧客', '業種']}
                            values={[
                                currentProject?.client_company?.company?.name,
                                currentProject?.business_type,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['場所', '工期']}
                            values={[
                                currentProject?.location_address,
                                `${currentProject?.start_at} ~ ${currentProject?.end_at}`,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['金額', '見積担当']}
                            values={[
                                currentProject?.total_amount,
                                currentProject?.management_charge,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['収集運搬', '処分先']}
                            values={[
                                currentProject?.collection_and_transportation
                                    ?.reduce(
                                        (result, item) =>
                                            (result += item + '、'),
                                        '',
                                    )
                                    .slice(0, -1),
                                currentProject?.destination
                                    ?.reduce(
                                        (result, item) =>
                                            (result += item + '、'),
                                        '',
                                    )
                                    .slice(0, -1),
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['管理者', '担当社員']}
                            values={[
                                currentProject?.manager_worker?.name,
                                <div>
                                    {currentProject?.assigned_project_workers_attributes &&
                                        currentProject?.assigned_project_workers_attributes?.map(
                                            (worker, index) => (
                                                <span>
                                                    {`${worker?.name}${
                                                        index + 1 <
                                                        currentProject
                                                            ?.assigned_project_workers_attributes
                                                            .length
                                                            ? '、'
                                                            : ''
                                                    }`}
                                                </span>
                                            ),
                                        )}
                                </div>,
                            ]}
                        />
                    </>
                ) : (
                    <>
                        <ProjectTabelRow
                            titles={['顧客', '業種']}
                            values={[
                                currentProject?.client_company?.company?.name,
                                currentProject?.business_type,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['場所', ' 工期']}
                            values={[
                                currentProject?.location_address,
                                `${currentProject?.start_at} ~ ${currentProject?.end_at}`,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['金額', '見積担当']}
                            values={[
                                '',
                                currentProject?.estimate_assigned_worker?.name,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['協力会社', '管理者']}
                            values={[
                                currentProject?.cooperative_companies
                                    ?.reduce(
                                        (result, item) =>
                                            (result += item?.company?.name + '、'),
                                        '',
                                    )
                                    .slice(0, -1),
                                // <ul className="cooperative-companies-list">
                                //     {currentProject &&
                                //     currentProject.cooperative_companies ? (
                                //         currentProject.cooperative_companies.map(
                                //             (item) => (
                                //                 <li>{item.company.name}</li>
                                //             ),
                                //         )
                                //     ) : (
                                //         <></>
                                //     )}
                                // </ul>,
                                currentProject?.manager_worker?.name,
                            ]}
                        />
                        <ProjectTabelRow
                            titles={['担当社員', '']}
                            values={[
                                <div>
                                    {currentProject?.assigned_project_workers_attributes &&
                                        currentProject?.assigned_project_workers_attributes?.map(
                                            (worker, index) => (
                                                <span>
                                                    {`${worker?.name}${
                                                        index + 1 <
                                                        currentProject
                                                            ?.assigned_project_workers_attributes
                                                            .length
                                                            ? '、'
                                                            : ''
                                                    }`}
                                                </span>
                                            ),
                                        )}
                                </div>,
                            ]}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
export default ProjectTable;
