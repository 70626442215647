import styled from "styled-components";

export default styled.div`
  .dx-item.dx-scheduler-appointment {
    background: rgb(245, 245, 245);
    border-radius: 4px;
    color: rgb(56, 142, 60);
    box-shadow: none !important;
    // height: 60px !important;

    &.dx-scheduler-appointment-empty {
      // width: 200px !important;
      margin-left: 2px;
    }
  }

  // &.Day {
  //   .dx-scheduler-header-panel-empty-cell,
  //   .dx-scheduler-group-table {
  //     display: none;
  //   }
  // }

  .dx-scheduler {
    .dx-scheduler-container {
      border: 2px solid rgb(191, 191, 191);
      border-radius: 5px;
    }

    .dx-scheduler-header-panel,
    .dx-scheduler-date-table {
        width: 2600px !important;
    }

    .dx-scheduler-appointment-content {
      padding: 0;
      border-radius: 4px;
    }

    .dx-scheduler-header-panel-cell {
      border-left: 1px solid rgb(224, 224, 224);
    }

    .dx-scheduler-header-panel,
    .dx-scheduler-header-panel-empty-cell {
      margin-top: 0;
    }

    .dx-scheduler-timeline.dx-scheduler-work-space-both-scrollbar
      .dx-scheduler-header-panel,
    .dx-scheduler-timeline.dx-scheduler-work-space-both-scrollbar
      .dx-scheduler-header-panel-empty-cell {
      border-bottom: 1px solid rgb(224, 224, 224);
    }

    .dx-scheduler-header-panel-empty-cell {
      border-right: 0;
    }

    .dx-scheduler-timeline.dx-scheduler-work-space-grouped
      .dx-scheduler-group-row
      .dx-scheduler-group-header {
      border-bottom: 1px solid rgb(224, 224, 224);
      border-top: 1px solid #ffffff;
    }

    .dx-scheduler-work-space-grouped.dx-scheduler-timeline
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-timeline
      .dx-scheduler-last-group-cell,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-group-by-date
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-vertical-grouped
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-vertical-grouped
      .dx-scheduler-last-group-cell {
      border-right: 0;
    }

    .dx-scheduler-work-space-grouped.dx-scheduler-timeline
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-timeline
      .dx-scheduler-last-group-cell,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-group-by-date
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-vertical-grouped
      .dx-scheduler-group-row
      th,
    .dx-scheduler-work-space-grouped.dx-scheduler-work-space-vertical-grouped
      .dx-scheduler-last-group-cell {
      border-left: 1px solid rgb(224, 224, 224);
    }

    .dx-scheduler-group-flex-container
      .dx-scheduler-group-row:last-child
      .dx-scheduler-group-header {
      border-right: 0;
    }
  }

  .dx-scheduler-header {
    display: none;
  }
  .hiddenTime {
    border-left: none !important;
  }
`;
