/* eslint-disable import/first */
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import "./project.css";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/icons/Plus.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useModal } from "react-hooks-use-modal";
import projectsService from "../../services/projectsService";
import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../assets/icons/calendar.png";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import userService from "services/userService";
/* eslint-disable import/first */
import CompanyService from "services/clientCompanyService";
import workersService from "services/wokersService";
import { convertObjectToDropdownOptions } from "utils/helper/dropdownHelper";
import { convertUserConfigToDropdownOptions } from "utils/helper/userConfigHelper";
import { alertError } from "utils/helper/appHelper";
import { convertClientCompaniesToDropdownOptionsCreateProject } from "utils/helper/clientCompanyHelper";
import CustomPagination from "components/CustomPagination";
import { IPageQueries } from "models";
import ViewToggle from "./projectPlan/components/ViewToggle";
import ProjectCard from "./projectPlan/components/ProjectCard";
import { SugorokuIndexEnum, WasteSugorokuIndexEnum } from "utils/enums";

import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#C7DCF5",
    color: "#1C1E21",
    fontWeight: 600,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Zen Kaku Gothic Antique",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C1E21",
    padding: "4px 14px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Projects = (props) => {
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [projectsList, setProjectsList] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageQueries, setPageQueries] = useState<IPageQueries>({
    page: 1,
    per_page: 20,
  });
  const [searchedState, setSearchedState] = useState({});
  const [cardViewMode, setCardViewMode] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentProject, setCurrentProject] = useState<any>(null);

  // Configurations
  const [statuses, setStatuses] = useState<any>([]);
  const [statusBase, setStatusBase] = useState<any>({});
  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [listCompanies, setListCompanies] = useState<any>([]);

  const [listWorkers, setListWorkers] = useState<any>([]);

  const handleStartAtChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndAtChange = (date: any) => {
    setEndDate(date);
  };

  const onSearch = (data) => {
    setSearchedState({ ...data });
    if (pageQueries.page !== 1) {
      setPageQueries((prevState) => ({ ...prevState, page: 1 }));
    }
    getListProjects({ page: 1, per_page: pageQueries.per_page, data: data });
  };

  const getListConfiguration = async () => {
    try {
      const response = await userService.getListConfiguration();
      if (response !== null && response !== undefined) {
        setStatuses(
          [
            ...Object.values(SugorokuIndexEnum).map((v) => ({
              value: v.name,
              label: `解体 - ${Math.floor(v.value / 10)}. ${v.title}`,
            })),
            ...Object.values(WasteSugorokuIndexEnum).map((v, i) => ({
              value: v.name,
              label: `産廃 - ${i + 1}. ${v.title}`,
            })),
          ]
          // convertUserConfigToDropdownOptions(
          //   response.configurations?.project?.statuses
          // )
        );
        setStatusBase(response.configurations?.project?.statuses);
        setBusinessTypes(
          convertUserConfigToDropdownOptions(
            response.configurations?.project?.business_types
          )
        );
      }
    } catch (err) {
      // empty
    }
  };

  const getListClientCompanies = async () => {
    try {
      const response = await CompanyService.getListCompanies({ per_page: 999 });
      if (response !== null && response !== undefined) {
        setListCompanies(
          convertClientCompaniesToDropdownOptionsCreateProject(
            response.client_companies
          )
        );
      }
    } catch (err) {
      // empty
    }
  };

  const getListWorkers = async () => {
    try {
      const response = await workersService.getListWorkers({ per_page: 999 });
      if (response !== null && response !== undefined) {
        setListWorkers(convertObjectToDropdownOptions(response.workers));
      }
    } catch (err) {
      // empty
    }
  };

  const getListProjects = async ({ page = 1, per_page = 5, data = {} }) => {
    try {
      const response = await projectsService.getListProjects({
        page,
        per_page,
        ...data,
      });
      if (response !== null && response !== undefined) {
        setProjectsList(response.projects);
        setTotalCount(response.meta.total_count);
      }
    } catch (err) {
      // empty
    }
  };

  const deleteProject = async () => {
    await projectsService
      .deleteProject(currentProject.id)
      .then((data) => {
        if (pageQueries.page !== 1) {
          setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListProjects({
          page: 1,
          per_page: pageQueries.per_page,
          data: { ...searchedState },
        });
      })
      .catch((err) => {
        const response = err?.response;
        if (!response || typeof response === "undefined") {
          navigate("login");
          return;
        }
        alertError(response.data?.error);
      })
      .finally(() => {
        setCurrentProject(null);
        closeDelete();
      });
  };

  useEffect(() => {
    getListConfiguration();
    getListClientCompanies();
    getListWorkers();
  }, []);

  useEffect(() => {
    getListProjects({ page: pageQueries.page, per_page: pageQueries.per_page });
  }, []);

  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    "root",
    {
      preventScroll: true,
      closeOnOverlayClick: false,
    }
  );

  return (
    <div className="container-worker d-flex flex-row">
      <div className="worker-content d-flex flex-column">
        <div
          className="create-button d-flex flex-row"
          style={{ height: "53px" }}
        >
          <button
            className="create-user-button d-flex flex-row"
            onClick={() => navigate("/projects/create")}
          >
            <img width={"16px"} height={"16px"} src={plus}></img>
            新規作成
          </button>
        </div>
        <form onSubmit={handleSubmit(onSearch)}>
          <div
            className="search-workers d-flex flex-column"
            style={{ height: "200px" }}
          >
            <div className="search-workers-row d-flex flex-row">
              <div className="search-workers-row-dropdown d-flex flex-column">
                <div className="input-add-user d-flex flex-column">
                  <p style={{ marginTop: 0 }}>キーワード</p>
                  <input
                    className="height36 search-icon"
                    autoComplete="off"
                    id="input-search"
                    type="text"
                    {...register("free_word")}
                    placeholder="&#xf002;"
                  ></input>
                </div>
              </div>
              <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                <div className="input-add-user d-flex flex-column">
                  <p style={{ marginTop: 0 }}>顧客</p>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listCompanies.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    getOptionLabel={(option: any) =>
                      option.label || option.value
                    }
                    onChange={(event, newValue: any) => {
                      if (newValue) {
                        setValue("client_company_id", newValue);
                      } else {
                        setValue("client_company_id", undefined);
                      }
                    }}
                    classes={{
                      root: "project-list-search-select-style",
                      input: "project-list-search-select-style",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        className="autocomplete-custom-textfield"
                      />
                    )}
                    noOptionsText="該当なし"
                  />
                </div>
              </div>
              <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                <div className="input-add-user d-flex flex-column">
                  <p style={{ marginTop: 0 }}>業種</p>
                  <Controller
                    name="business_type"
                    control={control}
                    render={({ field: { value } }) => (
                      <Dropdown
                        className="dropdown-create-user1 height36"
                        controlClassName="dropdown-control-user height36"
                        options={businessTypes.filter(
                          (v) => v.label !== "不動産"
                        )}
                        value={{
                          label: value?.label || "",
                          value: value?.value || "",
                        }}
                        placeholder=""
                        onChange={(option) => {
                          if (value && value.value === option.value) {
                            setValue("business_type", undefined);
                          } else {
                            setValue("business_type", option);
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="search-workers-row d-flex flex-row">
              <div className="search-workers-row-dropdown d-flex flex-column">
                <div className="input-add-user d-flex flex-column">
                  <p style={{ marginTop: "8px" }}>管理者</p>
                  <Autocomplete
                    disablePortal
                    options={listWorkers.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    getOptionLabel={(option: any) =>
                      option.label || option.value
                    }
                    onChange={(event, newValue: any) => {
                      if (newValue) {
                        setValue("manager_worker_id", newValue);
                      } else {
                        setValue("manager_worker_id", undefined);
                      }
                    }}
                    classes={{
                      root: "project-list-search-select-style",
                      input: "project-list-search-select-style",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        className="autocomplete-custom-textfield"
                      />
                    )}
                    noOptionsText="該当なし"
                  />
                </div>
              </div>
              <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                <div className="input-add-user d-flex flex-column">
                  <p style={{ marginTop: "8px" }}>ステータス</p>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { value } }) => {
                      return (
                        <Dropdown
                          className="dropdown-create-user1 height36"
                          controlClassName="dropdown-control-user height36"
                          options={statuses}
                          value={{
                            label: value?.label || "",
                            value: value?.value || "",
                          }}
                          placeholder=""
                          onChange={(option) => {
                            if (value && value.value === option.value) {
                              setValue("status", undefined);
                            } else {
                              setValue("status", option);
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div
                className="input-add-user d-flex flex-column"
                style={{ width: "33.33%", marginLeft: "28px" }}
              >
                <p style={{ marginTop: "8px" }}>期間</p>
                <div className="datepicker-wrap">
                  <div className="target-datepicker-wrap d-flex flex-row">
                    <div
                      className="input-date"
                      style={{ position: "relative" }}
                    >
                      <Controller
                        name="start_at"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            selected={startDate}
                            {...field}
                            onChange={(date) => {
                              field?.onChange(date);
                              handleStartAtChange(date);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsStart
                            maxDate={endDate}
                            dateFormat="yyyy/MM/dd"
                            autoComplete="off"
                            locale="ja"
                          />
                        )}
                      />
                      <img
                        className="input-date-img"
                        width={"16px"}
                        height={"16px"}
                        src={calendarIcon}
                      ></img>
                    </div>
                    <span className="symbol">~</span>
                    <div
                      className="input-date"
                      style={{ position: "relative" }}
                    >
                      <Controller
                        name="end_at"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            selected={endDate}
                            {...field}
                            onChange={(date) => {
                              field?.onChange(date);
                              handleEndAtChange(date);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsEnd
                            minDate={startDate}
                            dateFormat="yyyy/MM/dd"
                            autoComplete="off"
                            locale="ja"
                          />
                        )}
                      />
                      <img
                        className="input-date-img"
                        width={"16px"}
                        height={"16px"}
                        src={calendarIcon}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-workers-row-button width-full margin-left d-flex flex-column">
              <button
                className="search-user-button d-flex flex-column"
                type="submit"
                style={{ width: "150px", height: "36px", marginBottom: "12px" }}
              >
                検索
              </button>
            </div>
          </div>
        </form>

        <ViewToggle
          cardViewMode={cardViewMode}
          setCardViewMode={setCardViewMode}
        />

        <div className="table-workers d-flex flex-column">
          <div className="table-content d-flex flex-column">
            {!cardViewMode ? (
              <TableContainer component={Paper} table-layout={"auto"}>
                <Table sx={{ minWidth: 1420 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "15%" }}
                      >
                        <div>案件名</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "5%" }}
                      >
                        <div style={{ whiteSpace: "nowrap" }}>業種</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        <div style={{ whiteSpace: "nowrap" }}>顧客</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "5%", whiteSpace: "nowrap" }}
                      >
                        <div>状態</div>
                      </StyledTableCell>
                      {/* <StyledTableCell className='text-overflow1' align="center" style={{ width: '10%' }}>
                      <div className='text-overflow1'>役職</div>
                    </StyledTableCell> */}
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "5%" }}
                      >
                        <div>開始</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "5%" }}
                      >
                        <div>終了</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "15%" }}
                      >
                        <div>見積担当</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "10%" }}
                      >
                        <div>管理者</div>
                      </StyledTableCell>
                      <StyledTableCell
                        className="text-overflow1"
                        align="center"
                        style={{ width: "30%" }}
                      >
                        <div>操作</div>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectsList !== null &&
                      projectsList.map((row, ind) => (
                        <StyledTableRow key={ind}>
                          <StyledTableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ width: "15%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.name}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "5%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.business_type}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "20%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.client_company?.company?.name}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "5%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.status !== "none" ? row.status : ""}
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell align="left" style={{ width: '10%' }}>
                        <div className='text-overflow1' style={{ whiteSpace: "nowrap" }}>{row.location_address}</div>
                      </StyledTableCell> */}
                          <StyledTableCell
                            align="center"
                            style={{ width: "5%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.start_at
                                ? moment(row.start_at).format("YYYY/MM/DD")
                                : ""}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "5%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.end_at
                                ? moment(row.end_at).format("YYYY/MM/DD")
                                : ""}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "15%" }}
                          >
                            <div className="text-overflow1">
                              {row.estimate_assigned_worker?.name}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            <div
                              className="text-overflow1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {row.manager_worker?.name}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            style={{ width: "30%", minWidth: "250px" }}
                          >
                            <button
                              className="button-tb"
                              style={{ width: "72px", margin: "5px" }}
                              onClick={() => {
                                navigate(`/projects/detail/${row.id}/plan`, {
                                  state: { id: row.id },
                                });
                              }}
                            >
                              詳細
                            </button>
                            <button
                              className="button-tb"
                              style={{ width: "72px", margin: "5px" }}
                              onClick={() => {
                                navigate(`/projects/edit/${row.id}`);
                              }}
                            >
                              編集
                            </button>
                            <button
                              className="button-tb"
                              style={{
                                width: "72px",
                                margin: "5px",
                                background: "#FF5045",
                              }}
                              onClick={() => {
                                openDelete();
                                setCurrentProject(row);
                              }}
                            >
                              削除
                            </button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  gap: "28px",
                }}
              >
                {projectsList !== null &&
                  projectsList.map((item) => (
                    <ProjectCard
                      key={item.id}
                      name={item.name}
                      startDate={item.start_at || ""}
                      imagePath={item.image?.image_path || ""}
                      onClick={() => {
                        navigate(`/projects/detail/${item.id}/plan`, {
                          state: { id: item.id },
                        });
                      }}
                    />
                  ))}
              </div>
            )}
            {/* <Grid container alignItems="center" justifyContent="center" mt="15px" mb="10px">
              <Grid item>
                <Pagination count={10} variant="outlined" color="primary" />
              </Grid>
            </Grid> */}
            <ModalDelete>
              <div
                className="modal-create-user d-flex flex-column"
                style={{ height: "152px" }}
              >
                <div className="modal-create-user-title d-flex flex-row">
                  本当に削除しますか?
                </div>
                <div
                  className="modal-create-user-buttons d-flex flex-row"
                  style={{ marginTop: "50px" }}
                >
                  <button
                    className="modal-create-user-button1 d-flex flex-row"
                    onClick={closeDelete}
                  >
                    キャンセル
                  </button>
                  <button
                    onClick={deleteProject}
                    className="modal-create-user-button2 d-flex flex-row"
                  >
                    はい
                  </button>
                </div>
              </div>
            </ModalDelete>
          </div>
        </div>
        {projectsList && projectsList.length > 0 && (
          <CustomPagination
            totalCount={totalCount}
            pageQueries={pageQueries}
            setPageQueries={setPageQueries}
            fetchList={(page) =>
              getListProjects({
                page,
                per_page: pageQueries.per_page,
                data: { ...searchedState },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default Projects;
