import { useState, useEffect } from "react";
import "./ads.css";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/icons/Plus.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useModal } from "react-hooks-use-modal";
import adsService from "../../services/adsService";

import "react-dropdown/style.css";
import { IPageQueries } from "models";
import CustomPagination from "components/CustomPagination";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { isAllowAction } from "utils/helper/workerHelper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#C7DCF5",
        color: "#1C1E21",
        paddingTop: "8px",
        paddingBottom: "8px",
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Zen Kaku Gothic Antique",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C1E21",
        padding: "4px 16px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const TableRowCustom = (props) => {
    const { row, openDelete, setAdsDelete, isAllow } = props;
    const navigate = useNavigate();

    return (
        <StyledTableRow>
            <StyledTableCell align="left" component="th" scope="row">
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {row?.name}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left">
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {row?.start_date}
                </div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <button
                    className="button-tb"
                    style={{ width: "72px", margin: "5px" }}
                    onClick={() => {
                        navigate(`/ads/detail/${row.id}`);
                    }}
                >
                    詳細
                </button>
                {isAllow && (
                    <button
                        className="button-tb"
                        style={{ width: "72px", margin: "5px" }}
                        onClick={() => {
                            navigate(`/ads/edit/${row.id}`);
                        }}
                    >
                        編集
                    </button>
                )}
                {isAllow && (
                    <button
                        className="button-tb"
                        style={{
                            width: "72px",
                            margin: "5px",
                            background: "#FF5045",
                        }}
                        onClick={() => {
                            openDelete();
                            setAdsDelete(row);
                        }}
                    >
                        削除
                    </button>
                )}
            </StyledTableCell>
        </StyledTableRow>
    );
};

const Ads = (props) => {
    const navigate = useNavigate();

    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAllowAction(userData);

    const [adsList, setAdsList] = useState<any[]>([]);

    const [searchingText, setSearchingText] = useState<any>("");
    // const [totalCount, setTotalCount] = useState<number>(0);
    const [searchedState, setSearchedState] = useState<string>("");
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [adsDelete, setAdsDelete] = useState<any>(null);

    const getListAds = async ({ page = 1, per_page = 1000, name = "" }) => {
        try {
            const response = await adsService.getListAds({
                page,
                per_page,
                name,
            });
            if (response !== null && response !== undefined) {
                setAdsList(response.ads_managements);
                // setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    const deleteAds = async () => {
        try {
            const response = await adsService.deleteAds(adsDelete.id);
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListAds({
                    page: 1,
                    per_page: pageQueries.per_page,
                    name: searchedState,
                });
                setAdsDelete(null);
                closeDelete();
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    const handleSearch = () => {
        setSearchedState(searchingText);
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListAds({
            page: 1,
            per_page: pageQueries.per_page,
            name: searchingText,
        });
    };

    const [winReady, setwinReady] = useState(false);
    useEffect(() => {
        setwinReady(true);
    }, []);

    useEffect(() => {
        getListAds({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        "root",
        {
            preventScroll: true,
            closeOnOverlayClick: false,
        }
    );

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: "53px" }}
                >
                    {isAllow && (
                        <button
                            className="create-user-button d-flex flex-row"
                            onClick={() => navigate("/ads/create")}
                        >
                            <img
                                width={"16px"}
                                height={"16px"}
                                src={plus}
                            ></img>
                            新規作成
                        </button>
                    )}
                </div>
                <div className="search-client-company-button d-flex flex-row">
                    <div
                        className="input-add-user margin-input d-flex flex-column"
                        style={{ width: "343px" }}
                    >
                        <p>キーワード</p>
                        <input
                            style={{ width: "343px" }}
                            autoComplete="off"
                            type="text"
                            // placeholder="&#xf002;"
                            value={searchingText}
                            onChange={(e) => setSearchingText(e.target.value)}
                        ></input>
                    </div>
                    <div className="search-client-company-row-button margin-left d-flex flex-column">
                        <button
                            className="search-user-button d-flex flex-column"
                            onClick={handleSearch}
                            style={{
                                width: "150px",
                                height: "36px",
                                marginBottom: "12px",
                            }}
                        >
                            検索
                        </button>
                    </div>
                </div>
                <div
                    className="table-workers table-company d-flex flex-column"
                    style={{ marginTop: "10px" }}
                >
                    <div className="table-content d-flex flex-column">
                        {winReady ? (
                            <TableContainer
                                component={Paper}
                                table-layout={"auto"}
                            >
                                <Table
                                    sx={{ minWidth: 600 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="left"
                                            >
                                                <div>タイトル</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="left"
                                            >
                                                <div>開始日</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{
                                                    minWidth: "200px",
                                                }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {adsList !== null &&
                                            adsList.map((row, ind) => (
                                                <TableRowCustom
                                                    key={ind}
                                                    row={row}
                                                    openDelete={openDelete}
                                                    setAdsDelete={setAdsDelete}
                                                    isAllow={isAllow}
                                                ></TableRowCustom>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: "152px" }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか？
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: "50px" }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        onClick={deleteAds}
                                        className="modal-create-user-button2 d-flex flex-row"
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ads;
