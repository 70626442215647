import { Autocomplete, CircularProgress, Dialog, Grid, styled, TextField } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { useTranslation } from "react-i18next";
import isEmpty from 'lodash/isEmpty';
import updateWorkingLogValidation from "utils/validation/registers/workingLogs/updateWorkingLogValidation";
import calendarIcon from 'assets/icons/calendar.png';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import StyledWorkingLogForm from "./style";
// import { getFilePreview } from "utils/helper/file";
// import moment from "moment";
import workingLogService from "services/workingLogService";
import { convertErrorMessagesToObject } from "utils/validation/validatorHelper";
import { getFilePreview } from "utils/helper/file";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  plans: any[];
  workers: any[];
  onFinishUpdate: () => void;
  project_id: string;
  selectedWorkingLog: any;
}

registerLocale('ja', ja);

const Input = styled('input')({
  display: 'none',
});

const UpdateWorkingLogDialog: FC<IProps> = (props) => {
  const { 
    open, 
    setOpen, 
    plans = [], 
    workers = [], 
    onFinishUpdate, 
    project_id, 
    selectedWorkingLog 
  } = props;

  const getCurrentWorkingLogValues = () => {
    const currentLog = selectedWorkingLog;
    const plan = plans.find((item) => item.id === currentLog?.plan?.id);
    const worker = workers.find((item) => item.id === currentLog?.worker?.id);

    return {
      ...currentLog,
      worked_at: currentLog.worked_at ? new Date(currentLog.worked_at) : null,
      plan_id: plan ? { value: plan.id, label: plan.name } : null,
      worker: worker ? { value: worker.id, label: worker.name } : null,
    }
  }

  const { 
    register, 
    control, 
    handleSubmit, 
    setError, 
    setValue, 
    formState: { errors } 
  } = useForm({
    defaultValues: selectedWorkingLog ? getCurrentWorkingLogValues() : {},
  });
  const { t } = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const [newImageFiles, setNewImageFiles] = useState<any[]>([]);
  const [hasUploadedImagesDeleted, setHasUploadedImagesDeleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedWorkingLog &&
      selectedWorkingLog.working_log_images &&
      selectedWorkingLog.working_log_images[0]
    ) {
      setUploadedFile(selectedWorkingLog.working_log_images[0].images);
    }
  }, [selectedWorkingLog])

  const handleUploadFile = (files) => {
    if (files.length <= 0) return;

    setNewImageFiles((prev) => ([...prev, files[0]]));
  }

  const handleRemoveNewImageFile = (idx) => {
    setNewImageFiles((prev) => ([...prev.filter((item, index) => index !== idx)]));
  }

  const handleRemoveUploadedImage = (signed_id) => {
    setUploadedFile((prev) => {
      const newFilesArray = [...prev];
      let target = newFilesArray.find((item) => item.signed_id === signed_id);
      const targetIndex = newFilesArray.findIndex((item) => item.signed_id === signed_id);
      target = {...target, _destroy: true};
      newFilesArray[targetIndex] = target;
      return newFilesArray;
    })

    if (!hasUploadedImagesDeleted) {
      setHasUploadedImagesDeleted(true);
    }
  }

  const handleUpdateNewImage = async () => {
    if (newImageFiles.length === 0) return;

    let formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('id', selectedWorkingLog.id);
    newImageFiles.forEach((item) => formData.append('working_log_images[]', item));

    try {
      const response = await workingLogService.updateImages(formData, selectedWorkingLog.id, project_id);
      if (response) {
        return true;
      }
    } catch (error) {
      // empty
    }
  }

  const handleUpdateDeletedImage = () => {
    const promisesArray: Promise<unknown>[] = [];
    uploadedFile.forEach((item) => {
      if (item._destroy) {
        promisesArray.push(new Promise(async (resolve, reject) => {
          await workingLogService
          .deleteImage(selectedWorkingLog.id, project_id, item.signed_id)
          .then(() => resolve(true))
          .catch((err) => reject(err))
        }));
      }
    })
    return Promise.all(promisesArray)
  }

  const handleUpdateWorkingLog = async (data) => {
    if (!data.id) return;
    setLoading(true);

    let formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('plan_id', data.plan_id.value);
    formData.append('worker_id', data.worker.value);
    formData.append('name', data.name);
    formData.append('note', data.note);
    formData.append('worked_at', data.worked_at);

    if (hasUploadedImagesDeleted) {
      await handleUpdateDeletedImage();
    }

    if (newImageFiles.length > 0) {
      await handleUpdateNewImage();
    }

    return new Promise(async (resolve, reject) => {
      await workingLogService
        .updateWorkingLog(formData, selectedWorkingLog.id)
        .then(() => {
          setLoading(false);
          onFinishUpdate();
        })
        .catch((err) => {
          const response = err?.response;
          if (!response || typeof response === 'undefined') {
              window.location.href = '/login';
              reject(err);
          }
          switch (response.status) {
            case 403:
              const messages = convertErrorMessagesToObject(
                {
                  name: 'name',
                  worked_at: 'start_at',
                  plan_id: 'assigned_worker_id',
                  note: 'note'
                },
                response.data?.error
              );
              if (!isEmpty(messages)) {
                Object.keys(messages).map((key: any) =>
                    setError(key, {
                        message: messages[key],
                    }),
                );
              } else {
                  setError('name', {
                      message: response.data?.error,
                  });
              }
              
              break;
          
            default:
              break;
          }
          reject(err)
        });
      resolve(true);
    });
  }

  return (
    <Dialog open={open} onClose={() => !loading && setOpen(false)} disableEscapeKeyDown>
      <StyledWorkingLogForm onSubmit={handleSubmit(handleUpdateWorkingLog)}>
        <div className="formTitle">作業記録</div>

        <div className="formFieldRow">
          <p>工程</p>
          <Controller 
            name="plan_id"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete 
                disablePortal
                options={plans.map((item) => ({
                  value: item?.id || "",
                  label: item?.name || "",
                }))}
                {...register('plan_id', updateWorkingLogValidation(t).plan_id())}
                onChange={(event, newValue) => {
                  setValue('plan_id', newValue);
                }}
                classes={{
                  root: 'autocomplete-select-style',
                }}
                renderInput={(params) => (
                  <TextField {...params} label="" />
                )}
                noOptionsText="該当なし"
                value={value}
              />
            )}
          />
          {errors.plan_id && (
            <span className="error">{errors.plan_id.message}</span>
          )}
        </div>

        <div className="formFieldRow">
          <p>作業名</p>
          <input 
            type="text" 
            autoComplete="off" 
            {...register('name', updateWorkingLogValidation(t).name())}
            className="textfield"
          />
          {errors.name && (
            <span className="error">{errors.name.message}</span>
          )}
        </div>

        <div className="formFieldRow">
          <p>作業日</p>
          <div className="datepicker-wrapper">
            <Controller 
              name="worked_at"
              control={control}
              render={({ field }) => (
                <DatePicker 
                  selected={field.value}
                  {...register('worked_at', updateWorkingLogValidation(t).worked_at())}
                  {...field}
                  onChange={(date) => {
                    field?.onChange(date);
                    // setWorkDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  autoComplete="off"
                  locale="ja"
                />
              )}
            />
            <img src={calendarIcon} alt="" className="calendar-datepicker-icon" />
          </div>
          {errors.worked_at && (
            <span className="error">{errors.worked_at?.message}</span>
          )}
        </div>

        <div className="formFieldRow">
          <p>担当</p>
          <Controller 
            name="worker"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete 
                disablePortal
                options={workers.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                {...register('worker', updateWorkingLogValidation(t).worker())}
                onChange={(event, newValue) => {
                  setValue('worker', newValue);
                }}
                classes={{ root: 'autocomplete-select-style' }}
                renderInput={(params) => <TextField {...params} label="" />}
                noOptionsText="該当なし"
                value={value}
              />
            )}
          />
          {errors.worker && (
            <span className="error">{errors.worker.message}</span>
          )}
        </div>

        <div className="formFieldRow">
          <p>備考</p>
          <textarea 
            autoComplete="off" 
            className="textarea" 
            {...register('note', updateWorkingLogValidation(t).note())}
          ></textarea>
          {errors.note && (
            <span className="error">{errors.note.message}</span>
          )}
        </div>

        <div className="formFieldRow">
          <p>画像</p>
          <Grid container>
            <Grid item xs={9}>
              <div className="imagesContainer">
                {
                  uploadedFile &&
                  uploadedFile.length > 0 && 
                  uploadedFile
                    .filter((item) => item._destroy !== true)
                    .map((item, idx) => (
                      <div className="imageWrapper" key={`image-preview-${idx}`}>
                        <img src={item.image_path} alt="working log image" />
                        <img 
                          src={deleteIcon} 
                          alt="delete icon" 
                          className="deleteIcon" 
                          onClick={() => handleRemoveUploadedImage(item.signed_id)}
                        />
                      </div>
                    ))
                }
                {
                  newImageFiles && 
                  newImageFiles.length > 0 &&
                  newImageFiles.map((item, idx) => (
                    <div className="imageWrapper" key={`image-preview-${idx}`}>
                      <img src={getFilePreview(item)} alt="working log image" />
                      <img 
                        src={deleteIcon} 
                        alt="delete icon" 
                        className="deleteIcon" 
                        onClick={() => handleRemoveNewImageFile(idx)}
                      />
                    </div>
                  ))
                }
              </div>
            </Grid>
            <Grid item xs={3}>
              <label htmlFor="file-upload-input" className="file-uploader-wrapper">
                <Input 
                  accept="image/*"
                  id="file-upload-input"
                  multiple={false}
                  type="file"
                  // hidden
                  onChange={(e) => handleUploadFile(e.target.files)}
                />
                <div className="addFileBtn">追加</div>
              </label>
            </Grid>
          </Grid>
        </div>

        <div className="formButtonGroup">
          <button 
            type="button" 
            className="cancelBtn"
            disabled={loading}
            onClick={() => {
               setOpen(false);
            }}
          >
            戻る
          </button>
          <button className="registerBtn" type="submit" disabled={loading}>
            {
              loading ? <CircularProgress color="inherit" size={25} /> : '登録'
            }
          </button>
        </div>
      </StyledWorkingLogForm>
    </Dialog>
  )
}

export default UpdateWorkingLogDialog;