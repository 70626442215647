import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Add } from '@mui/icons-material';
import { useModal } from 'react-hooks-use-modal';
import { DefaultButton, DeleteButton } from '../../components/button';
import ManifestPopup from './manifestPopup';
import CreateManifestModal from './create';
import userService from 'services/userService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import IndustriesWatesService from 'services/industriesWatesService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertClientCompaniesToDropdownOptions } from 'utils/helper/clientCompanyHelper';
import manifestService from 'services/manifestService';
import { OrderWasteType } from 'utils/enums';
import EditManifestModal from './edit';
import projectsService from 'services/projectsService';

const manifestsListTitles = [
    <>
        <p>整理</p>
        <p>番号</p>
    </>,
    '日付',
    'マニフェストNo',
    '産廃物種類',
    '数量',
    '単位',
    '体積',
    '収集運搬',
    // '処分先',
    '状態',
    '処分業者',
    '最終処分場',
    '最終処分日',
    '操作',
];

const ProjectManiFestsTabs = () => {
    const [openManifestLog, setOpenManifestLog] = useState(false);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [manifestToDelete, setManifestToDelete] = useState<any>(null);
    const [manifestToEdit, setManifestToEdit] = useState<any>(null);
    const [manifests, setManifests] = useState<any[]>([]);
    const [selectedManifest, setSelectedManifest] = useState<any>(null);
    const [currentProject, setCurrentProject] = useState<any>(null);

    // const [orderItemOptions, setOrderItemOptions] = useState<any[]>([]);
    const [orderItemOptions, setOrderItemOptions] = useState<any[]>([]); 
    const [wasteTypes, setWasteTypes] = useState<any[]>([]);
    const [companyOptions, setCompanyOptions] = useState<any[]>([]);
    const { id } = useParams();
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal('root', {
      preventScroll: true,
      closeOnOverlayClick: true
    });

    const fetchListOfWasteTypes = async () => {
      try {
        const data = await userService.getListConfiguration();
        setWasteTypes(convertUserConfigToDropdownOptions(data.configurations.order_item.waste_type))
      } catch (error) {
        // empty
      }
    }

    const fetchProjectDetail = async () => {
      if (!id) return;
      try {
        const data = await projectsService.getProject(+id);
        if (data) {
          // console.log('data project', data);
          setCurrentProject(data.project);
        }
      } catch (error) {
        // empty
      }
    }

    const fetchListOfOrderItems = async () => {
      try {
        const data: any = await IndustriesWatesService.getListOrderItem({
          page: 1,
          per_page: 0,
          project_id: id,
        });
        if (data) {
          setOrderItemOptions(convertObjectToDropdownOptions(data.order_items));
        }
      } catch (error) {
        // empty
      }
    }

    const fetchOrderItemOptions = async () => {
      try {
        const data: any = await IndustriesWatesService.getListOrderItem({
          page: 1,
          per_page: 0,
          project_id: id,
        });
        if (data && data.order_items) {
          const noManifestOrderItems = data.order_items.filter((item) => !item.has_manifest);
          setOrderItemOptions(convertObjectToDropdownOptions(noManifestOrderItems))
        }
      } catch (error) {
        
      }
    }

    const fetchListOfCompanies = async () => {
      try {
        const response = await CooperativeCompanyService.getListCooperativeCompanies();
        setCompanyOptions(convertClientCompaniesToDropdownOptions(response.cooperative_companies));
      } catch (error) {
        // empty
      }
    }

    const fetchListOfManifests = async () => {
      try {
        const data = await manifestService.getManifestList({
          page: 1,
          per_page: 0,
          project_id: id
        });
        if (data) {
          setManifests(data.manifests);
        }
      } catch (error) {
        // empty
      }
    }

    const handleDeleteManifest = async () => {
      if (!manifestToDelete) return;

      try {
        const response = await manifestService.deleteManifest(manifestToDelete.id, id);
        if (response) {
          fetchListOfManifests();
          fetchOrderItemOptions();
          closeDelete();
        }
      } catch (error) {
        // empty
      }
    }

    useEffect(() => {
      // fetchListOfOrderItems();
      fetchListOfWasteTypes();
      fetchListOfCompanies();
      fetchListOfManifests();
      fetchOrderItemOptions();
    }, [])

    useEffect(() => {
      if (id) {
        fetchProjectDetail();
      }
    }, [id])

    useEffect(() => {
      if (!isOpenDelete) {
        setManifestToDelete(null);
      }
    }, [isOpenDelete])

    useEffect(() => {
      if (!openEdit) {
        setManifestToEdit(null);
      }
    }, [openEdit])

    useEffect(() => {
      if (!openManifestLog) {
        setSelectedManifest(null);
      }
    }, [openManifestLog])

    return (
        <>
            {
              openManifestLog && selectedManifest && currentProject && (
                <ManifestPopup 
                  isOpen={openManifestLog} 
                  setIsOpen={setOpenManifestLog} 
                  selectedManifest={selectedManifest}
                  currentProject={currentProject}
                />
              )
            }

            {
              openCreate && (
                <CreateManifestModal 
                  open={openCreate}
                  setOpen={setOpenCreate}
                  orderItemOptions={orderItemOptions}
                  wasteTypes={wasteTypes}
                  companyOptions={companyOptions}
                  project_id={id}
                  onFinishCreate={() => {
                    setOpenCreate(false);
                    fetchListOfManifests();
                    fetchOrderItemOptions();
                  }}
                />
              )
            }

            {
              openEdit && (
                <EditManifestModal 
                  open={openEdit}
                  setOpen={setOpenEdit}
                  orderItemOptions={orderItemOptions}
                  wasteTypes={wasteTypes}
                  companyOptions={companyOptions}
                  project_id={id}
                  manifestId={manifestToEdit.id}
                  onFinishEdit={() => {
                    setOpenEdit(false);
                    fetchListOfManifests();
                    fetchOrderItemOptions();
                  }}
                />
              )
            }

            <ModalDelete>
              <div
                  className="modal-create-user d-flex flex-column"
                  style={{ height: '152px' }}
              >
                  <div className="modal-create-user-title d-flex flex-row">
                      本当に削除しますか?
                  </div>
                  <div
                      className="modal-create-user-buttons d-flex flex-row"
                      style={{ marginTop: '50px' }}
                  >
                      <button
                          className="modal-create-user-button1 d-flex flex-row"
                          onClick={() => {
                            closeDelete();
                            setManifestToDelete(null);
                          }}
                      >
                          キャンセル
                      </button>
                      <button
                          className="modal-create-user-button2 d-flex flex-row"
                          onClick={handleDeleteManifest}
                      >
                          はい
                      </button>
                  </div>
              </div>
            </ModalDelete>
            
            <Box marginTop="24px">
                <TableContainer
                    table-layout={'auto'}
                    sx={{ padding: '0px', boxShadow: 'none' }}
                    component={Paper}
                >
                    <Table
                        sx={{
                            width: '100%',
                            minWidth: 1900,
                            'th, td': {
                                fontFamily: 'Zen Kaku Gothic Antique',
                            },
                        }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    bgcolor: '#C7DCF5',
                                    th: {
                                        color: '#1C1E21',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        padding: '6px 16px'
                                    },
                                }}
                            >
                                {manifestsListTitles?.map((title, index) => (
                                    <TableCell
                                        sx={{
                                            width: 'fit-content',
                                            // padding: '38px 10px',
                                        }}
                                        key={index}
                                        align="center"
                                    >
                                        {title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                td: {
                                    textAlign: 'center',
                                    fontWeight: '400',
                                    borderRight: '1px solid #F5F5F5',
                                    borderBottom:
                                        '1px solid #F5F5F5 !important',
                                    padding: '8px 10px',
                                },
                            }}
                        >
                            {manifests.length > 0 && manifests.map((row) => (
                                <TableRow
                                    sx={{
                                        'td:first-child': {
                                            borderLeft: '1px solid #F5F5F5',
                                        },
                                    }}
                                    key={row.id}
                                >
                                    <TableCell>{row?.index}</TableCell>
                                    <TableCell>{row?.date_at}</TableCell>
                                    <TableCell>{row?.manifest_no}</TableCell>
                                    <TableCell>
                                        {row?.order_item?.waste_type ? OrderWasteType[row.order_item.waste_type] : ""}
                                    </TableCell>
                                    <TableCell>{row?.order_item?.amount}</TableCell>
                                    <TableCell>{row?.order_item?.unit}</TableCell>
                                    <TableCell>{row?.order_item?.volume}</TableCell>
                                    <TableCell>
                                        {row?.order_item?.collect_company?.name || ""}
                                    </TableCell>
                                    {/* <TableCell>{row?.order_item?.disposal_company?.name || ""}</TableCell> */}
                                    <TableCell>
                                        {row?.step}
                                    </TableCell>
                                    <TableCell>
                                        {row?.order_item?.disposal_company?.name || ""}
                                    </TableCell>
                                    <TableCell>
                                        {row?.order_item?.final_disposal_company?.name || ""}
                                    </TableCell>
                                    <TableCell>
                                        {row?.final_disposal_at}
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <DefaultButton
                                                text="編集"
                                                onClick={() => {
                                                  setOpenEdit(true);
                                                  setManifestToEdit(row);
                                                }}
                                            />
                                            <DefaultButton
                                                text="マニ状況"
                                                onClick={() => {
                                                    setSelectedManifest(row);
                                                    setOpenManifestLog(true);
                                                }}
                                            />
                                            <DeleteButton
                                                onClick={() => {
                                                  openDelete();
                                                  setManifestToDelete(row);
                                                }}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{
                        mt: '16px',
                        fontWeight: '500',
                        padding: '6px 18px',
                        borderColor: 'rgba(28, 30, 33, 0.4)',
                        borderRadius: '6px',
                        color: '#1C1E21',
                    }}
                    variant="outlined"
                    onClick={() => setOpenCreate(true)}
                >
                    <Add /> 行追加
                </Button>
            </Box>
        </>
    );
};
export default ProjectManiFestsTabs;
