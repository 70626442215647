import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import _ from 'lodash'

import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { validate } from './validation'
import { Main, ShoeiInputText } from './styles'


const ModalCreateNew = ({ onClose, submitForm, item, showDataEstimateItem }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      estimate_id: 0,
      name: '',
      summary: '',
      amount: null,
      unit: null,
      unit_price: null,
      price: 0,
      note: null,
      is_parent: false,
      item: item
    },
    validate,
    onSubmit: values => {
      submitForm(values);
    },
  });

  return (
    <Main className='modal'>
      <form onSubmit={formik.handleSubmit} >
        <InputLabel className='title'>
          見積新規作成
        </InputLabel>

        <div className='contronler-box-input-label'>
          <InputLabel className='label-input'> 名称 </InputLabel>
          <ShoeiInputText
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name && (
            <span className="error">{formik.errors.name}</span>
          )}
        </div>

        <div className='contronler-box-input-label'>
          <InputLabel className='label-input'> 摘要 </InputLabel>
          <ShoeiInputText
            fullWidth
            name="summary"
            value={formik.values.summary}
            onChange={formik.handleChange}
            error={formik.touched.summary && Boolean(formik.errors.summary)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.summary && formik.errors.summary && (
            <span className="error">{formik.errors.summary}</span>
          )}
        </div>

        {
          !showDataEstimateItem && <FormControlLabel
            label="大項目"
            control={
              <Checkbox
                className={formik.values.is_parent ? 'check-parent' : ''}
                checked={formik.values.is_parent}
                onChange={() => {
                  formik.setFieldValue("is_parent", !formik.values.is_parent)
                }}
              />
            }
          />
        }

        {
          !formik.values.is_parent && (
            <div className={`${!showDataEstimateItem ? "is-parent mb-2" : ''}`}>
              <div className='d-flex gap-input'>
                <div className='contronler-box-input-label'>
                  <InputLabel className='label-input'> 数量 </InputLabel>
                  <ShoeiInputText
                    fullWidth
                    type='number'
                    name="amount"
                    value={formik.values.amount}
                    onChange={e => {
                      const { unit_price = 0 } = formik.values;
                      const val = (unit_price || 0) * Number(e.target.value || 0);

                      formik.setFieldValue('price', val)
                      formik.handleChange(e);
                    }}
                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <span className="error">{formik.errors.amount}</span>
                  )}
                </div>

                <div className='contronler-box-input-label'>
                  <InputLabel className='label-input'> 単位 </InputLabel>
                  <ShoeiInputText
                    fullWidth
                    name="unit"
                    value={formik.values.unit}
                    onChange={formik.handleChange}
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.unit && formik.errors.unit && (
                    <span className="error">{formik.errors.unit}</span>
                  )}
                </div>
              </div>

              <div className='d-flex gap-input'>
                <div className='contronler-box-input-label'>
                  <InputLabel className='label-input'> 単価 </InputLabel>
                  <ShoeiInputText
                    fullWidth
                    type='number'
                    name="unit_price"
                    value={formik.values.unit_price}
                    onChange={e => {
                      const { amount = 0 } = formik.values;
                      const val = (amount || 0) * Number(e.target.value || 0);

                      formik.setFieldValue('price', val)
                      formik.handleChange(e);
                    }}
                    error={formik.touched.unit_price && Boolean(formik.errors.unit_price)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.unit_price && formik.errors.unit_price && (
                    <span className="error">{formik.errors.unit_price}</span>
                  )}
                </div>

                <div className='contronler-box-input-label'>
                  <InputLabel className='label-input'> 金額 </InputLabel>
                  <ShoeiInputText
                    fullWidth
                    disabled
                    type='number'
                    name="price"
                    value={formik.values.price}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.price && formik.errors.price && (
                    <span className="error">{formik.errors.price}</span>
                  )}
                </div>
              </div>
            </div>
          )
        }

        <div className='contronler-box-input-label'>
          <InputLabel className='label-input'> 備考 </InputLabel>
          <ShoeiInputText
            fullWidth
            multiline
            rows={3}
            name="note"
            value={formik.values.note}
            onChange={formik.handleChange}
            error={formik.touched.note && Boolean(formik.errors.note)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.note && formik.errors.note && (
            <span className="error">{formik.errors.note}</span>
          )}
        </div>

        <div className="group-buttons d-flex flex-row gap-input">
          <Button
            className="bnt bnt-line d-flex flex-row"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button
            className="bnt bnt-submit d-flex flex-row"
            type="submit"
          >
            登録
          </Button>
        </div>
      </form>
    </Main>
  )
}



export default ModalCreateNew