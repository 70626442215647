/* eslint-disable import/first */
import { useState, Dispatch, SetStateAction, FC } from "react";
import "./createWorkingLog.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertErrorMessagesToObject } from "utils/validation/validatorHelper";
import isEmpty from "lodash/isEmpty";
import workingLogService from "services/workingLogService";
import createWorkingLogValidation from "utils/validation/registers/workingLogs/createWorkingLogValidation";
import { Grid, Autocomplete, TextField, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
// import moment from 'moment';
import calendarIcon from "assets/icons/calendar.png";
import deleteIcon from "assets/icons/deleteIcon.svg";
import StyledWorkingLogForm from "./style";
import { getFilePreview } from "utils/helper/file";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  plans: any[];
  workers: any[];
  onFinishCreate: () => void;
  project_id: any;
  currentPlan: any;
}

const Input = styled("input")({
  display: "none !important",
});

const CreateWorkingLogDialog: FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    onFinishCreate,
    project_id,
    plans,
    workers,
    currentPlan,
  } = props;
  const { userData } = useSelector((state: RootState) => state.users);

  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const [fileUpload, setFileUpload] = useState<any[]>([]);

  const handleUploadFile = (files) => {
    let current = [...fileUpload];
    if (files.length > 0) {
      if (current) {
        current.push(files[0]);
      } else {
        current = [files[0]];
      }
      setFileUpload(current);
    }
  };

  const removeImage = (idx) => {
    let current = [...fileUpload];
    current = current.filter((item, index) => index !== idx);
    setFileUpload(current);
  };

  const onCreate = (data) => {
    let formData = new FormData();
    formData.append("project_id", project_id);
    formData.append("plan_id", data.plan_id.value);
    formData.append("worker_id", data.worker.value);
    formData.append("name", data.name);
    formData.append("note", data.note);
    formData.append("worked_at", data.worked_at);
    for (var x = 0; x < fileUpload.length; x++) {
      formData.append("working_log_images[]", fileUpload[x]!);
    }

    return new Promise(async (resolve, reject) => {
      await workingLogService
        .createWorkingLog(formData)
        .then((data) => {
          onFinishCreate();
        })
        .catch((err) => {
          const response = err?.response;
          if (!response || typeof response === "undefined") {
            window.location.href = "/login";
            reject(err);
          }
          switch (response.status) {
            case 403:
              const messages = convertErrorMessagesToObject(
                {
                  name: "name",
                  worked_at: "start_at",
                  plan_id: "assigned_worker_id",
                  note: "note",
                },
                response.data?.error
              );

              if (!isEmpty(messages)) {
                Object.keys(messages).map((key: any) =>
                  setError(key, {
                    message: messages[key],
                  })
                );
              } else {
                setError("name", {
                  message: response.data?.error,
                });
              }
              break;
            default:
          }
          reject(err);
        });
      resolve(true);
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
      <StyledWorkingLogForm onSubmit={handleSubmit(onCreate)}>
        <div className="formTitle">作業記録</div>
        <div className="formFieldRow">
          <p>工程</p>
          <Controller
            name="plan_id"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={plans.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                {...register(
                  "plan_id",
                  createWorkingLogValidation(t).plan_id()
                )}
                onChange={(event, newValue) => {
                  setValue("plan_id", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                renderInput={(params) => <TextField {...params} label="" />}
                noOptionsText="該当なし"
                value={currentPlan?.name || value}
              />
            )}
          />
          {errors.plan_id && (
            <span className="error">{errors.plan_id.message}</span>
          )}
        </div>
        <div className="formFieldRow">
          <p>作業名</p>
          <input
            autoComplete="off"
            type="name"
            {...register("name", createWorkingLogValidation(t).name())}
            className="textfield"
          />
          {errors.name && <span className="error">{errors.name.message}</span>}
        </div>
        <div className="formFieldRow">
          <p>作業日</p>
          <div className="datepicker-wrapper">
            <Controller
              name="worked_at"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  {...register(
                    "worked_at",
                    createWorkingLogValidation(t).worked_at()
                  )}
                  {...field}
                  onChange={(date) => {
                    field?.onChange(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  autoComplete="off"
                  locale="ja"
                />
              )}
            />
            <img className="calendar-datepicker-icon" src={calendarIcon}></img>
          </div>
          {errors.worked_at && (
            <span className="error">{errors.worked_at?.message}</span>
          )}
        </div>
        <div className="formFieldRow">
          <p>担当</p>
          <Controller
            name="worker"
            control={control}
            render={({ field: { value } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={workers.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                {...register("worker", createWorkingLogValidation(t).worker())}
                onChange={(event, newValue) => {
                  setValue("worker", newValue);
                }}
                classes={{
                  root: "autocomplete-select-style",
                }}
                renderInput={(params) => <TextField {...params} label="" />}
                noOptionsText="該当なし"
                value={(userData?.worker as any)?.name || value}
              />
            )}
          />
          {errors.worker && (
            <span className="error">{errors.worker.message}</span>
          )}
        </div>
        <div className="formFieldRow">
          <p>備考</p>
          <textarea
            autoComplete="off"
            className="textarea"
            {...register("note", createWorkingLogValidation(t).note())}
          ></textarea>
          {errors.note && <span className="error">{errors.note.message}</span>}
        </div>
        <div className="formFieldRow">
          <p>画像</p>
          <Grid container>
            <Grid item xs={9}>
              <div className="imagesContainer">
                {fileUpload &&
                  fileUpload.map((item, idx) => {
                    return (
                      <div
                        className="imageWrapper"
                        key={`image-preview-${idx}`}
                      >
                        <img src={getFilePreview(item)} />
                        <img
                          className="deleteIcon"
                          src={deleteIcon}
                          onClick={() => removeImage(idx)}
                        />
                      </div>
                    );
                  })}
              </div>
            </Grid>
            <Grid item xs={3}>
              <label
                htmlFor="contained-button-file"
                className="file-uploader-wrapper"
              >
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple={false}
                  type="file"
                  onChange={(e) => handleUploadFile(e.target.files)}
                />
                <div className="addFileBtn">追加</div>
              </label>
            </Grid>
          </Grid>
        </div>

        <div className="formButtonGroup">
          <button
            type="button"
            className="cancelBtn"
            onClick={() => {
              setOpen(false);
              setFileUpload([]);
            }}
          >
            戻る
          </button>
          <button className="registerBtn" type="submit">
            登録
          </button>
        </div>
      </StyledWorkingLogForm>
    </Dialog>
  );
};

export default CreateWorkingLogDialog;
