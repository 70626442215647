import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    scheduleView: "",
    scheduleCurrentDate: null,
}

const scheduleSlice = createSlice({
    name: "schedule",
    initialState,
    reducers: {
        updateScheduleView(state, action) {
            state.scheduleView = action.payload;
        },
        updateScheduleCurrentDate(state, action) {
            state.scheduleCurrentDate = action.payload;
        }
    }
});

export const { updateScheduleView, updateScheduleCurrentDate } = scheduleSlice.actions;
export default scheduleSlice.reducer;