import {
    FC,
    memo,
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import { isArray, isEmpty } from 'lodash';
import {
    Widget,
    renderCustomComponent,
    toggleWidget,
    dropMessages,
} from 'react-chat-widget';
import moment from 'moment';
import 'react-chat-widget/lib/styles.css';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Main } from './style';
import useChatBox, { Props, ReceivedProps } from './hook';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import carretDown from '../../../assets/icons/carret_down.svg';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
} from '@chatscope/chat-ui-kit-react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const WhoCanJoin = ({ chat }) => {
    const [whoCanJoinToggle, setWhoCanJoinToggle] = useState(false);
    return (
        <Box marginTop="25px">
            {whoCanJoinToggle ? (
                <Box
                    sx={{
                        border: '1px solid #E3E5E8',
                        padding: '13px',
                        borderRadius: '10px',
                        position: 'relative',
                        height: '100%',
                        overflow: 'auto',
                        maxHeight: '100px',
                        display: 'flex',
                    }}
                >
                    <Typography
                        color="#215493"
                        fontWeight="500"
                        width="90%"
                        fontSize={16}
                    >
                        {chat?.who_can_join}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#215493',
                            width: '30px',
                            height: '30px',
                            borderRadius: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'sticky',
                            right: '10px',
                            top: '0px',
                        }}
                    >
                        <img
                            onClick={() => setWhoCanJoinToggle(false)}
                            style={{ transform: 'rotate(180deg)' }}
                            src={carretDown}
                            alt="carretDown"
                        />
                    </Box>
                </Box>
            ) : (
                <Button
                    onClick={() => setWhoCanJoinToggle(true)}
                    sx={{
                        backgroundColor: '#215493',
                        color: '#fff',
                        width: '160px',
                    }}
                >
                    参加者を表示する
                </Button>
            )}
        </Box>
    );
};
const ChatBoxLayout: FC<Props> = ({
    workerId,
    listMessage,
    onSubmit,
    handleFileChange,
    listImageMessage,
    refInput,
    removeImage,
    chatRoomId,
    message,
    setMessage,
    chat,
    whoCanJoinToggle,
    setWhoCanJoinToggle,
    setListMessage,
    handleReceived,
}) => {
    // const [messageInputValue, setMessageInputValue] = useState('');

    // const submit = () => {
    //     onSubmit(message);
    //     setMessage('');
    // };
    const navigate = useNavigate();
    const messageInputRef: any = useRef(null);
    // const handleReceived = (response) => {
    //     const { data } = response;
    //     setListMessage((preState) => [
    //         ...preState,
    //         { ...data, images: data?.image_urls },
    //     ]);
    // };
    useEffect(() => {
        const element = document.getElementsByClassName('cs-button--send');
        element[0]?.removeAttribute('disabled');
        // element[0]?.addEventListener('click', () => onSubmit());
    }, []);

    const handleButtonClick = () => {
        const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
        messageInputRef?.current?.inputRef?.current.dispatchEvent(
            enterKeyEvent,
        );
    };

    const listMessageItem = useMemo(() => {
        return listMessage?.map((message) => (
            <>
                <Message
                    key={message.id}
                    model={{
                        message: message.body,
                        sender: message?.sender?.name,
                        sentTime: message?.publish_at,
                        direction: message?.is_owner ? 'incoming' : 'outgoing',
                        position: 'first',
                        type: 'custom',
                    }}
                >
                    <Message.CustomContent>
                        <div className="message">
                            <p>{message?.body || ''}</p>

                            {message?.images &&
                                Object.values(message?.images).map(
                                    (img: any, id) => (
                                        <img
                                            src={img?.image_path}
                                            style={{
                                                marginTop: '5px',
                                                width: '324px',
                                            }}
                                            key={id}
                                        />
                                    ),
                                )}
                        </div>
                    </Message.CustomContent>
                </Message>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: message?.is_owner ? 'start' : 'end',
                    }}
                    className="message-footer"
                >
                    <p>{message?.sender?.name || ''}</p>
                    <p>
                        {message.publish_at &&
                            moment(message.publish_at)
                                .zone('+09:00')
                                .format('YYYY/MM/DD HH:mm:ss')}
                    </p>
                </div>
            </>
        ));
    }, [listMessage]);

    return (
        <Main className="chat-box" style={{ position: 'relative' }}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '32px',
                    }}
                >
                    <Typography fontWeight="bold" fontSize={20}>
                        {chat?.project?.name}
                    </Typography>
                    <Button
                        onClick={() =>
                            navigate(`/sp/project/${chat?.project?.id}/chats`)
                        }
                        sx={{
                            color: '#215493',
                            width: '100px',
                            fontWeight: 600,
                        }}
                        variant="outlined"
                    >
                        戻る
                    </Button>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F3F3F3',
                        padding: '14px',
                        borderRadius: '10px',
                    }}
                >
                    <Typography fontWeight="500" fontSize={16}>
                        {chat?.title}
                    </Typography>
                </Box>
                <WhoCanJoin chat={chat}/>
                {/* <Box marginTop="25px">
                    {whoCanJoinToggle ? (
                        <Box
                            sx={{
                                border: '1px solid #E3E5E8',
                                padding: '13px',
                                borderRadius: '10px',
                                position: 'relative',
                                height: '100%',
                                overflow: 'auto',
                                maxHeight: '100px',
                                display: 'flex',
                            }}
                        >
                            <Typography
                                color="#215493"
                                fontWeight="500"
                                width="90%"
                                fontSize={16}
                            >
                                {chat?.who_can_join}
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#215493',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'sticky',
                                    right: '10px',
                                    top: '0px',
                                }}
                            >
                                <img
                                    onClick={() => setWhoCanJoinToggle(false)}
                                    style={{ transform: 'rotate(180deg)' }}
                                    src={carretDown}
                                    alt="carretDown"
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Button
                            onClick={() => setWhoCanJoinToggle(true)}
                            sx={{
                                backgroundColor: '#215493',
                                color: '#fff',
                                width: '160px',
                            }}
                        >
                            参加者を表示する
                        </Button>
                    )}
                </Box> */}
            </Box>
            <ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            >
                <div style={{ position: 'relative', height: '60%' }}>
                    <MainContainer>
                        <ChatContainer>
                            <MessageList>{listMessageItem}</MessageList>
                            {/* <MessageInput
                                onKeyPress={(e) => {
                                    e.code === 'Enter' && onSubmit(message);
                                }}
                                attachButton={false}
                                placeholder="メッセージを入力..."
                                value={message}
                                ref={messageInputRef}
                                onChange={(val) => setMessage(val)}
                                onSend={() => onSubmit}
                            /> */}
                        </ChatContainer>
                    </MainContainer>
                    <div className="action-container">
                        <label
                            className="upload-image-chat"
                            htmlFor="fileInput"
                        >
                            <input
                                onChange={(e) => handleFileChange(e)}
                                id="fileInput"
                                name="file"
                                type="File"
                                multiple
                                ref={refInput}
                                accept="image/*"
                                style={{ display: 'none' }}
                            ></input>
                            <BiImageAdd size={20} />
                        </label>
                        <input
                            onKeyPress={(e) => {
                                e.code === 'Enter' && onSubmit(message);
                            }}
                            className="input-custom-chat"
                            type="text"
                            placeholder="メッセージを入力..."
                            autoFocus
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <button
                            style={{
                                bottom: '16px',
                                zIndex: 100000,
                                right: '16px',
                            }}
                            type="button"
                            onClick={() => onSubmit(message)}
                        >
                            <BiSend size={25} />
                        </button>
                        <div className="preview-img-upload">
                            {listImageMessage?.length > 0 &&
                                listImageMessage.map((item, id) => {
                                    return (
                                        <div className="img-upl-ct">
                                            <img
                                                src={URL.createObjectURL(item)}
                                                key={id}
                                            />
                                            <TiCancel
                                                onClick={() => removeImage(id)}
                                                size={15}
                                                className="delete-image-upl"
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </ActionCableConsumer>
        </Main>
    );
};

const ChatBox: FC<ReceivedProps> = (props) => (
    <ChatBoxLayout {...useChatBox(props)} />
);

export default memo(ChatBox);
