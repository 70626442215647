import { FC } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@material-ui/core/Chip";
import Select from "@mui/material/Select";

import DatePickerField from "../FormField/DatePicker";
import DateTimePickerField from "../FormField/DateTimePicker";
import useCreateScheduleDialog, {
    Props,
    ReceivedProps,
    SCHEDULER_COLORS,
} from "./hook";
import CreateScheduleWrapper from "./style";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isAllowAction, isUserGuest } from "utils/helper/workerHelper";
import { MenuItem } from "@mui/material";
import UpdateRepeatScheduleDialog from "../UpdateRepeatScheduleDialog";
import CircularProgress from "@mui/material/CircularProgress";
import SmallDatePickerField from "../FormField/SmallDatePicker";
import NumberInputField from "../FormField/NumberInput";
import { DuplicateOptionsEnum, DuplicateTypeEnum } from "utils/enums";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const CreateScheduleDialogLayout: FC<Props> = ({
    open,
    formik,
    editingId,
    workerOptions,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    setOpen,
    isAllow,
    isAllowShowNotPublic,
    isExecutive,
    isScheduleMaker,
    scheduleListMeetingRoom,
    isErrorMeetingRoom,
    setIsErrorMettingRoom,
    errorTimeMessage,
    setErrorTimeMessage,
    setIsShowDefaultDate,
    duplicateTypes,
    setOpenDeleteRepeatScheduleDialog,
    isRepeatSchedule,
    openUpdateRepeatScheduleDialog,
    setOpenUpdateRepeatScheduleDialog,
    handleUpdateForRepeatSchedule,
    changedDuplicateType,
    setChangedDuplicateType,
    submitLoading,
    duplicateEndDateMax,
    setDuplicateEndDateMax,
    duplicateMaxNum,
    onChangeDuplicateEndDate,
}) => {
    const navigate = useNavigate();
    const isAllowShowCheckbox = isAllowShowNotPublic();
    const isNotDisableEdit = isExecutive();
    const { userData } = useSelector((state: RootState) => state.users);
    const isAllowEdit = isAllowAction(userData) || userData?.worker?.id === formik.values?.created_by?.id || userData?.worker?.id === formik.values?.worker?.id;
    const checkUserGuest = isUserGuest(userData);
    const cloneWorkerOptions = [...workerOptions];
    cloneWorkerOptions.unshift({id: 0, name: "全員"})
    return (
        <>
            <BootstrapDialog
                className="customBootstrapDialog scheduleFormDialog"
                onClose={() => {
                    setOpen(false); navigate(`/sp/calendar`, {
                        state: {
                            editingId: null,
                        },
                    });
                    setIsShowDefaultDate(true);
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <CreateScheduleWrapper>
                    <DialogContent>
                        <div
                            className="description"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            {editingId
                                ? "スケジュール編集"
                                : "スケジュール新規作成"}
                        </div>
                    </DialogContent>
                    <Dialog open={isErrorMeetingRoom} onClose={() => setIsErrorMettingRoom(false)}>
                        <DialogContent>
                            <span className="text-[#d32f2f] text-[0.75rem]">※同時間帯で既に予約が入っています</span>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={errorTimeMessage !== "" ? true : false} onClose={() => setErrorTimeMessage("")}>
                        <DialogContent>
                            <span className="text-[#d32f2f] text-[0.75rem]">{errorTimeMessage}</span>
                        </DialogContent>
                    </Dialog>
                    <form onSubmit={formik.handleSubmit} className="filterBox">
                        <div className="filterBoxTop">
                            <div className="filterBoxCol">
                                <span className="fieldLabel">担当社員</span>
                                <div className="wrapSelectField">
                                    <Autocomplete
                                        value={formik.values.worker_id}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "worker_id",
                                                newValue
                                            );
                                        }}
                                        options={workerOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={
                                                    formik.touched.worker_id &&
                                                    !!formik.errors.worker_id
                                                }
                                                helperText={
                                                    formik.touched.worker_id &&
                                                    formik.errors.worker_id
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">タイトル</span>
                                <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        name="title"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                        error={
                                            formik.touched.title &&
                                            !!formik.errors.title
                                        }
                                        helperText={
                                            formik.touched.title &&
                                            formik.errors.title
                                        }
                                    />
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">内容</span>
                                <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        name="content"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={formik.handleChange}
                                        value={formik.values.content}
                                        multiline
                                    />
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">
                                    <FormControl fullWidth>
                                        <RadioGroup
                                            row
                                            name="is_all_day"
                                            onChange={() => {
                                                formik.setFieldValue(
                                                    "is_all_day",
                                                    false
                                                );
                                            }}
                                        >
                                            <FormControlLabel
                                                value={false}
                                                control={
                                                    <Radio
                                                        checked={
                                                            !formik.values
                                                                .is_all_day
                                                        }
                                                    />
                                                }
                                                label="日時（期間）"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </span>
                                <div
                                    className={classNames(
                                        "inlineField",
                                        !formik.values.is_all_day
                                            ? ""
                                            : "hiddenValue"
                                    )}
                                >
                                    <div className="wrapTextField">
                                        <DateTimePickerField
                                            name="start_date"
                                            placeholder=""
                                            locale="ja"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="divider">~</div>
                                    <div className="wrapTextField">
                                        <DateTimePickerField
                                            name="end_date"
                                            placeholder=""
                                            locale="ja"
                                            formik={formik}
                                            onChangeEndTime={(value) =>  formik.setFieldValue("end_date", new Date(value))}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">
                                    <FormControl fullWidth>
                                        <RadioGroup
                                            row
                                            name="is_all_day"
                                            onChange={() => {
                                                formik.setFieldValue(
                                                    "is_all_day",
                                                    true
                                                );
                                            }}
                                        >
                                            <FormControlLabel
                                                value={false}
                                                control={
                                                    <Radio
                                                        checked={
                                                            formik.values.is_all_day
                                                        }
                                                    />
                                                }
                                                label="終日"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </span>
                                <div
                                    className={classNames(
                                        "inlineField",
                                        formik.values.is_all_day
                                            ? ""
                                            : "hiddenValue"
                                    )}
                                >
                                    <div className="wrapTextField">
                                        <DatePickerField
                                            name="start_date"
                                            placeholder=""
                                            locale="ja"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="divider">~</div>
                                    <div className="wrapTextField">
                                        <DatePickerField
                                            name="end_date"
                                            placeholder=""
                                            locale="ja"
                                            formik={formik}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="filterBoxCol d-flex items-center mb-3">
                                <input 
                                    type="checkbox" 
                                    name="is_block_duplicate" 
                                    checked={!!formik.values.is_block_duplicate} 
                                    onChange={formik.handleChange}
                                />
                                <span className="fieldLabel !mb-0 !ml-2">
                                    スケジュールの重複をブロック
                                </span>
                            </div> */}

                            <div className="filterBoxCol">
                                <div className="wrapSelectField">
                                    <Select 
                                        value={formik.values.duplicate_type}
                                        onChange={(event) => {
                                          formik.setFieldValue("duplicate_type", event.target.value);
                                          setChangedDuplicateType(true);
                                        }}
                                        className="duplicate-types-options-select"
                                    >
                                      {duplicateTypes.map((item) => {
                                        return (
                                          <MenuItem value={item.value}>{item.label}</MenuItem>
                                        )
                                      })}
                                    </Select>
                                </div>
                            </div>

                            {formik.values.duplicate_type !== DuplicateTypeEnum.DEFAULT.value && (
                              <div className="filterBoxCol mb-3">
                                <RadioGroup
                                  // row
                                  name="duplicate_options"
                                  value={formik.values.duplicate_options}
                                  onChange={e => {
                                    formik.setFieldValue('duplicate_options', e.target.value);
                                  }}
                                >
                                  {Object.values(DuplicateOptionsEnum).map(category => (
                                    <div className="mb-2 flex items-center" key={category.value}>
                                      <FormControlLabel
                                        // className="flex items-center"
                                        value={category.value}
                                        control={
                                          <Radio
                                            checked={formik.values.duplicate_options === category.value}
                                          />
                                        }
                                        label={category.label}
                                      />
                                      {
                                        category.value === DuplicateOptionsEnum.BY_ON_DATE.value && (
                                          <SmallDatePickerField 
                                            name="duplicate_end_date"
                                            placeholder=""
                                            locale="ja"
                                            formik={formik}
                                            disabled={formik.values.duplicate_options !== DuplicateOptionsEnum.BY_ON_DATE.value}
                                            minDate={formik.values.start_date ? (new Date(formik.values.start_date)).setDate((new Date(formik.values.start_date)).getDate() + 1) : undefined}
                                            maxDate={duplicateEndDateMax}
                                            onChangeDuplicateEndDate={onChangeDuplicateEndDate}
                                          />
                                        )
                                      }
                                      {
                                        category.value === DuplicateOptionsEnum.BY_MAX_DUPLICATE.value && (
                                          <>
                                            <NumberInputField 
                                              name="max_duplicate"
                                              formik={formik}
                                              disabled={formik.values.duplicate_options !== DuplicateOptionsEnum.BY_MAX_DUPLICATE.value}
                                              max={duplicateMaxNum}
                                            />
                                            <span className="ml-2 text-[14px]" style={{
                                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                            }}>回の外観</span>
                                          </>
                                        )
                                      }
                                    </div>
                                  ))}
                                </RadioGroup>
                              </div>
                            )}

                            <div className="filterBoxCol">
                                <span className="fieldLabel">
                                    場所（営業所、住所など）
                                </span>
                                <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        name="place"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={formik.handleChange}
                                        value={formik.values.place}
                                    />
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">参加社員</span>
                                <div className="wrapSelectField multipleSelect">
                                    <Autocomplete
                                        multiple
                                        options={cloneWorkerOptions || []}
                                        onChange={(_event, newValue) => {
                                            if(newValue?.filter(item => item.id === 0)?.length > 0) {
                                                formik.setFieldValue(
                                                    "worker_schedules_attributes",
                                                    workerOptions
                                                );
                                            } else {
                                                formik.setFieldValue(
                                                    "worker_schedules_attributes",
                                                    newValue
                                                );
                                            }
                                        }}
                                        getOptionLabel={(option: any) =>
                                            option.name
                                        }
                                        value={
                                            formik.values
                                                .worker_schedules_attributes
                                        }
                                        noOptionsText="該当なし"
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option: any, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={`${option.name}`}
                                                    {...getTagProps({ index })}
                                                    className={
                                                        option.is_agree === false
                                                            ? "!border-[#ea0f0f]"
                                                            : ""
                                                    }
                                                />
                                            ))
                                        }
                                    />
                                </div>
                            </div>

                            <div className="filterBoxCol">
                                <span className="fieldLabel">色</span>
                                <div className="wrapColorBox">
                                    {SCHEDULER_COLORS.map((item, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            onClick={() => {
                                                formik.setFieldValue("color", item);
                                            }}
                                            className={classNames(
                                                formik.values.color === item
                                                    ? "active"
                                                    : ""
                                            )}
                                            style={{
                                                backgroundColor: item,
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>

                            {isAllowShowCheckbox && (
                                <div className="filterBoxCol">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                className="!p-0 !mr-[6px] !ml-[2px]"
                                                checked={formik.values.not_publish}
                                            />
                                        }
                                        label="非公開"
                                        name="not_publish"
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            )}
                        </div>

                        <DialogActions className="modal-create-plan-buttons d-flex flex-row !justify-center">
                            <button
                                className="modal-create-plan-button1 d-flex flex-row"
                                onClick={() => {
                                    setOpen(false);
                                    navigate(`/sp/calendar`, {
                                        state: {
                                            editingId: null,
                                        },
                                    });
                                    setIsShowDefaultDate(true);
                                }}
                            >
                                閉じる
                            </button>
                            {editingId && isAllowEdit && !checkUserGuest && (
                                <button
                                    type="button"
                                    className="modal-create-user-button2 d-flex flex-row"
                                    style={{
                                        background: "#FF5045",
                                    }}
                                    onClick={() => {
                                        setCurrentItemId(editingId);
                                        if (isRepeatSchedule) {
                                            setOpenDeleteRepeatScheduleDialog(true);
                                        } else {
                                            setOpenDeleteConfirmationDialog(true);
                                        }

                                    }}
                                >
                                    削除
                                </button>
                            )}

                            {(isAllowEdit || !editingId) && !checkUserGuest && (
                                <button
                                    className="modal-create-plan-button2 d-flex flex-row"
                                    type="submit"
                                    disabled={submitLoading}
                                >
                                    {submitLoading ? <CircularProgress size={16} sx={{ color: "#fff" }} /> : "登録"}
                                </button>
                            )}
                        </DialogActions>
                    </form>
                </CreateScheduleWrapper>
            </BootstrapDialog>
            {openUpdateRepeatScheduleDialog && (
              <UpdateRepeatScheduleDialog 
                open={openUpdateRepeatScheduleDialog}
                setOpen={setOpenUpdateRepeatScheduleDialog}
                onClick={handleUpdateForRepeatSchedule}
                changedDuplicateType={changedDuplicateType}
              />
            )}
        </>
    );
};

const CreateScheduleDialog: FC<ReceivedProps> = (props) => (
    <CreateScheduleDialogLayout {...useCreateScheduleDialog(props)} />
);

export default CreateScheduleDialog;
