import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const User: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeMiterlimit="10"
                d="M12 15a6 6 0 100-12 6 6 0 000 12z"
            ></path>
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.906 20.25a10.5 10.5 0 0118.188 0"
            ></path>
        </svg>
    );
};

export default User;
