// Lib
import { required } from "utils/validation/registers/validator";

// Type
import { TypeOfT } from "utils/type/translations/Translation.type";
import * as yup from "yup";

const schema = (t: TypeOfT) => {
    return yup.object().shape({
        name: yup
            .string()
            .required(required("ads_name", t))
            .trim(required("ads_name", t)),
        start_date: yup
            .date()
            .required(required("start_date", t)),
        message: yup
            .string()
    });
};
export default schema;
