import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const UsersFour: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 18.75a3 3 0 100-6 3 3 0 000 6zM3 21a5.626 5.626 0 019 0M7.5 9a3 3 0 100-6 3 3 0 000 6zM3 11.25a5.625 5.625 0 019 0M16.5 18.75a3 3 0 100-6 3 3 0 000 6zM12 21a5.627 5.627 0 019 0M16.5 9a3 3 0 100-6 3 3 0 000 6zM12 11.25a5.626 5.626 0 019 0"
            ></path>
        </svg>
    );
};

export default UsersFour;
