import { useState, useEffect } from 'react';
import './userList.css';
import userService from 'services/userService';
import workersService from 'services/wokersService';

import plus from 'assets/icons/Plus.png';
import 'react-dropdown/style.css';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import CreateNewUser from './components/create';
import UpdateUser from './components/update';
import DeleteUser from './components/delete';
import UserTable from './components/UserTable';
import UserSearch from './components/UserSearch';
import LineFriendService from 'services/lineService';

interface ILineFriend {
    id?: number;
    display_name?: string;
    label?: string,
    value?: number,
}
const UserList = (props) => {
    const [userList, setUserList] = useState<any>(null);
    const [tempList, setTempList] = useState<any>(null);
    const [isSearch, setIsSearch] = useState<any>(false);
    const [nameSearch, setNameSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const [openCreateModal, setOpenCreateModal] = useState<any>(false);
    const [openUpdateModal, setOpenUpdateModal] = useState<any>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);

    const [workerOptions, setWorkerOptions] = useState<any[]>([]);
    const [notExistOptions, setNotExistOptions] = useState<any>([]);
    const [lineFriendsOptions, setLineFriendsOptions] = useState<ILineFriend[]>([]);

    useEffect(() => {
        const getWorkerOptions = async () => {
            try {
                const response = await workersService.getListWorkers({
                    page: 1,
                    per_page: 0,
                });
                if (response) {
                    setWorkerOptions(
                        convertObjectToDropdownOptions(response.workers),
                    );
                }
            } catch (error) {
                // empty
            }
        };

        getWorkerOptions();
    }, []);

    const search = (key, key2, inputArray) => {
        let arr: Array<any> = [];
        if (key !== '' && key2 === '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.worker?.name
                        .toLowerCase()
                        .includes(key.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
        if (key === '' && key2 !== '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.email
                        .toLowerCase()
                        .includes(key2.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
        if (key !== '' && key2 !== '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.worker?.name
                        .toLowerCase()
                        .includes(key.toLowerCase()) &&
                    inputArray[i]?.email
                        .toLowerCase()
                        .includes(key2.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
    };

    const handleSearchName = () => {
        let result: any = null;
        if (nameSearch !== '' && emailSearch === '') {
            setIsSearch(true);
            result = search(nameSearch, '', userList);
            setTempList(result);
        } else if (nameSearch === '' && emailSearch !== '') {
            setIsSearch(true);
            result = search('', emailSearch, userList);
            setTempList(result);
        } else if (nameSearch !== '' && emailSearch !== '') {
            setIsSearch(true);
            result = search(nameSearch, emailSearch, userList);
            setTempList(result);
        } else {
          setIsSearch(false)
          getListUser();
        }
    };

    const handleOpenCreateModal = () => {
        setOpenCreateModal(true);
    };

    const handleOpenUpdateModal = (row) => {
        setOpenUpdateModal(true);
        setSelectedUser(row);
    };

    const handleOpenDeleteModal = (row) => {
        setOpenDeleteModal(true);
        setSelectedUser(row);
    };

    const getListUser = async () => {
        try {
            const response = await userService.getListUser();
            if (response !== null && response !== undefined) {
                setUserList(response.users);

                let arr = convertObjectToDropdownOptions(response.users.map(v => v.worker))

                let notExist = workerOptions.filter(v => {
                    return !arr.find(e => {
                        return e.value == v.value;
                    });
                });

                setNotExistOptions(notExist);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getLineFriendOptions = async () => {
        try {
            const res = await LineFriendService.getLineFriends();
            if(!(res as {line: ILineFriend[]})?.line) return;
            const line = (res as {line: ILineFriend[]})?.line;
            setLineFriendsOptions(
                line?.map((item) => ({
                    label: item.display_name,
                    value: item.id,
                })),
            );
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getListUser();
    }, [workerOptions]);

    useEffect(() => {
        if (!openUpdateModal) {
            setSelectedUser(null);
        }
    }, [openUpdateModal]);

    useEffect(() => {
        if (!openDeleteModal) {
            setSelectedUser(null);
        }
    }, [openDeleteModal]);

    useEffect(() => {
        getLineFriendOptions();
    }, [])
    return (
        <div className="container-users d-flex flex-column">
            <div className="users-content d-flex flex-column">
                <div className="create-button d-flex flex-row">
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={handleOpenCreateModal}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>

                <UserSearch
                    onSearchName={(e) => setNameSearch(e.target.value)}
                    onSearchEmail={(e) => setEmailSearch(e.target.value)}
                    onClick={handleSearchName}
                />

                <UserTable
                    userList={isSearch ? tempList : userList}
                    onClickUpdate={(row) => handleOpenUpdateModal(row)}
                    onClickDelete={(row) => handleOpenDeleteModal(row)}
                />

                {openCreateModal && (
                    <CreateNewUser
                        open={openCreateModal}
                        setOpen={setOpenCreateModal}
                        onFinish={() => {
                            getListUser();
                            setOpenCreateModal(false);
                        }}
                        workerOptions={notExistOptions}
                        lineFriendsOptions={lineFriendsOptions}
                    />
                )}

                {openUpdateModal && selectedUser && (
                    <UpdateUser
                        open={openUpdateModal}
                        setOpen={setOpenUpdateModal}
                        selectedUser={selectedUser}
                        onFinish={() => {
                            getListUser();
                            setOpenUpdateModal(false);
                        }}
                        workerOptions={notExistOptions}
                        lineFriendsOptions={lineFriendsOptions}
                    />
                )}

                {openDeleteModal && selectedUser && (
                    <DeleteUser
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        onFinish={() => {
                            getListUser();
                            setOpenDeleteModal(false);
                        }}
                        selectedUser={selectedUser}
                    />
                )}
            </div>
        </div>
    );
};

export default UserList;
