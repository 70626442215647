import moment from 'moment';
import { MILLISECONDS_PER_DAY } from 'utils/constants';

/*
 * @param yyyy/MM/DDTHH:mm:ss.sssZ
 * || yyyy/MM/DDTHH:mm:ss+00:00
 * @param format Date
 * @param prefix String
 * return DD/MM/YYYY HH:mm
 */
export const formatDateTime = (
    dateTime,
    format = 'DD/MM/YYYY HH:mm',
    prefix = '',
) => {
    try {
        if (!dateTime) return '';
        if (moment(dateTime).isValid())
            return prefix + moment(dateTime).format(format);
    } catch (error) {
        console.log('Catch formatDateTime', error);
    }
};

export const getDateTimeUtc = (dateTime) => {
    // input format: 2023/03/30 07:30
    // output format: 2023-03-30T00:30:00Z

    const currentDate = moment(dateTime).utc().format();
    return currentDate;
};

export const getYesterDay = (date = new Date()) => {
    const yesterday = new Date(date.getTime());
    yesterday.setDate(date.getDate() - 1);
    return yesterday;
}

export const getTomorrow = (date = new Date()) => {
    const tomorrow = new Date(date.getTime());
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
}

export const getMondayOfWeek = (date = new Date()) => {
    // console.log('getDay', date);
    const currentDay = date.getDay();
    const monday = new Date(date.getTime());
    switch (currentDay) {
        case 0:
            // Sunday
            monday.setDate(date.getDate() - 6);
            break;
        case 1:
            // Monday
            break;
        case 2:
            // Tuesday
            monday.setDate(date.getDate() - 1);
            break;
        case 3:
            // Wednesday
            monday.setDate(date.getDate() - 2);
            break;
        case 4:
            // Thursday
            monday.setDate(date.getDate() - 3);
            break;
        case 5:
            // Friday
            monday.setDate(date.getDate() - 4);
            break;
        case 6:
            // Saturday
            monday.setDate(date.getDate() - 5);
            break;
    
        default:
            break;
    }
    return monday;
}

export const getSundayOfWeek = (date = new Date()) => {
    const currentDay = date.getDay();
    const sunday = new Date(date.getTime());
    switch (currentDay) {
        case 0:
            // Sunday
            break;
        case 1:
            // Monday
            sunday.setDate(date.getDate() + 6);
            break;
        case 2:
            // Tuesday
            sunday.setDate(date.getDate() + 5);
            break;
        case 3:
            // Wednesday
            sunday.setDate(date.getDate() + 4);
            break;
        case 4:
            // Thursday
            sunday.setDate(date.getDate() + 3);
            break;
        case 5:
            // Friday
            sunday.setDate(date.getDate() + 2);
            break;
        case 6:
            // Saturday
            sunday.setDate(date.getDate() + 1);
            break;
    
        default:
            break;
    }
    return sunday;
}

export const calculateDays = (startDate: string, endDate: string): number => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  // One day in milliseconds, used for calculation
  const oneDay: number =  24 * 60 * 60 * 1000;

  // Add 1 day to make the calculation inclusive
  end.setDate(end.getDate() + 1);

  const diffDays: number = Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay));

  return diffDays;
};

export const compareDates = (date1: string, date2: string): number => {
  const a: moment.Moment = moment(date1);
  const b: moment.Moment = moment(date2);

  if (a.isBefore(b)) return -1; // a is earlier than b
  if (a.isAfter(b)) return 1; // a is later than b
  return 0; // a and b are the same day
}

export const compareTwoDateObjects = (d1: Date, d2: Date) => {
  // Converting both dates to same format (set hours, minutes, seconds and milliseconds as 0)
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  date1.setHours(0,0,0,0);
  date2.setHours(0,0,0,0);
   
  if (date1 < date2) 
    // d1 is earlier than d2
    return -1;
  else if (date1 > date2) 
    // d1 is later than d2
    return 1;
  else 
    // d1 is the same day as d2
    return 0;
}

export function getWeekNumberInMonth(dateObj: Date): number {
  //Calculate first of month for the given date
  let firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
  //Calculate the difference in days
  let dayDifference = Math.ceil((dateObj.getTime() - firstDay.getTime()) / (1000*60*60*24));
  //Add the day of the week (0 index based)
  let weeks = Math.ceil((dayDifference + firstDay.getDay() + 1) / 7);
  return weeks;
}

export function getDateInNextYears(givenDate: Date | string, years: number) {
  let date = new Date(givenDate);
  date.setFullYear(date.getFullYear() + years);  // Add 3 years to the date.
  return date;
}

export function getDaysBetweenDates(startDate: Date | string, endDate: Date | string) {
  let start = new Date(startDate);
  let end = new Date(endDate);
  
  // It is necessary to add 1 to include the last day
  let dayCount = Math.floor((end.getTime() - start.getTime()) / MILLISECONDS_PER_DAY) + 1;
  return dayCount;
}

export function countSpecificDayBetweenDates(
  startDate: Date | string,
  endDate: Date | string,
  dayOfWeek: number,
) {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let count = 0;
  for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === dayOfWeek) {
      count++;
    }
  }
  return count;
}

export function countWeekdaysBetweenDates(startDate, endDate) {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let count = 0;
  for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
    let day = d.getDay();
    if (day !== 0 && day !== 6) { // Skip weekends
      count++;
    }
  }
  return count;
}