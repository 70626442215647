import Scheduler, { Resource, View } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./styled";
import useDayViewComponent, { Props, ReceivedProps } from "./hook";
import { SLASH_LINES_BACKGROUND } from "pages/schedules/SchedulerBox/WeekTable";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

const groups = ["ConferenceId"];

const StyledButton = styled(Button)({
  color: "rgb(28, 30, 33)",
  fontWeight: 500,
  fontSize: "14px",
  minWidth: "58px",
  background: "rgb(231, 231, 231)",
  border: "1px solid rgba(28, 30, 33, 0.2)",
  cursor: "pointer",
  borderRadius: "4px",
  textAlign: "center",
  padding: "0px",
  marginLeft: "25px",
});

const DayViewComponentLayout: FC<Props> = ({
    currentDate,
    currentViewName,
    dataSource,
    setOpenScheduleFormDialog,
    workerGroup,
    setCurrentDate,
    setDefaultDate,
    setIdWorkerClicked
}) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const navigate = useNavigate();
    const getDayByCurrentDay = dataSource.filter((item) =>
        moment(moment(item?.startDate).format("YYYY/MM/DD")).isSame(
        moment(currentDate).format("YYYY/MM/DD"),
        ),
    );

    const newDataSource = JSON.parse(JSON.stringify(dataSource)).map((item: any) => {
      return {
        ...item,
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
      }
    })

    const moveToYesterday = () => {
      setCurrentDate((today) => moment(today).subtract(1, "days"));
    };
  
    const moveToTomorrow = () => {
      setCurrentDate((today) => moment(today).add(1, "days"));
    };

    return (
        <Styles className={currentViewName} style={{ marginTop: "110px" }}>
            <div className="dateNavigate relative flex w-[55%] justify-between mb-5">
              <div className="ml-4">
                <button onClick={moveToYesterday}>
                  <ArrowBackIosOutlined style={{ color: "#0000008a" }} />
                </button>
                <button onClick={moveToTomorrow} className="ml-3">
                  <ArrowForwardIosOutlined style={{ color: "#0000008a" }} />
                </button>
                <StyledButton onClick={() => setCurrentDate(new Date())}>今日</StyledButton>
              </div>

              <div className="font-semibold">{moment(currentDate).format("YYYY年MM月DD日")}</div>
            </div>
            <Scheduler 
                adaptivityEnabled={true}
                // timeZone="Asia/Tokyo"
                dataSource={newDataSource}
                views={["timelineDay"]}
                crossScrollingEnabled={true}
                defaultCurrentView="timelineDay"
                defaultCurrentDate={new Date(moment(currentDate).toISOString())}
                groups={groups}
                maxAppointmentsPerCell="unlimited"
                showCurrentTimeIndicator={true}
                height={ workerGroup?.length <= 1 ? (getDayByCurrentDay?.length <= 4 ? 300 : getDayByCurrentDay?.length * 59) :
                getDayByCurrentDay?.length === 0
                    ? 400
                    : getDayByCurrentDay.length === 1
                    ? 400
                    : getDayByCurrentDay.length * 550
                }
                cellDuration={30}
                startDayHour={0}
                onContentReady={(e) => {
                    const scheduler = e.component;
                    const currentDate = scheduler.getStartViewDate();
                    currentDate.setHours(10, 20, 0);
                    e.component.scrollTo(currentDate);
                }}
                endDayHour={24}
                editing={false}
                timeCellComponent={(props: any) => {
                    if (props.data.text?.includes("30")) {
                      const eles = document.getElementsByClassName("dx-scheduler-header-panel-cell");
                      Array.from(eles)?.map((ele, index: number) => {
                        if (index % 2 !== 0) {
                          ele.classList.value += " hiddenTime"
                        }
                      })
                      return <div style={{ display: "none" }}></div>
                    }
                    return <div {...props}>{moment(props.data.date).format("H:mm")}</div>;
                }}
                focusStateEnabled={false}
                onAppointmentDblClick={(e) => {
                    e.cancel = true;
                }}
                appointmentDragging={{
                    onDragStart: (e) => {
                        e.cancel = true;
                    },
                }}
                onAppointmentTooltipShowing={(e) => {
                    e.cancel = true;
                }}
                onCellClick={(e) => {
                  const { startDate } = e.cellData;
                  setOpenScheduleFormDialog(true);
                  navigate(`/sp/calendar`, {
                    state: {
                      editingId: null,
                    },
                  });
                  setDefaultDate(startDate);
                  setIdWorkerClicked(e.cellData?.groups?.ConferenceId || 0);
                }}
                appointmentComponent={(props: any) => {
                  // check to show slash lines
                  let isShowSlashLines = false;
                  let assignWorker = props?.data?.appointmentData?.worker_schedules?.filter(item => item?.worker_id === props.data?.targetedAppointmentData?.ConferenceId);
                  if (props.data?.targetedAppointmentData?.ConferenceId === props?.data?.appointmentData?.worker?.id && props?.data?.appointmentData?.schedule_maker_is_agree === null) {
                    isShowSlashLines = true;
                  } else if (assignWorker[0] && assignWorker[0].is_agree === null && assignWorker[0].worker_id !== props?.data?.appointmentData?.created_by?.id) {
                    isShowSlashLines = true;
                  }
                  //check flow of acc m-terashima.ken@kyowak.co.jp not_publish
                  let isNotPublish = props?.data?.appointmentData?.not_publish && userData.worker?.id !== props.data?.targetedAppointmentData?.ConferenceId && userData?.email !== "m-terashima.ken@kyowak.co.jp";
                    return (
                      <div
                        onClick={() => {
                          if (props?.data?.appointmentData?.project_id) {
                            navigate(
                              `/projects/detail/${props?.data?.appointmentData?.project_id}/plan`,
                              {
                                state: {
                                  id: props?.data?.appointmentData?.project_id,
                                },
                              },
                            );
                          } else {
                            if (isNotPublish) {
                              setOpenScheduleFormDialog(false);
                            } else {
                              navigate(`/sp/calendar`, {
                                state: {
                                  editingId: props?.data?.appointmentData?.id,
                                },
                              });
                              setOpenScheduleFormDialog(true);
                            }
                          }
                        }}
                        {...props}
                        style={{
                          height: "100%",
                          background: isShowSlashLines ? SLASH_LINES_BACKGROUND : '',
                          backgroundColor:
                            props?.data?.appointmentData?.color || "rgb(245,245,245)",
                          color: props?.data?.appointmentData?.color
                            ? "#ffffff"
                            : "#000000",
                          padding: 5,
                        }}
                      >
                        <p>
                          {isNotPublish ? "アポ有り" : (props.data?.appointmentData?.title ||
                            props.data?.appointmentData?.content ||
                            props.data?.appointmentData?.name)}
                        </p>
                        {!isNotPublish && <>
                          {props.data?.appointmentData?.allDay ? (
                            <p>終日</p>
                          ) : (
                            <p>
                              {props.data?.appointmentData?.title
                                ? moment(props.data?.appointmentData?.startDate).format(
                                  "HH:mm",
                                ) + " - "
                                : ""}
                              {props.data?.appointmentData?.title
                                ? moment(props.data?.appointmentData?.endDate).format(
                                  "HH:mm",
                                )
                                : ""}
                            </p>
                          )}
                        </>}
                      </div>
                    );
                }}
            >
                <View
                    name="Vertical Grouping"
                    type="timelineDay"
                    groupOrientation="vertical"
                />
                <View
                    name="Horizontal Grouping"
                    type="day"
                    groupOrientation="horizontal"
                />
                <Resource
                    fieldExpr="ConferenceId"
                    allowMultiple={true}
                    dataSource={[
                        ...workerGroup.map((item) => ({
                            id: item.id,
                            text: item.text,
                        }))
                    ]}
                    label="Worker"
                />
            </Scheduler>
        </Styles>
    );
};

const DayViewComponent: FC<ReceivedProps> = (props) => (
    <DayViewComponentLayout {...useDayViewComponent(props)}  />
);

export default DayViewComponent;
