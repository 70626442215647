import { useState, useEffect, useMemo } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Checkbox, TextField, useMediaQuery } from '@mui/material';
import { login, register, loginSP } from '../../store/auth/auth';
import { clearMessage } from '../../store/message/message';
import { RootState, AppDispatch, useAppDispatch } from '../../store/index';
import { setMessage } from '../../store/message/message';
import { alertError } from 'utils/helper/appHelper';
import { useFormik } from 'formik';
import { loginValidation } from './validation';

initializeIcons();

const LoginForm = ({
    // onSubmit,
    // email,
    // handleChangeEmail,
    // pass,
    // handleChangePass,
    message,
    remember,
    setRemember,
    formik,
}) => {
    return (
        <div className="login-form d-flex flex-column">
            <div className="title">{process.env.REACT_APP_PROJECT_NAME}</div>
            <form className="form d-flex flex-column" onSubmit={formik.handleSubmit}>
                <div className="text-field">
                    <label>
                        Email
                        <TextField
                            style={{ display: 'block' }} 
                            type="text"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            // required
                            title="Please enter your email"
                            lang="en"
                            placeholder="olivia@untitledui.com"
                        />
                    </label>
                    {
                      formik.touched.email && formik.errors.email && (
                        <span className="error">{formik.errors.email}</span>
                      )
                    }
                </div>
                <div className="text-field">
                    <label>
                        パスワード
                        <TextField
                            style={{ display: 'block' }} 
                            type="password"
                            name="password"
                            // required
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            lang="en"
                            title="Please enter your password"
                            placeholder="************"
                        />
                    </label>
                    {
                      formik.touched.password && formik.errors.password && (
                        <span className="error">{formik.errors.password}</span>
                      )
                    }
                    {message !== undefined && (
                        <p className="validate-warning">{message}</p>
                    )}
                </div>
                <div className="checkbox d-flex flex-row">
                    <Checkbox
                        sx={{
                            color: "#3DDD74",
                            '&.Mui-checked': {
                              color: "#3DDD74",
                            },
                            '& .MuiSvgIcon-root': { fontSize: 18 },
                            width: 15,
                            height: 15
                          }}
                        checked={remember}
                        onChange={() => setRemember(!remember)}
                    />
                    <div className="checkbox-text">ログインを維持する</div>
                </div>
                <input
                    type="submit"
                    value="ログイン"
                    className="submit-button d-flex flex-column"
                />
            </form>
        </div>
    );
};
const Login = () => {
    const navigate = useNavigate();
    const { message } = useSelector((state: RootState) => state.message);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<any>('');
    const [pass, setPass] = useState<any>('');
    const [remember, setRemember] = useState<boolean>(false);
    const matchesSP = useMediaQuery('(max-width: 912px)');
    const dispatch = useAppDispatch();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        email: '',
        password: ''
      },
      validate: loginValidation,
      onSubmit: (values) => {
        submitLogin(values);
      }
    });

    const handleChangeEmail = (event: any) => {
        setEmail(event.target.value);
    };

    const handleChangePass = (event: any) => {
        setPass(event.target.value);
    };

    const submitLogin = async (values) => {
        // e.preventDefault();
        const body = {
            email: values.email,
            password: values.password,
            is_remember: remember,
        };
        dispatch(matchesSP ? loginSP(body) : login(body))
            .unwrap()
            .then(() => {
                navigate('/');
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                // dispatch(setMessage(err.error));
                alertError(err.error);
                setLoading(false);
            });
    };

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const loginForm = useMemo(() => {
        return (
            <LoginForm
                // onSubmit={onSubmit}
                formik={formik}
                // email={email}
                // handleChangeEmail={handleChangeEmail}
                // pass={pass}
                // handleChangePass={handleChangePass}
                message={message}
                remember={remember}
                setRemember={setRemember}
            />
        );
    }, [message, remember, email, pass]);

    return (
        <div className="container-login d-flex flex-row gap-x-[40px]">
            <div
                style={{
                    backgroundImage: `url(${
                        process.env.PUBLIC_URL + '/img/login-background.png'
                    })`,
                }}
                className="login-background"
            >
                <div className="login-form-mobile">
                  <LoginForm
                      // onSubmit={onSubmit}
                      formik={formik}
                      // email={email}
                      // handleChangeEmail={handleChangeEmail}
                      // pass={pass}
                      // handleChangePass={handleChangePass}
                      message={message}
                      remember={remember}
                      setRemember={setRemember}
                  />
                </div>
            </div>
            <div
                className="right d-flex flex-column"
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '43.5%',
                    height: '100%',
                }}
            >
                <LoginForm
                    // onSubmit={onSubmit}
                    formik={formik}
                    // email={email}
                    // handleChangeEmail={handleChangeEmail}
                    // pass={pass}
                    // handleChangePass={handleChangePass}
                    message={message}
                    remember={remember}
                    setRemember={setRemember}
                />
            </div>
        </div>
    );
};

const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
};
export default Login;
