import axios from 'axios';
import { getToken } from '../../utils/helper/userConfigHelper';

const Axios = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API,
});

Axios.interceptors.request.use(
    function (config: any) {
        const token = getToken();
        if (typeof token !== 'undefined') {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    function (error) {
        console.log(error);
        return Promise.reject(error);
    },
);

Axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response?.data;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    },
);

export function convertToQueryString(params: any) {
    if (typeof params == 'string') return params;
    let paramString = '';
    Object.keys(params).map((key) => {
        if (params[key] !== '') {
            paramString += key + '=' + params[key] + '&';
        }
    });
    return paramString;
}

const fetch = {
    async get(url: string, params: any) {
        return new Promise((res, rej) => {
            const queryString = new URLSearchParams(params);

            // let queryString = convertToQueryString(params);

            url = url + '?' + queryString.toString();

            try {
                let response = Axios.get(url);
                return res(response);
            } catch (error) {
                rej(error);
            }
        });
    },

    async put(url: string, params: any) {
        return new Promise((res, rej) => {
            try {
                let response = Axios.put(url, params);
                return res(response);
            } catch (error) {
                rej(error);
            }
        });
    },

    async post(url: string, params: any) {
        return new Promise((res, rej) => {
            try {
                let response = Axios.post(url, params);
                return res(response);
            } catch (error) {
                rej(error);
            }
        });
    },

    async delete(url: string, params: any) {
        return new Promise((res, rej) => {
            const queryString = new URLSearchParams(params);
            url = url + '?' + queryString.toString();
            try {
                let response = Axios.delete(url, params);
                return res(response);
            } catch (error) {
                rej(error);
            }
        });
    },
};

export default fetch;
