import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';

    .datePickerErrorText {
        color: #d32f2f;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        text-align: left;
        margin-top: 3px;
        margin-right: 14px;
        margin-bottom: 0;
    }

    .innerItem {
        position: relative;
        width: 100%;
        &.error {
            .react-datepicker__input-container {
                input {
                    border-color: #d32f2f;
                }
            }
        }
    }

    img {
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 2;
        width: 13px;
        height: 14px;
        pointer-events: none;
    }

    .react-datepicker__input-container {
        input {
            height: 30px;
            background: #f3f3f3;
            border-radius: 4px;
            padding: 5px 5px;
            border: 0;
            font-weight: 400;
            color: #1c1e21;
            font-size: 14px;
            box-sizing: border-box;
            width: 100%;
            &:placeholder {
                color: #1c1e21;
            }
        }
    }
`;
