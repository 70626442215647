import React from 'react';
import ReactDOM from 'react-dom';
import setupAxios from './config/axiosConfig';
import i18n from './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import { ActionCableProvider } from 'react-actioncable-provider';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { registerLicense } from '@syncfusion/ej2-base';
// import { GoogleOAuthProvider } from '@react-oauth/google';
const SYNCFUSION_LICENSE_KEY = process.env.REACT_APP_SYNCFUSION_LICENSE_KEY;
registerLicense(SYNCFUSION_LICENSE_KEY || '');
setupAxios();
moment.locale('ja');
let persistor = persistStore(store);
const API = process.env.REACT_APP_SOCKET_API;
// const GoogleClientID = process.env.GOOGLE_CLIENT_ID;
const user: any = localStorage.getItem('user');
const access_token: any = JSON.parse(user)?.access_token;

const url = API + '?access_token=' + access_token;

ReactDOM.render(
    // <GoogleOAuthProvider clientId={GoogleClientID}>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <I18nextProvider i18n={i18n}>
                        <ActionCableProvider url={url}>
                            <App />
                        </ActionCableProvider>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    // </GoogleOAuthProvider>
    ,
    document.getElementById('root'),
);

reportWebVitals();
