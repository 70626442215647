import Api from './api/estimateItemApi';
import pick from 'lodash/pick';

const createChildEstimateItem = async (payload) => {
    return await Api()
        .post('/child_estimate_items', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createEstimateItem = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateEstimate = async (estimateId: number, payload: any) => {

    return await Api()
        .put(`${estimateId}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteEstimateItem = async (input?: any) => {
    let data = pick(input, ['project_id', 'estimate_id']);
    const params = new URLSearchParams(data);

    return await Api()
        .delete(`/${input?.id}?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getDetailEstimateItem = async (input?: any) => {
    let data = pick(input, ['project_id', 'estimate_id']);
    const params = new URLSearchParams(data);

    return await Api()
        .get(`/${input?.id}?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListEstimateItem = async (input?: any) => {
    let data = pick(input, ['page', 'per_page', 'estimate_id']);
    // if (input.department_id) data.department_id = input.department_id?.value;
    // if (input.position_id) data.position_id = input.position_id?.value;
    // if (input.cooperative_company_id)
    //     data.cooperative_company_id = input.cooperative_company_id?.value;
    // if (input.gender_type) data.gender_type = input.gender_type?.value;

    const params = new URLSearchParams(data);

    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const estimateService = {
    createEstimateItem,
    createChildEstimateItem,
    updateEstimate,
    deleteEstimateItem,
    getDetailEstimateItem,
    getListEstimateItem,
};

export default estimateService;
