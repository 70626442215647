import { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';

import TabWrapper from './style';
import { getRole } from 'utils/helper/userConfigHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { isUserGuest } from 'utils/helper/workerHelper';

type IProps = {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
};

const TabBox: FC<IProps> = ({ activeTab, setActiveTab }) => {
    const { userData } = useSelector((state: RootState) => state.users);
    let checkUserGuest = isUserGuest(userData); 
    const TAB_DATA = userData.worker && !checkUserGuest ? [
      {
          title: '自分のみ',
          id: 0,
      },
      {
          title: '全員',
          id: 1,
      },
    ] : [
      {
          title: '全員',
          id: 1,
      },
    ];
    return (
        <TabWrapper>
            {TAB_DATA.map((item, index) => (
                <button
                    className={classNames(activeTab === item.id ? 'active' : '')}
                    key={item.id}
                    onClick={() => setActiveTab(item.id)}
                    type="button"
                >
                    {item.title}
                </button>
            ))}
        </TabWrapper>
    );
};

export default TabBox;
