import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { GridSizeChangedEvent, GridApi } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { isEmpty } from 'lodash';

import { IPageQueries } from 'models';
import { columns } from './utility';
import suggestionService from 'services/suggestionService';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export type ReceivedProps = {
    queryFilter: Record<string, any>;
    setTotalAmount: Dispatch<SetStateAction<number>>;
};

export const ROW_HEIGHT = 50;
export const HEADER_HEIGHT = 40;

const useSuggestion = (props: ReceivedProps) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const isManager = user.role === 'admin';
    const columnDefsSetting = columns(isManager).map((item) => ({
        ...item,
        filterParams: {
            textMatcher: ({ value, filterText }: any) => {
                const filterTextLowerCase = filterText.toLowerCase();
                const valueLowerCase = value.toString().toLowerCase();
                return valueLowerCase.includes(filterTextLowerCase);
            },
        },
    }));

    const [gridApi, setGridApi] = useState<GridApi>();
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const [rowData, setRowData] = useState<any[]>([]);
    const [columnDefs] = useState<any[]>(columnDefsSetting);
    const [total, setTotal] = useState<number>(0);
    const [suggestionId, setSuggestionId] = useState<number | null>(null);

    const gridRef: any = useRef();

    const containerStyle = useMemo(
        () => ({
            width: '100%',
            height: `${
                isEmpty(rowData)
                    ? 600
                    : rowData.length * ROW_HEIGHT + HEADER_HEIGHT + 1
            }px`,
        }),
        [rowData],
    );
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: false,
            floatingFilter: false,
            sortable: false,
        };
    }, []);

    const isFullWidthRow = useCallback((params) => {
        return params.rowNode.data.fullWidth;
    }, []);

    const onVirtualColumnsChanged = () => {
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    };

    const onGridReady = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
        setGridApi(params.api);
    };

    const fetchSuggestions = useCallback(async () => {
        try {
            const { suggestions, meta } =
                await suggestionService.getSuggestions({
                    ...pageQueries,
                    ...(props.queryFilter?.free_word && {
                        free_word: props.queryFilter.free_word,
                    }),
                });

            setRowData(suggestions);
            setTotal(meta.total_count);
            props.setTotalAmount(meta.total_amount);
        } catch (error) {
            //
        }
    }, [pageQueries, props.queryFilter]);

    const onDeleteSuggestion = async (closeDelete: () => void) => {
        if (!suggestionId) return;

        try {
            await suggestionService.deleteSuggestion(suggestionId);
            fetchSuggestions();
            closeDelete();
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        fetchSuggestions();
    }, [fetchSuggestions]);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }

        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    }, [rowData, gridApi]);

    return {
        ...props,
        containerStyle,
        gridStyle,
        defaultColDef,
        columnDefs,
        gridRef,
        rowData,
        pageQueries,
        total,
        onDeleteSuggestion,
        setSuggestionId,
        setPageQueries,
        onGridReady,
        isFullWidthRow,
        onVirtualColumnsChanged,
    };
};

export type Props = ReturnType<typeof useSuggestion>;

export default useSuggestion;
