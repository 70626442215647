import Api from './api/adsApi';
import pick from 'lodash/pick';

const getListAds = async (params: any = { page: 1, per_page: 0, name: '' }) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                return await Api().get('', { headers: { Authorization: `Bearer ${user.access_token}` }, params }).then(response => {
                    if (response.data) {
                        return response.data;
                    }
                })
            }
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const getAds = async (id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)) {
        try {
            return await Api().get(`${id}`, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
                if (response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}


const createAds = async (body) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;

    if ((user.access_token !== undefined && user.access_token !== null)) {
        try {
            return await Api().post('', body, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
                if (response.data) {
                    return response.data;
                }
            })

        } catch (err) {
            console.log(err)
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const updateAds = async (body, id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'name',
        'start_date',
        'message'
    ]);
    if ((user.access_token !== undefined && user.access_token !== null)) {
        return await Api().put(`${id}`, data, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
            if (response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const updateAdsFiles = async (body, id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)) {
        // try {
        return await Api().put(`${id}/add_files`, body, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
            if (response.data) {
                return response.data;
            }
        })
        // } catch (err) {
        //     console.log(err)
        //     return null
        // }
    } else {
        window.location.href = '/login';
    }
}

const deleteAds = async (id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)) {
        try {
            return await Api().delete(`${id}`, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
                if (response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            // window.location.href = '/login';
            return err
        }
    } else {
        window.location.href = '/login';
    }
}

const deleteAdsFile = async (id, signed_id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)) {
        try {
            return await Api().delete(`${id}/delete_file?signed_id=${signed_id}`, { headers: { Authorization: `Bearer ${user.access_token}` } }).then(response => {
                if (response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            // window.location.href = '/login';
            return err
        }
    } else {
        window.location.href = '/login';
    }
}

const adsService = {
    getListAds,
    createAds,
    updateAds,
    deleteAds,
    getAds,
    deleteAdsFile,
    updateAdsFiles
};

export default adsService;
