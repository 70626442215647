import React, { FC } from "react";
import { 
    ScheduleComponent, 
    Day, 
    Week,
    Month,
    Inject,
    ViewsDirective,
    ViewDirective,
    EventSettingsModel,
} from "@syncfusion/ej2-react-schedule";
import StyledSyncfusionSchdulerContainer from "./style";
import useSyncfusionScheduler, { Props } from "./hook";

const SyncfusionSchedulerLayout: FC<Props> = ({
    dataManager
}) => {
    const data = [
        {
          Id: 1,
          Subject: 'Meeting',
          IsAllDay: false,
          StartTime: new Date(2023, 6, 15, 10, 0),
          EndTime: new Date(2023, 6, 15, 12, 30),
          Worker: 'worker 1',
        },
        {
          Id: 2,
          Subject: 'CAMON',
          IsAllDay: false,
          StartTime: new Date(2023, 6, 11, 2, 0),
          EndTime: new Date(2023, 6, 11, 8, 0),
          Worker: 'worker 1',
        },
        {
          Id: 3,
          Subject: 'Ok hand',
          IsAllDay: false,
          StartTime: new Date(2023, 6, 22, 13, 0),
          EndTime: new Date(2023, 6, 22, 14, 0),
          Worker: 'worker 2',
        },
    ];
    const onPopupOpen = (args) => {
        console.log('onPopupOpen args', args);
    }
    const eventSettings: EventSettingsModel = {
        dataSource: dataManager?.dataSource.json
    }
    console.log('dataManager', dataManager);
    return (
        <StyledSyncfusionSchdulerContainer>
            <div className="pageContentWrapper">
                <ScheduleComponent
                    selectedDate={new Date(2023, 6, 28)}
                    eventSettings={eventSettings}
                    popupOpen={onPopupOpen}
                    currentView="Month"
                >
                    <ViewsDirective>
                        <ViewDirective option="Day" />
                        <ViewDirective option="Week" />
                        <ViewDirective option="Month" />
                    </ViewsDirective>
                    <Inject services={[Day, Week, Month]} />
                </ScheduleComponent>
            </div>
        </StyledSyncfusionSchdulerContainer>
    )
}

const SyncfusionScheduler = () => (
    <SyncfusionSchedulerLayout {...useSyncfusionScheduler()} />
);

export default SyncfusionScheduler;