/* eslint-disable import/first */
import { useState, useEffect } from "react";
import "./companyEdit.css";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../../assets/icons/calendar.png";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import CompanyService from "../../../services/clientCompanyService";
import CooperativeCompanyService from "../../../services/cooperativeCompaniesService";
import createClientCompanyValidation from "utils/validation/registers/clientCompanies/createClientCompany";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  convertClientCompaniesToDropdownOptions,
  removeUndefinedCompany,
} from "utils/helper/clientCompanyHelper";
import schema from "utils/validation/registers/clientCompanies/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from 'react-redux';
import { isAllowAction } from "utils/helper/workerHelper";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

const CooperativeCompanyEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema(t)),
  });

  const { userData } = useSelector((state) => state.users);
  const isAllow = isAllowAction(userData);

  const [companyOption, setCompanyOption] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const [checkClient, setCheckClient] = useState(false);
  const [checkCooperative, setCheckCooperative] = useState(true);

  const handleStartAtChange = (date) => {
    setStartDate(date);
  };

  const getListClientCompany = async () => {
    try {
      const response = await CompanyService.getListCompanies();
      if (response !== null && response !== undefined) {
        let tempListClient = convertClientCompaniesToDropdownOptions(
          response.client_companies
        );
        const finalClients = removeUndefinedCompany(tempListClient);
        setCompanyOption(finalClients);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListCooperativeCompanies = async () => {
    try {
      const response = await CooperativeCompanyService.getListCooperativeCompanies();
      if (response !== null && response !== undefined) {
        let tempListClient = convertClientCompaniesToDropdownOptions(
          response.cooperative_companies
        );
        const finalClients = removeUndefinedCompany(tempListClient);
        setCompanyOption(finalClients);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListCompany = async () => {
    try {
      let listClient = [];
      let listCooperative = [];
      const response = await CompanyService.getListCompanies();
      if (response !== null && response !== undefined) {
        listClient = convertClientCompaniesToDropdownOptions(
          response.client_companies
        );
      }
      const response2 = await CooperativeCompanyService.getListCooperativeCompanies();
      if (response2 !== null && response2 !== undefined) {
        listCooperative = convertClientCompaniesToDropdownOptions(
          response2.cooperative_companies
        );
      }
      const finalClients = removeUndefinedCompany(
        listClient.concat(listCooperative)
      );
      setCompanyOption(finalClients);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    checkClient && !checkCooperative && getListClientCompany();
    !checkClient && checkCooperative && getListCooperativeCompanies();
    checkClient && checkCooperative && getListCompany();
  }, [checkClient, checkCooperative]);

  const getCompanyDetail = async (id) => {
    try {
      const response = await CooperativeCompanyService.getCooperativeCompany(
        id
      );
      if (response !== null && response !== undefined) {
        console.log("company detail: ", response);
        let start = new Date(
          response.cooperative_company.company.date_of_establishment
        );
        setStartDate(start);
        setDefaultValue({
          name: response.cooperative_company.company?.name,
          email: response.cooperative_company.company?.email,
          business_content:
            response.cooperative_company.company?.business_content,
          fax: response.cooperative_company.company?.fax,
          zipcode: response.cooperative_company.company?.zipcode,
          capital: response.cooperative_company.company?.capital,
          address: response.cooperative_company.company?.address,
          phone: response.cooperative_company.company?.phone,
          number_of_employees:
            response.cooperative_company.company?.number_of_employees,
          date_of_establishment: new Date(
            response.cooperative_company.company?.date_of_establishment
          ),
          note: response.cooperative_company.company?.note,
          parent_company_id: {
            value: response.cooperative_company?.company?.parent_company?.id,
            label: response.cooperative_company?.company?.parent_company?.name,
            name: response.cooperative_company?.company?.parent_company?.name,
          },
        });

        if (response.cooperative_company?.company?.is_client_company) {
          setCheckClient(true);
        } else {
          setCheckClient(false);
        }

        if (response.cooperative_company?.company?.is_cooperative_company) {
          setCheckCooperative(true);
        } else {
          setCheckCooperative(false);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (checkClient) {
      setValue("is_client_company", true);
    } else {
      setValue("is_client_company", false);
    }
  }, [checkClient]);

  useEffect(() => {
    id !== null && id !== undefined && getCompanyDetail(id);
  }, [id]);

  useEffect(() => {
    // console.log(defaultValue)
    reset(defaultValue);
  }, [defaultValue]);

  const onUpdate = (data) => {
    return new Promise(async (resolve, reject) => {
      await CooperativeCompanyService.updateCooperativeCompany(data, id)
        .then(() => {
          navigate("/cooperative-companies");
        })
        .catch((err) => {
          const response = err?.response;
          if (!response || typeof response === "undefined") {
            // window.location.href = '/login';
            reject(err);
          }
          reject(err);
        });
      resolve(true);
    });
  };
  return (
    <form
      onSubmit={handleSubmit(onUpdate)}
      className="container-company-create-detail d-flex flex-row"
    >
      <div className="company-create-content d-flex flex-column">
        <div
          className="worker-create-title d-flex flex-column"
          style={{ alignItems: "flex-start" }}
        >
          編集
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>会社名</p>
          <input
            autoComplete="off"
            type="text"
            // required
            className="create-company-input"
            {...register("name", createClientCompanyValidation(t).name())}
          ></input>
          {errors.name && <span className="error">{errors.name.message}</span>}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p style={{ marginTop: 0 }}>親会社</p>
          <Controller
            name="parent_company_id"
            control={control}
            render={({ field: { value } }) => (
              // <Dropdown
              // className='dropdown-create-user1 create-company-input'
              // controlClassName='dropdown-control-user'
              // options={companyOption}
              // {...register('parent_company_id', createClientCompanyValidation(t).parent_company_id())}
              // {...field}
              // placeholder="&#xf002;"/>
              <Autocomplete
                disablePortal
                value={{
                  label: value?.label || "",
                  value: value?.value || "",
                }}
                options={companyOption.map((item) => ({
                  label: item.label,
                  value: String(item.value),
                }))}
                getOptionLabel={(option) => option.label || option.value}
                onChange={(event, newValue) => {
                  setValue("parent_company_id", newValue);
                }}
                classes={{
                  root: "cooperative-company-create-search-select-style",
                  input: "cooperative-company-create-search-select-style",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    className="autocomplete-custom-textfield"
                  />
                )}
                noOptionsText="該当なし"
              />
            )}
          />
          {errors.parent_company && (
            <span className="error">{errors.parent_company.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>会社属性</p>
          <div className="checkbox checkbox-create-company d-flex flex-row">
            <Checkbox
              styles={checkClient ? checkBoxStyles : checkBoxStyles2}
              checked={checkClient}
              onChange={() => setCheckClient(!checkClient)}
            />
            <div className="checkbox-text ">顧客会社</div>
          </div>

          <div
            className="checkbox checkbox-create-company d-flex flex-row"
            style={{ marginTop: "8px" }}
          >
            <Checkbox
              styles={checkCooperative ? checkBoxStyles : checkBoxStyles2}
              checked={checkCooperative}
              // onChange={() => setCheckCooperative(!checkCooperative)}
            />
            <div className="checkbox-text">協力会社</div>
          </div>
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>email</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("email", createClientCompanyValidation(t).email())}
          ></input>
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>電話番号</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("phone", createClientCompanyValidation(t).phone())}
          ></input>
          {errors.phone && (
            <span className="error">{errors.phone.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>FAX</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("fax", createClientCompanyValidation(t).fax())}
          ></input>
          {errors.fax && <span className="error">{errors.fax.message}</span>}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>郵便番号</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("zipcode", createClientCompanyValidation(t).zipcode())}
          ></input>
          {errors.zipcode && (
            <span className="error">{errors.zipcode.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>住所</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("address", createClientCompanyValidation(t).address())}
          ></input>
          {errors.address && (
            <span className="error">{errors.address.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>設立日</p>
          <div
            className="input-date create-company-input"
            style={{ position: "relative" }}
          >
            <Controller
              name="date_of_establishment"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={startDate}
                  {...register(
                    "date_of_establishment",
                    createClientCompanyValidation(t).date_of_establishment()
                  )}
                  {...field}
                  onChange={(date) => {
                    field?.onChange(date);
                    handleStartAtChange(date);
                  }}
                  dateFormat="yyyy/MM/dd"
                  autoComplete="off"
                  locale="ja"
                />
              )}
            />
            <img
              className="input-date-img"
              width={"16px"}
              height={"16px"}
              src={calendarIcon}
            ></img>
          </div>
          {errors.date_of_establishment && (
            <span className="error">
              {errors.date_of_establishment.message}
            </span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>資本金</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="text"
            // required
            {...register("capital", createClientCompanyValidation(t).capital())}
          ></input>
          {errors.capital && (
            <span className="error">{errors.capital.message}</span>
          )}
        </div>
        <div className="input-add-user margin-input d-flex flex-column">
          <p>従業員数</p>
          <input
            className="create-company-input"
            autoComplete="off"
            type="number"
            // required
            {...register(
              "number_of_employees",
              createClientCompanyValidation(t).number_of_employees()
            )}
          ></input>
          {errors.number_of_employees && (
            <span className="error">{errors.number_of_employees.message}</span>
          )}
        </div>

        <div className="input-add-user d-flex flex-column">
          <p style={{ marginTop: 0 }}>事業内容</p>
          <textarea
            autoComplete="off"
            // required
            className="dropdown-create-user2"
            {...register(
              "business_content",
              createClientCompanyValidation(t).business_content()
            )}
          ></textarea>
          {errors.business_content && (
            <span className="error">{errors.business_content.message}</span>
          )}
        </div>
        <div
          className="input-add-user d-flex flex-column"
          style={{ marginTop: "8px" }}
        >
          <p style={{ marginTop: 0 }}>備考</p>
          <textarea
            autoComplete="off"
            // required
            className="dropdown-create-user2"
            {...register("note")}
          ></textarea>
        </div>
        <div
          className="modal-create-user-buttons create-button-company"
          style={{ marginTop: "14px" }}
        >
          <button
            className="modal-create-user-button1"
            onClick={() => navigate("/cooperative-companies")}
          >
            戻る
          </button>
          <button
            className="modal-create-user-button2"
            style={{ marginLeft: "5px" }}
            // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
            // onClick={handleCreateUser}
            type="submit"
            disabled={!isAllow}
          >
            登録
          </button>
        </div>
      </div>
    </form>
  );
};
const checkBoxStyles = {
  checkmark: {
    background: "#3DDD74",
    color: "white",
  },
  checkbox: {
    background: "#3DDD74",
    borderColor: "#3DDD74",
    width: "15px",
    margin: "5px 15px 0 0",
    height: "15px",
  },
  // text: {
  //   fontWeight: "bold",
  //   color:'brown'
  // }
};

const checkBoxStyles2 = {
  checkmark: {
    background: "#3DDD74",
    color: "white",
  },
  checkbox: {
    background: "#FFF",
    borderColor: "#CDD1D5",
    width: "15px",
    margin: "5px 15px 0 0",
    height: "15px",
  },
};
export default CooperativeCompanyEdit;
