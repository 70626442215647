import styled from 'styled-components';

export const Main = styled.div`
    background-color: #e3e6e8;
    padding: 28px;
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    .chat-detail-content {
        background: #fff;
        border-radius: 12px;
        min-height: calc(100% - 56px);
        padding: 28px;
        width: 100%;
    }

    .form-chat-detail {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #cdd1d5;
        border-radius: 8px;
        border-left: none;
    }

    .input-chat {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        background: #c7dcf5;

        .search-select-style {
            max-width: 350px;
            max-height: 36px;
            width: 100% !important;
            .MuiOutlinedInput-root {
                height: 36px !important;
            }

            fieldset {
                height: 43px !important;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            .content-right {
                flex-direction: column;
                align-items: flex-start;

                .error {
                    margin-top: 10px;
                }
            }
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .content-right {
                border-top-right-radius: 8px;
            }
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .content-right {
                border-bottom-right-radius: 8px;
            }

            .content-right {
                border-bottom: none;
                position: relative;
            }
        }

        .input-text {
            background: #fff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
            max-width: 350px;
            width: 100%;
            padding: 10px 12px;
            
        }

        .modal-dropdown-chat {
            width: 350px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            height: 36px;
            background: #ffffff;
        }

        .dropdown-control-chat {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            height: 38px;
            background: #ffffff;
            border: 1px solid #cdd1d5 !important;
            border-radius: 8px !important;
        }

        p {
            width: 200px;
            min-height: 60px;
            margin-bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            min-width: 100px;
            padding-left: 12px;
            align-items: center;
        }

        .content-right {
            width: 100%;
            align-items: center;
            padding: 10px 0;
            padding-left: 12px;
            padding-right: 12px;
            min-height: 60px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #1c1e21;
            border-bottom: 1px solid #f5f5f5;
            background: #ffffff;

            .multi-select {
                max-width: 350px;
                width: 100%;
                .MuiAutocomplete-clearIndicator {
                    margin-right: -15px;
                    display: none;
                }

                .MuiInputBase-root {
                    padding: 0;
                    border-radius: 8px;
                    margin-right: 30px;
                }

                .MuiButtonBase-root.MuiChip-root {
                    height: 30px;

                    &:last-child() {
                        margin-right: 10px;
                    }
                }
            }
        }

        .tag {
            padding: 0.4rem 0.8rem;
            background: #f3f3f3;
            border-radius: 10px;
            margin-right: 1rem;
        }

        .tag span {
            text-align: center;
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background: #ff5045;
            border-radius: 5px;
            margin-left: 0.5rem;
            color: #fff;
            line-height: 1.3rem;
        }

        .add-btn {
            padding: 0.4rem 0.8rem;
            border-radius: 5px;
            margin-left: 1rem;
            background: #c7dcf5;
        }
    }

    .group-buttons {
        width: 100%;
        height: 20%;
        align-items: center;
        margin-top: 20px;
    }

    .bnt.Mui-disabled {
        background: rgb(0 0 0 / 13%) !important;
    }

    .bnt {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #215493;
        background: #ffffff;
        width: 167.5px;
        align-items: center;
        justify-content: center;
        height: 44px;
        margin-right: 8px;
        border-radius: 8px;
        &:last-child {
            margin-right: 0px;
        }
    }

    .bnt-cancel {
        border: 1px solid rgba(33, 84, 147, 0.3);
    }

    .bnt-submit {
        background: #215493 !important;
        color: #ffffff !important;
    }

    .chat-box {
        position: relative;
        margin-top: 20px;
        font-family: 'Zen Kaku Gothic Antique';

        /* display: flex;
            flex-direction: column;
            width: 100%; */

        .item {
            display: flex;
            flex-direction: column;
            max-width: 350px;
            text-align: left;

            &.item-left {
                margin-right: auto;
                margin-left: 0;

                .message {
                    background: #f3f3f3;
                }

                .message-footer {
                    justify-content: flex-start;
                    display: flex;
                }
            }

            &.item-right {
                margin-right: 0;
                margin-left: auto;

                .message {
                    background: #c7dcf5;
                }

                .message-footer {
                    justify-content: flex-end;
                    display: flex;
                }
            }

            .message-footer {
                width: 100%;

                p {
                    font-weight: 500;
                    font-size: 15px;
                    font-family: 'Zen Kaku Gothic Antique';

                    &:nth-child(1) {
                        margin-right: 5px;
                    }
                }
            }

            .message {
                border-radius: 10px;
                font-weight: 500;
                font-size: 15px;
                padding: 8px 13px 10px 13px;
                font-family: 'Zen Kaku Gothic Antique';
                display: flex;
                flex-direction: column;
                margin-left: auto;
            }
        }

        .emoji-mart {
            width: 40% !important;
        }

        .rcw-messages-container {
            border-radius: 10px 10px 0 0;
            padding: 20px;
        }

        .rcw-widget-container {
            display: block !important;
            margin: 0;
            position: relative;
        }

        .rcw-message > .rcw-response {
            border-radius: 10px;
        }

        .rcw-message-text {
            max-width: 600px;
            padding: 8px 13px 10px 13px;
            text-align: left;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .rcw-picker-btn,
        .rcw-new-message {
            margin-right: 20px;
        }

        .rcw-sender {
            align-items: center;
        }

        .rcw-launcher {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #215493;
        }

        .rcw-conversation-container > .rcw-header {
            background-color: #215493;
            display: none;
        }

        .rcw-launcher {
            display: none;
        }

        .rcw-message {
            flex-direction: column;

            &.rcw-message-client {
                display: none;
            }

            & > .rcw-response {
                background-color: #c7dcf5;
                color: white;
            }
        }
    }
    @media only screen and (max-width: 992px) {
        background-color: #fff;
        padding: 10px;
        .chat-detail-content {
            padding: 0px;
        }
        .input-chat {
            p {
                width: 100px;
            }
        }
        .group-buttons {
            justify-content: center;
        }
    }
`;
