import { isFloat } from 'utils/helper/numberHelper';
import { NumberInputWrapper } from './style';

interface IProps {
  name: string;
  formik: any;
  disabled: boolean;
  max?: number;
}

const NumberInputField: React.FC<IProps> = ({ name, formik, disabled , max }) => {
    const validateInputValue = (value: string) => {
      let numValue = +value;
      
      if (numValue < 0) {
        numValue = Math.abs(numValue);
      }
      
      if (isFloat(numValue)) {
        numValue = Math.round(numValue);
      }

      if (numValue === 0) {
        numValue = 1;
      }

      if (max && numValue > max) {
        numValue = max;
      }

      return numValue;
    }

    return (
        <NumberInputWrapper>
            <input
              name={name}
              autoComplete="off"
              type="number"
              min={1}
              max={max}
              value={formik.values.max_duplicate}
              onChange={(evt) => {
                const validatedValue = validateInputValue(evt.target.value);
                formik.setFieldValue(name, validatedValue);
              }}
              disabled={disabled}
            ></input>
            {/* {get(touched, name) && !!get(errors, name) && (
                <p className="datePickerErrorText">{get(errors, name)}</p>
            )} */}
        </NumberInputWrapper>
    );
};

export default NumberInputField;
