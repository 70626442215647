export const validate = (values) => {
    const errors: any = {};

    if (!values.worker_id) {
        errors.worker_id = '必須です。';
    }

    if (!values.title || values.title.trim().length === 0) {
        errors.title = '必須です。';
    }

    //if (!values.start_date) {
    //    errors.start_date = '必須です。';
    //}

    //if (!values.end_date) {
    //    errors.end_date = '必須です。';
    //}

    //const timeStart = new Date(
    //    `${values.start_date}`,
    //).getTime();

    //const timeEnd = new Date(
    //    `${values.end_date}`,
    //).getTime();

    //if (timeStart > timeEnd) {
    //    errors.start_date = '終了は開始日時以降を入力してください。';
    //}
    return errors;
};
