import { useState, useRef } from "react";
import "./adsCreate.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropdown/style.css";
import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../../assets/icons/calendar.png";
import adsService from "../../../services/adsService";
import { BiImageAdd } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";
import { isImageUpload } from "utils/helper/checkImage";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "utils/validation/registers/ads/createAds";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

const AdsCreate = (props) => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema(t)),
    });
    const datepickerRef = useRef<DatePicker>(null)

    const handleStartAtChange = (date: Date) => {
        setStartDate(date);
    };

    const handleDeleteFile = (name: string, id: number) => {
        if (files) {
            let current = [...files];
            current = current.filter(
                (file: File, index) => index !== id || file.name !== name
            );
            setFiles(current);
        }
    };

    const onCreate = (data: { name: string; start_date: Date; message: string | undefined }) => {
        let formData = new FormData();
        const filesArr = files ? [...files] : [];
        formData.append("name", data.name);
        formData.append("start_date", data.start_date.toString());
        if(data.message) {
            formData.append("message", data.message);
        }

        filesArr.forEach((file, i) => {
            formData.append(`files[]`, file);
        });
        return new Promise(async (resolve, reject) => {
            await adsService
                .createAds(formData)
                .then((res) => {
                    navigate("/ads");
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === "undefined") {
                        reject(err);
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    const renderFileList = () => (
        <div className="image-list">
            {files &&
                [...files].map((item: File, idx) => {
                    let type = item.type.split("/");
                    return (
                        <div className="img-container-edit" key={idx}>
                            {isImageUpload(type) ? (
                                <img
                                    src={URL.createObjectURL(item)}
                                    className="image-ads-edit"
                                ></img>
                            ) : (
                                <video
                                    className="img-container-edit"
                                    width="300px"
                                    height="200px"
                                    controls
                                >
                                    <source
                                        src={URL.createObjectURL(item)}
                                        type="video/mp4"
                                        className="image-ads-edit"
                                    />
                                </video>
                            )}
                            <GiCancel
                                className="delete-file"
                                onClick={() => handleDeleteFile(item.name, idx)}
                            />
                        </div>
                    );
                })}
        </div>
    );

    const handleClickDatepickerIcon = () => {
        const datepickerElement = datepickerRef.current;
        if(!datepickerElement) return;
        datepickerElement.setFocus(true);
    }
    
    return (
        <form
            onSubmit={handleSubmit(onCreate)}
            className="container-company-create-detail d-flex flex-row"
        >
            <div className="company-create-content d-flex flex-column">
                <div
                    className="worker-create-title d-flex flex-column"
                    style={{ alignItems: "flex-start" }}
                >
                    ダッシュボード 新規作成
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>タイトル</p>
                    <input
                        autoComplete="off"
                        type="text"
                        // required
                        className="create-company-input"
                        {...register("name")}
                    ></input>
                    {errors.name && (
                        <span className="error">{errors.name.message}</span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>メッセージ</p>
                    <textarea
                        autoComplete="off"
                        // required
                        className="create-company-input"
                        {...register("message")}
                    ></textarea>
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>開始日</p>
                    <div
                        className="input-date create-company-input"
                        style={{ position: "relative" }}
                    >
                        <Controller
                            name="start_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    selected={startDate}
                                    {...register("start_date")}
                                    {...field}
                                    onChange={(date: Date) => {
                                        field?.onChange(date);
                                        handleStartAtChange(date);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    ref={datepickerRef}
                                />
                            )}
                        />
                        <img
                            className="input-date-img"
                            width={"16px"}
                            height={"16px"}
                            src={calendarIcon}
                            onClick={() => handleClickDatepickerIcon()}
                        ></img>
                    </div>
                    {errors.start_date && (
                        <span className="error">
                            {errors.start_date.message}
                        </span>
                    )}

                    <div className="input-add-user margin-input d-flex flex-column multi-file-select">
                        <p>アップロード</p>
                        <>
                            <input
                                // {...register("files")}
                                type="file"
                                accept="image/*, video/*"
                                multiple
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                    e.target.files &&
                                    setFiles(files.concat([...e.target.files]))
                                }
                                className="create-company-input"
                                id="files-upload"
                                style={{ display: "none" }}
                            />
                            <label
                                htmlFor="files-upload"
                                className="btn-upload"
                            >
                                <BiImageAdd className="add-file" /> アップロード
                            </label>
                            {renderFileList()}
                        </>
                    </div>
                    <div
                        className="modal-create-user-buttons create-button-company"
                        style={{ marginTop: "14px" }}
                    >
                        <button
                            className="modal-create-user-button1"
                            onClick={() => navigate("/ads")}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2"
                            style={{ marginLeft: "5px" }}
                            type="submit"
                        >
                            登録
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AdsCreate;
