import Swal from 'sweetalert2';

/*
 * alert
 *
 */
export const alertError = (message: string): void => {
    Swal.fire({
        title: 'エラー',
        text: message,
        icon: 'error',
        confirmButtonText: 'キャンセル',
        confirmButtonColor: '#215493',
    });
};

export const textTrim = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    let slicedText;
    slicedText = text.slice(0, maxLength) + "...";
    return slicedText;
  } else {
    return text;
  }
}