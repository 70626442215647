import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { get } from 'lodash';
import { SmallDatePickerWrapper } from './style';
import React from 'react';

interface IProps {
  name: string;
  locale: string;
  formik: any;
  placeholder: string;
  disabled: boolean;
  minDate?: number;
  maxDate?: Date;
  onChangeDuplicateEndDate: (date: Date) => void;
}

const SmallDatePickerField: React.FC<IProps> = ({ name, locale = 'ja', formik, placeholder, disabled, minDate, maxDate, onChangeDuplicateEndDate }) => {
    const { errors, touched } = formik;

    return (
        <SmallDatePickerWrapper>
            <div
                className={classNames(
                    get(touched, name) && !!get(errors, name) ? 'error' : '',
                    'innerItem',
                )}
            >
                <DatePicker
                    locale={locale}
                    placeholder={placeholder}
                    dateFormat="yyyy/MM/dd"
                    value={
                      formik.values[name]
                        ? new Date(formik.values[name])
                        : null
                    }
                    selected={
                      formik.values[name]
                        ? new Date(formik.values[name])
                        : null
                    }
                    disabled={disabled}
                    onChange={onChangeDuplicateEndDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    portalId="html"
                    showYearDropdown
                />
            </div>
            {get(touched, name) && !!get(errors, name) && (
                <p className="datePickerErrorText">{get(errors, name)}</p>
            )}
        </SmallDatePickerWrapper>
    );
};

export default SmallDatePickerField;
