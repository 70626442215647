import moment from 'moment';
import get from 'lodash/get';
import { formatMoney } from 'utils/helper/helpers';

export const STATUS = {
    not_solved: '相談中',
};

export const columns = (isManager: any) => {
    return [
        {
            headerName: '作成日',
            field: 'createdAt',
            maxWidth: 130,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{moment(e.createdAt).format('YYYY/MM/DD')}</div>
            ),
        },
        isManager && {
            headerName: '名前',
            field: 'to_worker.name',
            maxWidth: 200,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'タイトル',
            field: 'title',
            maxWidth: 200,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: '会話',
            field: 'last_message',
            maxWidth: 300,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellStyle: {
                display: '-webkit-box',
                'line-height': '35px',
                'white-space': 'normal',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'horizontal',
                '-webkit-box-align': 'center',
                'max-width': '100%',
            },
        },
        {
            headerName: '貸与金額',
            field: 'amount',
            maxWidth: 260,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{e.value && '¥' + formatMoney(String(e.value || 0))}</div>
            ),
        },
        {
            headerName: '返済金額',
            field: 'repayment_amount',
            maxWidth: 260,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{e.value && '¥' + formatMoney(String(e.value || 0))}</div>
            ),
        },
        {
            headerName: '状態',
            field: 'status',
            maxWidth: 260,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => <div>{get(STATUS, e.value)}</div>,
        },
    ];
};
