import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import ChatsList from './ChatsList';
import plus from '../../assets/icons/Plus.png';
import { Main } from './style';

const Chats: FC = () => {
    const [queryFilter, setQueryFilter] = useState<any>({});
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            free_word: null,
        },
        onSubmit: (values: any) => {
            setQueryFilter(values);
        },
    });

    return (
        <Main>
            <div className="suggestions-content d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row searchRow">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="search-box"
                        >
                            <div className="search-box">
                                <input
                                    className="height36 search-icon input-search"
                                    autoComplete="off"
                                    id="input-search"
                                    type="text"
                                    name="free_word"
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'free_word',
                                            e.target.value,
                                        );
                                    }}
                                    placeholder="&#xf002;"
                                />
                                <button
                                    className="search-user-button d-flex flex-column height36"
                                    type="submit"
                                >
                                    検索
                                </button>
                            </div>
                        </form>

                        <div className="actionArea">
                            <Link
                                className="button-create"
                                to={`/chats/create`}
                            >
                                <img
                                    width={'16px'}
                                    height={'16px'}
                                    src={plus}
                                />
                                新規作成
                            </Link>
                        </div>
                    </div>

                    <ChatsList queryFilter={queryFilter} />
                </div>
            </div>
        </Main>
    );
};

export default Chats;
