import { useState, useEffect } from "react";
import "./createPlan.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../../../../assets/icons/calendar.png";
import plus from "../../../assets/icons/Plus.png";
import { Controller, useForm } from "react-hook-form";
import SelectSearch from "react-select-search";
import { useTranslation } from "react-i18next";
import { convertClientCompaniesToDropdownOptions } from "utils/helper/clientCompanyHelper";
import { convertObjectToDropdownOptions } from "utils/helper/dropdownHelper";
import workersService from "services/wokersService";
import { convertErrorMessagesToObject } from "utils/validation/validatorHelper";
import isEmpty from "lodash/isEmpty";
import plansService from "services/plansService";
import createPlanValidation from "utils/validation/registers/plans/createPlanValidation";
import { Grid } from "@mui/material";
import { convertUserConfigToDropdownOptions } from "utils/helper/userConfigHelper";
import userService from "services/userService";
import { handleFilter } from "utils/helper/filterDropdown";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

const PlanCreate = (props) => {
  const { onCancel, onClose, project_id } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateActual, setStartDateActual] = useState(null);
  const [endDateActual, setEndDateActual] = useState(null);

  // Configurations
  const [statuses, setStatuses] = useState<any>([]);

  const [listWorkers, setListWorkers] = useState<any>([]);

  const getListConfiguration = async () => {
    try {
      const response = await userService.getListConfiguration();
      if (response !== null && response !== undefined) {
        setStatuses(
          convertUserConfigToDropdownOptions(
            response.configurations?.plan?.status_types
          )
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListWorkers = async () => {
    try {
      const response = await workersService.getListWorkers({ per_page: 999 });
      if (response !== null && response !== undefined) {
        setListWorkers(convertObjectToDropdownOptions(response.workers));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getListWorkers();
    getListConfiguration();
    reset({
      status: {
        label: "未着手",
        value: "not_start_yet",
      },
      progress_percentage: 0,
      assigned_worker_id: "",
    });
  }, []);

  const onCreate = (data) => {
    return new Promise(async (resolve, reject) => {
      data.project_id = project_id;
      await plansService
        .createPlan(data)
        .then((data) => {
          onClose();
        })
        .catch((err) => {
          const response = err?.response;
          if (!response || typeof response === "undefined") {
            window.location.href = "/login";
            reject(err);
          }
          switch (response.status) {
            case 403:
              const messages = convertErrorMessagesToObject(
                {
                  name: "name",
                  start_at: "start_at",
                  start_at_actual: "start_at_actual",
                  end_at: "end_at",
                  end_at_actual: "end_at_actual",
                  assigned_worker_id: "assigned_worker_id",
                  note: "note",
                  status: "status",
                },
                response.data?.error
              );

              if (!isEmpty(messages)) {
                Object.keys(messages).map((key: any) =>
                  setError(key, { message: messages[key] })
                );
              } else {
                setError("name", {
                  message: response.data?.error,
                });
              }
              break;
            default:
          }
          reject(err);
        });
      resolve(true);
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onCreate)}
      className="modal-create-plan d-flex flex-column"
    >
      <div className="modal-create-plan-title d-flex flex-row">
        工程新規作成
      </div>
      <div className="modal-create-plan-form d-flex flex-column">
        <div className="input-add-plan d-flex flex-column">
          <p>工程名</p>
          <input
            autoComplete="off"
            type="name"
            {...register("name", { required: "必須です。" })}
          />
          {errors.name && <span className="error">{errors.name.message}</span>}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>期間</p>
          <div className="datepicker-wrap">
            <div className="target-datepicker-wrap d-flex flex-row">
              <div className="input-date" style={{ position: "relative" }}>
                <Controller
                  name="start_at"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={startDate}
                      {...register("start_at", { required: "必須です。" })}
                      {...field}
                      onChange={(date) => {
                        field?.onChange(date);
                        setStartDate(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsStart
                      maxDate={endDate}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      locale="ja"
                    />
                  )}
                />
                <img
                  className="input-date-img"
                  width={"16px"}
                  height={"16px"}
                  src={calendarIcon}
                ></img>
              </div>
              <span className="symbol">~</span>
              <div className="input-date" style={{ position: "relative" }}>
                <Controller
                  name="end_at"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={endDate}
                      {...register("end_at", { required: "必須です。" })}
                      {...field}
                      onChange={(date) => {
                        field?.onChange(date);
                        setEndDate(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsEnd
                      // required={true}
                      minDate={startDate}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      locale="ja"
                    />
                  )}
                />
                <img
                  className="input-date-img"
                  width={"16px"}
                  height={"16px"}
                  src={calendarIcon}
                ></img>
              </div>
            </div>
          </div>
          {(errors.start_at || errors.end_at) && (
            <span className="error">
              {errors.start_at?.message || errors.end_at?.message}
            </span>
          )}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>期間実績</p>
          <div className="datepicker-wrap">
            <div className="target-datepicker-wrap d-flex flex-row">
              <div className="input-date" style={{ position: "relative" }}>
                <Controller
                  name="start_at_actual"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={startDateActual}
                      {...register("start_at_actual")}
                      {...field}
                      onChange={(date) => {
                        field?.onChange(date);
                        setStartDateActual(date);
                      }}
                      startDate={startDateActual}
                      endDate={endDateActual}
                      selectsStart
                      maxDate={endDateActual}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      locale="ja"
                    />
                  )}
                />
                <img
                  className="input-date-img"
                  width={"16px"}
                  height={"16px"}
                  src={calendarIcon}
                ></img>
              </div>
              <span className="symbol">~</span>
              <div className="input-date" style={{ position: "relative" }}>
                <Controller
                  name="end_at_actual"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={endDateActual}
                      {...register("end_at_actual")}
                      {...field}
                      onChange={(date) => {
                        field?.onChange(date);
                        setEndDateActual(date);
                      }}
                      startDate={startDateActual}
                      endDate={endDateActual}
                      selectsEnd
                      // required={true}
                      minDate={startDateActual}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      locale="ja"
                    />
                  )}
                />
                <img
                  className="input-date-img"
                  width={"16px"}
                  height={"16px"}
                  src={calendarIcon}
                ></img>
              </div>
            </div>
          </div>
          {/* {(errors.start_at_actual || errors.end_at_actual) && <span className="error">{errors.start_at_actual?.message || errors.end_at_actual?.message}</span>} */}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>担当</p>
          <Controller
            name="assigned_worker_id"
            control={control}
            render={({ field }) => (
              <SelectSearch
                options={listWorkers}
                search
                filterOptions={handleFilter}
                {...register("assigned_worker_id", { required: "必須です。" })}
                {...field}
                placeholder=""
              />
            )}
          />
          {errors.assigned_worker_id && (
            <span className="error">{errors.assigned_worker_id.message}</span>
          )}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>状態</p>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Dropdown
                className="modal-dropdown-create-plan1 height36"
                controlClassName="dropdown-control-plan height36"
                options={statuses}
                {...register("status", createPlanValidation(t).status())}
                {...field}
                placeholder=""
              />
            )}
          />
          {errors.status && (
            <span className="error">{errors.status.message}</span>
          )}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>進捗</p>
          <input
            autoComplete="off"
            type="number"
            max="100"
            min="0"
            {...register(
              "progress_percentage",
              createPlanValidation(t).progress_percentage()
            )}
          />
          {errors.progress_percentage && (
            <span className="error">{errors.progress_percentage.message}</span>
          )}
        </div>
        <div className="input-add-plan d-flex flex-column">
          <p>備考</p>
          <textarea
            autoComplete="off"
            className="dropdown-create-plan2"
            {...register("note")}
          ></textarea>
          {errors.note && <span className="error">{errors.note.message}</span>}
        </div>
      </div>
      <div className="modal-create-plan-buttons d-flex flex-row">
        <button
          className="modal-create-plan-button1 d-flex flex-row"
          onClick={onCancel}
        >
          戻る
        </button>
        <button
          className="modal-create-plan-button2 d-flex flex-row"
          type="submit"
        >
          登録
        </button>
      </div>
    </form>
  );
};

export default PlanCreate;
