import { useState, useEffect, useMemo } from 'react'
import moment from 'moment';
import '../workerCreate/workerCreate.css'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import workersService from "../../../services/wokersService";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import calendarIcon from "../../../assets/icons/calendar.png"
import positionService from "../../../services/positionService";
import departmentsService from "../../../services/departmentsService";
import CooperativeCompanyService from "../../../services/cooperativeCompaniesService";
import isEmpty from "lodash/isEmpty";
import { RootState, AppDispatch, useAppDispatch } from "../../../store/index";
import { useSelector } from "react-redux";
import userService from 'services/userService';
import { Controller, useForm } from 'react-hook-form';
import { convertErrorMessagesToObject } from 'utils/validation/validatorHelper';
import updateWorkerValidation from 'utils/validation/registers/workers/updateWorkerValidation';
import { useTranslation } from 'react-i18next';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import { checkExist, isAllowAction } from 'utils/helper/workerHelper';

const WorkerEdit = (props) => {
    const { t } = useTranslation();
    const { id } = useParams()
    const [currentWorker, setCurrentWorker] = useState<any>(null);

    const { register, control, handleSubmit, watch, reset, setError, setValue, getValues, formState: { errors } } = useForm({
        // defaultValues: useMemo(() => {
        //     return currentWorker;
        // }, [currentWorker])
    })

    const { positionsList } = useSelector((state: RootState) => state.positions);
    const { departmentList } = useSelector((state: RootState) => state.deparments);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<any>(null);

    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAllowAction(userData);

    const [companyOption, setCompanyOption] = useState<any>(null)

    const [positionOption, setPositionOption] = useState<any>([])
    const [departmentOption, setDepartmentOption] = useState<any>([])

    const [listGenderValue, setListGenderValue] = useState<any>(null)

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration()
            if (response !== null && response !== undefined) {
                setListGenderValue(convertUserConfigToDropdownOptions(response.configurations.worker.gender_types))
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const getListDemartment = async () => {
        try {
            const response = await departmentsService.getListDepartments()
            if (response !== null && response !== undefined) {
                // const departmentData = [{ id: -1, name: 'ーーー' }, ...response.departments];
                setDepartmentOption(convertObjectToDropdownOptions(response.departments))
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const getListCompanies = async () => {
        try {
            const response = await CooperativeCompanyService.getListCooperativeCompanies()
            if (response !== null && response !== undefined) {
                setCompanyOption(convertClientCompaniesToDropdownOptionsCreateProject(response.cooperative_companies))
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const getListPositions = async () => {
        try {
            const response = await positionService.getListPositions()
            if (response !== null && response !== undefined) {
                const positionData = [{ id: -1, name: 'ーーー' }, ...response.positions];
                setPositionOption(convertObjectToDropdownOptions(positionData));
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const getDetailWorker = async () => {
        if (!id) return;
        await workersService.getWorker(id)
            .then(response => {
                setCurrentWorker(response.worker)
                if (response.worker?.birthday)
                    setStartDate(new Date(response.worker.birthday))
            })
            .catch(error => {
                console.error(error);
            })
    }

    const handleUpdateWorker = async (data) => {
        if (!currentWorker) return;
        return new Promise(async (resolve, reject) => {
            await workersService.updateWorker(id, data)
                .then(() => {
                    navigate('/workers')
                })
                .catch((err) => {
                    const response = err?.response
                    if (!response || typeof response === 'undefined') {
                        window.location.href = '/login';
                        reject(err)
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    gender_type: 'gender_type',
                                },
                                response.data?.error)

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map(key => setError(key, { message: messages[key] }))
                            } else {
                                setError('name', {
                                    message: response.data?.error
                                });
                            }
                            break;
                        default:
                    }
                    reject(err)

                })
            resolve(true)

        })
    }

    useEffect(() => {
        if (!id) return;
        getDetailWorker()
    }, [id])

    useEffect(() => {
        if (!currentWorker && companyOption === null && listGenderValue === null) return;
        if (currentWorker && companyOption !== null && listGenderValue !== null) {
            reset({
                ...currentWorker,
                cooperative_company_id: companyOption?.find(company =>
                    currentWorker?.cooperative_company?.id === company.value),
                gender_type: listGenderValue?.find(gender => currentWorker?.gender_type === gender.label),
                department_ids: currentWorker?.departments.length > 0 ? currentWorker?.departments.map((v: any) => ({
                    value: v.id,
                    label: v?.name,
                })) : [],
                // department_id: departmentOption?.find(department => +currentWorker?.department?.id === +department.value),
                birthday: currentWorker?.birthday ? moment(currentWorker.birthday).format('YYYY/MM/DD') : null,
                position_id: currentWorker?.position ? {
                    value: currentWorker?.position?.id,
                    label: currentWorker?.position?.name
                } : {
                    value: -1,
                    label: 'ーーー'
                },
            })
        }
    }, [currentWorker, companyOption, listGenderValue])

    useEffect(() => {
        getListCompanies()
        getListConfiguration()
        if (positionsList !== undefined && positionsList.length == 0) getListPositions()
        else {
            setPositionOption(convertObjectToDropdownOptions(positionsList))
        }
        if (departmentList !== undefined && departmentList.length == 0) getListDemartment()
        else {
            setDepartmentOption(convertObjectToDropdownOptions(departmentList))
        }
    }, []);
    return (
        <form onSubmit={handleSubmit(handleUpdateWorker)} className='container-worker-create-detail d-flex flex-row'>
            <div className='worker-content d-flex flex-column items-start'>
                <div className='worker-create-title d-flex flex-column'>{currentWorker?.name} 編集</div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>名前</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        disabled={!isAllow}
                        {...register('name', updateWorkerValidation(t).name())}
                    />
                    {errors.name && <span className="error">{errors.name.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>フリガナ</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('kana', updateWorkerValidation(t).kana())}
                    />
                    {errors.kana && <span className="error">{errors.kana.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p style={{ marginTop: 0 }}>性別</p>
                    <Controller
                        name="gender_type"
                        control={control}
                        render={({ field: { value } }) =>
                            // <Dropdown
                            //     className='dropdown-create-user1 worker-create-input'
                            //     controlClassName='dropdown-control-user'
                            //     options={listGenderValue}
                            //     value={{
                            //       label: value?.label || '',
                            //       value: value?.value || ''
                            //     }}
                            //     {...register('gender_type', 
                            //     // updateWorkerValidation(t).gender_type()
                            //     )}
                            //     onChange={(newValue) => {
                            //       if (value && value.value === newValue.value) {
                            //         setValue('gender_type', undefined)
                            //       } else {
                            //         setValue('gender_type', newValue)
                            //       }
                            //     }}
                            //     placeholder=""
                            // />
                            <Autocomplete 
                            value={{
                                label: value?.label || '',
                                value: value?.value || '',
                            }}
                            options={listGenderValue?.map((item) => ({
                                label: item.label,
                                value: item.value,
                            })) || []}
                            getOptionLabel={(option: any) => option.label || option.value}
                            {...register('gender_type')}
                            onChange={(event, newValue) => {
                                setValue('gender_type', newValue)
                            }}
                            classes={{
                                root: 'worker-create-search-select-style worker-create-input',
                                input: 'worker-create-search-select-style worker-create-input'
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="" className='autocomplete-custom-textfield' />
                            )}
                            noOptionsText="該当なし"
                        />
                        }
                    />
                    {errors.gender_type && <span className="error">{errors.gender_type.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p style={{ marginTop: 0 }}>協力会社</p>
                    <Controller
                        name="cooperative_company_id"
                        // rules={{required: true}}
                        control={control}
                        render={({ field: { value } }) => {
                          return (
                            <Autocomplete 
                              disablePortal
                              value={{
                                label: value?.label || '',
                                value: value?.value || '',
                              }}
                              options={companyOption?.map((item) => ({
                                label: item.label,
                                value: String(item.value)
                              })) || []}
                              getOptionLabel={(option: any) => option.label || option.value}
                              {...register('cooperative_company_id', 
                              // updateWorkerValidation(t).cooperative_company_id()
                              )}
                              onChange={(event, newValue) => {
                                setValue('cooperative_company_id', newValue)
                              }}
                              classes={{
                                root: 'worker-create-search-select-style worker-create-input',
                                input: 'worker-create-search-select-style worker-create-input'
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="" className="autocomplete-custom-textfield" />
                              )}
                              noOptionsText="該当なし"
                            />
                          )
                        }
                      }
                    />
                    {errors.cooperative_company_id && <span className="error">{errors.cooperative_company_id.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p style={{ marginTop: 0 }}>部署</p>
                    <Controller
                        name="department_ids"
                        control={control}
                        render={({ field: { value } }) => {
                            // <Dropdown
                            //     className='dropdown-create-user1 worker-create-input'
                            //     controlClassName='dropdown-control-user'
                            //     options={departmentOption}
                            //     value={{
                            //       label: value?.label || '',
                            //       value: value?.value || ''
                            //     }}
                            //     {...register('department_id', 
                            //     // updateWorkerValidation(t).department_id()
                            //     )}
                            //     onChange={(newValue) => {
                            //         setValue('department_id', newValue)
                            //     }}
                            //     placeholder=""
                            // />
                            return (
                            <Autocomplete
                                multiple
                                disablePortal
                                value={value ? value.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                })) : []}
                                options={checkExist(
                                    departmentOption.map((item) => ({
                                      label: item.label,
                                      value: String(item.value),
                                    })),
                                    value || []
                                )}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option: any) => option.label || option.value}
                                {...register('department_ids')}
                                onChange={(event, newValue) => {
                                    setValue('department_ids', newValue)
                                }}
                                classes={{
                                    root: 'worker-autocomplete-multiple worker-create-input',
                                    input: 'worker-autocomplete-multiple worker-create-input'
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" className='autocomplete-custom-textfield' />
                                )}
                                noOptionsText="該当なし"
                                disabled={!isAllow}
                            />)
                        }}
                    />
                    {errors.department_id && <span className="error">{errors.department_id.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p style={{ marginTop: 0 }}>役職</p>
                    <Controller
                        name="position_id"
                        control={control}
                        render={({ field: { value } }) =>
                            // <Dropdown
                            //     className='dropdown-create-user1 worker-create-input'
                            //     controlClassName='dropdown-control-user'
                            //     options={positionOption}
                            //     value={{
                            //       label: value?.label || '',
                            //       value: value?.value || ''
                            //     }}
                            //     {...register('position_id', 
                            //     // updateWorkerValidation(t).position_id()
                            //     )}
                            //     onChange={(newValue) => {
                            //         setValue('position_id', newValue)
                            //     }}
                            //     placeholder=""
                            // />
                            <Autocomplete 
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                options={positionOption.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                }))}
                                getOptionLabel={(option: any) => option.label || option.value}
                                {...register('position_id')}
                                onChange={(event, newValue) => {
                                    setValue('position_id', newValue)
                                }}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onBlur={(event: any) => {
                                    if (!event.target?.value || event.target?.value === "") {
                                        setValue('position_id', {
                                            value: -1,
                                            label: 'ーーー'
                                        })
                                    }
                                }}
                                classes={{
                                    root: 'worker-create-search-select-style worker-create-input',
                                    input: 'worker-create-search-select-style worker-create-input'
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" className='autocomplete-custom-textfield' />
                                )}
                                noOptionsText="該当なし"
                                disabled={!isAllow}
                            />
                        }
                    />
                    {errors.position_id && <span className="error">{errors.position_id.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>携帯電話</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('celphone', updateWorkerValidation(t).celphone())}
                    />
                    {errors.celphone && <span className="error">{errors.celphone.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>FAX</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('fax',
                        //  updateWorkerValidation(t).fax()
                         )}
                    />
                    {errors.fax && <span className="error">{errors.fax.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>email</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        disabled={!isAllow}
                        {...register('email', updateWorkerValidation(t).email())}
                    />
                    {errors.email && <span className="error">{errors.email.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>電話番号</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('phone', updateWorkerValidation(t).phone())}
                    />
                    {errors.phone && <span className="error">{errors.phone.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>郵便番号</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('zipcode', updateWorkerValidation(t).zipcode())}
                    />
                    {errors.zipcode && <span className="error">{errors.zipcode.message}</span>}
                </div>
                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>住所</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('address')}
                    />
                    {errors.address && <span className="error">{errors.address.message}</span>}
                </div>

                <div className='input-add-user margin-input d-flex flex-column'>
                    <p>誕生日</p>
                    <div className='input-date' style={{ position: "relative", maxWidth: '343px' }}>
                        <Controller
                            name="birthday"
                            control={control}
                            render={({ field }) =>
                                <DatePicker
                                    className="worker-create-input"
                                    selected={startDate}
                                    {...register('birthday', 
                                    // updateWorkerValidation(t).birthday()
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date)
                                        setStartDate(date)
                                    }}
                                    startDate={startDate}
                                    maxDate={new Date()}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            }
                        />
                        <img className='input-date-img' width={"16px"} height={"16px"} src={calendarIcon}></img>
                    </div>
                    {errors.birthday && <span className="error">{errors.birthday.message}</span>}
                </div>
                <div className='modal-create-user-buttons create-button-worker' style={{ marginTop: "14px" }}>
                    <button className='modal-create-user-button1' onClick={() => navigate('/workers')} type="button">戻る</button>
                    <button className='modal-create-user-button2'
                        // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
                        type='submit'>登録</button>
                </div>
            </div>
        </form>
    );
}

export default WorkerEdit;