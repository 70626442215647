import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    Calendar,
    ClipboardText,
    FolderPlus,
    LightBulb,
    Note,
    NotePad,
    UsersFour,
    User,
    WreckingBusinessManagement,
    CaretUp,
    FileArrowDown,
} from "assets/menuIcons";
import { IoChatbubblesOutline } from "react-icons/io5";
import { FaBuysellads } from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";
import { RootState } from "../../store/index";
import "./sidebar.css";
import { isAllowAction, isUserAdmin, isUserGuest } from "utils/helper/workerHelper";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";

declare let window: any;

const SideBar = (props) => {
    const { setChangeTab, isChangeTab } = props;
    const navigate = useNavigate();

    const { userData } = useSelector((state: RootState) => state.users);

    const isAllow = isAllowAction(userData);
    const isAdmin = isUserAdmin(userData);
    const [hide, setHide] = useState(false);

    const [path, setPath] = useState("");

    const [isShowSupMenu, setShowSupMenu] = useState(false);
    const checkUserGuest = isUserGuest(userData);
    
    useEffect(() => {
        let pathTemp = window.location.pathname;
        setPath(pathTemp);
        if (pathTemp == "/login") {
            setHide(true);
        }
    });

    const menuItemProps = {
        background: "#7A98BE",
        color: "#FFFFFF",
    };

    const svgProps = {
        strokeColor: "#FFFFFF",
    };

    return (
        <div
            className="sidebar"
            style={{
                display: hide ? "none" : "",
            }}
        >
            {!checkUserGuest && 
            <div
                className="header-sidebar flex items-center"
                onClick={() => {
                    navigate("/");
                    setChangeTab(!isChangeTab);
                }}
            >
                <HouseOutlinedIcon />
                <span>ホーム</span>
            </div>
            }
            <div className="menu-list">
                {/* TODO */}
                {/* <p className="menu-item d-flex flex-row">
          <img width={"20px"} height={"18px"} src={iconSearch} />
          案件検索
        </p> */}

                {/* calendar */}
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate("/calendar");
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === "/calendar" && menuItemProps),
                    }}
                >
                    <Calendar {...(path === "/calendar" && svgProps)} />
                    カレンダー
                </p>

                {/* Suggestion */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/suggestions");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...([
              "/suggestions",
              "/suggestions/create",
              "/suggestion/detail",
            ].includes(path) && menuItemProps),
          }}
        >
          <BsMailbox2
            size={20}
            color={
              [
                "/suggestions",
                "/suggestions/create",
                "/suggestion/detail",
              ].includes(path)
                ? "#FFFFFF"
                : "#CDD1D5"
            }
          />
          目安箱
        </p> */}

                {/* Chat Rooms */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/chats");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...(["/chats", "/chats/create", "/chats/detail"].includes(path) &&
              menuItemProps),
          }}
        >
          <IoChatbubblesOutline
            size={20}
            color={
              ["/chats", "/chats/create", "/chats/detail"].includes(path)
                ? "#FFFFFF"
                : "#CDD1D5"
            }
          />
          チャットルーム
        </p> */}

                {/* Create Project */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/projects/create");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...(path === "/projects/create" && menuItemProps),
          }}
        >
          <FolderPlus {...(path === "/projects/create" && svgProps)} />
          案件作成
        </p> */}

                {/* Project list */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/projects");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...(path === "/projects" ||
            (path.startsWith("/projects") && path !== "/projects/create")
              ? menuItemProps
              : {}),
          }}
        >
          <ClipboardText
            {...((path === "/projects" ||
              (path.startsWith("/projects") && path !== "/projects/create")) &&
              svgProps)}
          />
          案件
        </p> */}

                {/* Estimate */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {}}
          style={{
            ...(path === "/estimates" && menuItemProps),
          }}
        >
          <NotePad {...(path === "/estimates" && svgProps)} />
          見積
        </p> */}

                {/* Bills */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/bills");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...(path === "/bills" && menuItemProps),
          }}
        >
          <Note {...(path === "/bills" && svgProps)} />
          請求
        </p> */}
                {/* Estimates */}
                {/* <p
          className="menu-item d-flex flex-row"
          onClick={() => {
            navigate("/estimates");
            setChangeTab(!isChangeTab);
          }}
          style={{
            ...(path === "/estimates" && menuItemProps),
          }}
        >
          <Note {...(path === "/estimates" && svgProps)} />
          見積
        </p> */}

                {/* User */}
            {!checkUserGuest && 
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate("/workers");
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === "/workers" ||
                        path === "/workers/detail" ||
                        path === "/workers/create" ||
                        path === "/workers/edit"
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <UsersFour
                        {...((path === "/workers" ||
                            path === "/workers/detail" ||
                            path === "/workers/create" ||
                            path === "/workers/edit") &&
                            svgProps)}
                    />
                    社員
                </p>
            }
            {!checkUserGuest && 
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate("/ads");
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path.includes("/ads") ? menuItemProps : {}),
                    }}
                >
                    <FaBuysellads
                        size={20}
                        color={path.includes("/ads") ? "#FFFFFF" : "#CDD1D5"}
                    />
                    ダッシュボード
                </p>
            }
                {/* Account */}
                <>
                    {isAdmin && (
                            <p
                                className="menu-item d-flex flex-row"
                                onClick={() => {
                                    navigate("/users");
                                    setChangeTab(!isChangeTab);
                                }}
                                style={{
                                    ...(path === "/users" && menuItemProps),
                                }}
                            >
                                <User {...(path === "/users" && svgProps)} />
                                アカウント
                            </p>
                        )}
                </>

                {/* Management  */}
                {!checkUserGuest && 
                <div
                    className="sub-menu menu-item d-flex flex-row"
                    style={{
                        justifyContent: "space-between",
                    }}
                    onClick={() => setShowSupMenu(!isShowSupMenu)}
                >
                    <p
                        className="d-flex flex-row"
                        style={{
                            gap: 12,
                            ...(isShowSupMenu && { color: "#FFFFFF" }),
                        }}
                    >
                        <WreckingBusinessManagement
                            {...(isShowSupMenu && svgProps)}
                        />
                        マスタ管理
                    </p>
                    <div
                        className={`arrow-icon ${isShowSupMenu ? "open" : ""}`}
                    >
                        <CaretUp {...(isShowSupMenu && svgProps)} />
                    </div>
                </div>
                }
                {isShowSupMenu && (
                    <div
                        className="dropdown-container"
                        style={{ paddingBottom: "6px" }}
                    >
                        <p
                            className="menu-item"
                            onClick={() => {
                                navigate("/companies");
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path.includes("/companies")
                                    ? menuItemProps
                                    : {}),
                            }}
                        >
                            顧客会社
                        </p>
                        <p
                            className="menu-item"
                            onClick={() => {
                                navigate("/cooperative-companies");
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path.includes("/cooperative-companies")
                                    ? menuItemProps
                                    : {}),
                            }}
                        >
                            協力会社
                        </p>
                        <p
                            className="menu-item"
                            onClick={() => {
                                navigate("/news");
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === "/news" ? menuItemProps : {}),
                            }}
                        >
                            お知らせ
                        </p>
                        {/* <p className="menu-item">リスクアセスメント</p> */}
                        <p
                            className="menu-item"
                            onClick={() => {
                                navigate("/departments");
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === "/departments" && menuItemProps),
                            }}
                        >
                            部署
                        </p>
                        <p
                            className="menu-item"
                            onClick={() => {
                                navigate("/positions");
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === "/positions" && menuItemProps),
                            }}
                        >
                            役職
                        </p>
                    </div>
                )}

                {/* Settings */}
                {isAllow && !checkUserGuest && (
                    <p
                        className="menu-item d-flex flex-row"
                        onClick={() => {
                            navigate("/setting");
                            setChangeTab(!isChangeTab);
                        }}
                        style={{
                            ...(path === "/setting" ||
                            path.startsWith("/setting")
                                ? menuItemProps
                                : {}),
                        }}
                    >
                        <FileArrowDown
                            {...((path === "/setting" ||
                                path.startsWith("/setting")) &&
                                svgProps)}
                        />
                        設定
                    </p>
                )}
            </div>
        </div>
    );
};
export default SideBar;
