import { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from '../../common/DialogHeader';
import { StyledTextField } from 'components/StyledTextField';
import CommonStyledSugorkuDialogWrapper from "../../common/commonSugorokuDialogStyle";
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import { SugorokuIndexEnum, DeliveryMethodEnum } from 'utils/enums';
import { useFormik, FormikProps } from "formik";
import { useParams } from "react-router-dom";
import sugorokuService from "services/sugorokuService";
import { formatDateTime } from 'utils/helper/dateHelper';
import { IInvoicingPayload } from "models";

interface IProps {
  step: number;
  onClose?: any;
  data_id?: number;
  workerOptions: any[];
  onFinish: () => void;
}

interface IInvoicingFormikValues {
  from_address?: string | null;
  person_in_charge_worker?: any;
  submitted_at?: Date | null;
  submit_worker?: any;
  date_sent?: Date | null;
  delivery_method?: any;
  sent_by_worker?: any;
  completed_at?: Date | null;
}

const InvoicingModal = (props: IProps) => {
  const { step, onClose, workerOptions, data_id, onFinish } = props;
  const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<any>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hasData = !!data_id;
  const { id } = useParams();

  const [createDate, setCreateDate] = useState(new Date());
  const [sentDate, setSentDate] = useState(new Date());
  const [completionDate, setCompletionDate] = useState(new Date());

  const formik: FormikProps<IInvoicingFormikValues>  = useFormik<IInvoicingFormikValues>({
    initialValues: {
      from_address: "",
      person_in_charge_worker: null,
      submitted_at: null,
      submit_worker: null,
      date_sent: null,
      delivery_method: null,
      sent_by_worker: null,
      completed_at: null,
    },
    onSubmit: (values: IInvoicingFormikValues) => {
      setSubmitLoading(true);
      let data: IInvoicingPayload = pick(values, ['from_address']);
      if (!id) {
        setSubmitLoading(false);
        return;
      }
    
      data.project_id = +id;
      data.sugoroku_index = SugorokuIndexEnum.INVOICING.value;


      if (values.date_sent) {
        data.date_sent = formatDateTime(values.date_sent, "yyyy-MM-DD");
      } else {
        data.date_sent = null;
      }

      if (values.completed_at) {
        data.completed_at = formatDateTime(values.completed_at, "yyyy-MM-DD");
      } else {
        data.completed_at = null;
      }
  
      if (values.submitted_at) {
        data.submitted_at = formatDateTime(values.submitted_at, "yyyy-MM-DD");
      } else {
        data.submitted_at = null;
      }
  
      if (values.submit_worker) {
        data.submit_worker_id = +values.submit_worker.value;
      } else {
        data.submit_worker_id = null;
      }
  
      if (values.sent_by_worker) {
        data.sent_by_worker_id = +values.sent_by_worker.value;
      } else {
        data.sent_by_worker_id = null;
      }
  
      if (values.person_in_charge_worker) {
        data.person_in_charge_worker_id = +values.person_in_charge_worker.value;
      } else {
        data.person_in_charge_worker_id = null;
      }

      if (values.delivery_method !== null && values.delivery_method !== undefined) {
        data.delivery_method = +values.delivery_method.value;
      } else {
        data.delivery_method = null;
      }
      
      if (hasData) {
        updateSugoroku(data);
      } else {
        createSugoroku(data);
      }
    }
  });

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const data = await sugorokuService.getSugorokuEventDetail(data_id, Number(id));
      if (data && data.sugoroku_event) {
        const sugorokuData = data.sugoroku_event;
        
        formik.setValues({
          'submitted_at': sugorokuData.submitted_at 
            ? new Date(sugorokuData.submitted_at) 
            : null,
          'date_sent': sugorokuData.date_sent 
            ? new Date(sugorokuData.date_sent) 
            : null,
          'submit_worker': sugorokuData.submit_worker ? {
            value: String(sugorokuData.submit_worker.id),
            label: sugorokuData.submit_worker.name,
            name: sugorokuData.submit_worker.name,
          } : null,
          'person_in_charge_worker': sugorokuData.person_in_charge_worker ? {
            value: String(sugorokuData.person_in_charge_worker.id),
            label: sugorokuData.person_in_charge_worker.name,
            name: sugorokuData.person_in_charge_worker.name,
          } : null,
          'from_address': sugorokuData.from_address,
          'sent_by_worker': sugorokuData.sent_by_worker ? {
            value: String(sugorokuData.sent_by_worker.id),
            label: sugorokuData.sent_by_worker.name,
            name: sugorokuData.sent_by_worker.name,
          } : null,
          'delivery_method': sugorokuData.delivery_method !== null ? {
            value: String(DeliveryMethodEnum[sugorokuData.delivery_method].value),
            label: sugorokuData.delivery_method,
            name: sugorokuData.delivery_method,
          } : null,
          'completed_at': sugorokuData.completed_at 
            ? new Date(sugorokuData.completed_at)
            : null,
        });
        setIsEmpty(false)
      } else {
        setIsEmpty(true)
      }
    } catch (error) {
      // error
    } finally {
      setFetchLoading(false);
    }
  }

  const createSugoroku = async (data: IInvoicingPayload) => {
    try {
      const responseData = await sugorokuService.createSugorokuEvent(data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // empty
    }
  }
  
  const updateSugoroku = async (data: IInvoicingPayload) => {
    try {
      const responseData = await sugorokuService.updateSugorokuEvent(data_id, data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // epmty
    }
  }

  useEffect(() => {
    if (data_id && id) {
      fetchData();
    }
  }, [])

  return (
    <CommonStyledSugorkuDialogWrapper>
      <DialogHeader 
        step={step}
        title={SugorokuIndexEnum.INVOICING.title}
        onClose={onClose}
        mode={mode}
        setMode={setMode}
        completed={!!formik.values.completed_at}
      />

      {
        fetchLoading && (
          <div className="d-flex justify-center">
            <CircularProgress />
          </div>
        )
      }

      {
        mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
          <>
            <div className="dialog-field-row">
                <div className="field-label">送付先</div>
                <div className="value">{formik.values.from_address}</div>
            </div>
            <div className="dialog-field-row">
                <div className="field-label">担当者</div>
                <div className="value">{formik.values.person_in_charge_worker?.label || ""}</div>
            </div>
            <div className="dialog-field-row">
                <div className="field-label">作成日付</div>
                <div className="value">{formatDateTime(formik.values.submitted_at, 'yyyy-MM-DD')}</div>
                {/* <div className="value">{formik.values.company?.label || ""}</div> */}
            </div>
            <div className="dialog-field-row">
                <div className="field-label">作成者</div>
                <div className="value">{formik.values.submit_worker?.label || ""}</div>
                {/* <div className="value">{formik.values.content}</div> */}
            </div>
            <div className="dialog-field-row">
                <div className="field-label">送付日付</div>
                <div className="value">{formatDateTime(formik.values.date_sent, 'yyyy-MM-DD')}</div>
                {/* <div className="value">{formik.values.assigned_worker?.name || ""}</div> */}
            </div>
            <div className="dialog-field-row">
                <div className="field-label">送付方法</div>
                <div className="value">{formik.values.delivery_method?.name || ""}</div>
            </div>
            <div className="dialog-field-row">
                <div className="field-label">送付者</div>
                <div className="value">{formik.values.sent_by_worker?.name || ""}</div>
            </div>
            <div className="dialog-field-row">
                <div className="field-label">完了日付</div>
                <div className="value">{formatDateTime(formik.values.completed_at, 'yyyy-MM-DD')}</div>
            </div>
          </>
        )
      }

      {
        mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
          <form onSubmit={formik.handleSubmit}>
            <div className="dialog-field-row">
                <div className="field-label">送付先</div>
                <StyledTextField 
                className="textfield-style"
                name="from_address" 
                value={formik.values.from_address} 
                onChange={formik.handleChange}  />
              </div>

              <div className="dialog-field-row">
                  <div className="field-label">担当者</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={workerOptions.map((item) => ({
                          label: item.label,
                          value: String(item.value),
                          name: item.name
                        }))}
                        value={formik.values.person_in_charge_worker}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('person_in_charge_worker', newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
              </div>

              <div className="dialog-field-row">
                  <div className="field-label">作成日付</div>
                  <div className="datepicker-wrapper">
                      <DatePicker
                          selected={formik.values.submitted_at}
                          onChange={(value) => {
                            formik.setFieldValue('submitted_at', value)
                          }}
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                      />
                      <img
                          className="calendar-datepicker-icon"
                          src={calendarIcon}
                      ></img>
                  </div>
              </div>

              <div className="dialog-field-row">
                  <div className="field-label">作成者</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={workerOptions.map((item) => ({
                          label: item.label,
                          value: String(item.value),
                          name: item.name
                        }))}
                        value={formik.values.submit_worker}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('submit_worker', newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">送付日付</div>
                  <div className="datepicker-wrapper">
                      <DatePicker
                          selected={formik.values.date_sent}
                          onChange={(value) => {
                            formik.setFieldValue('date_sent', value)
                          }}
                          placeholder="送付日付"
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                      />
                      <img
                          className="calendar-datepicker-icon"
                          src={calendarIcon}
                      ></img>
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">送付方法</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          {
                            value: "0",
                            label: "email",
                            name: "email",
                          },
                          {
                            value: "1",
                            label: "by_post",
                            name: "by_post",
                          },
                        ]}
                        value={formik.values.delivery_method}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('delivery_method', newValue);
                        }}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">送付者</div>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={workerOptions.map((item) => ({
                          label: item.label,
                          value: String(item.value),
                          name: item.name
                        }))}
                        value={formik.values.sent_by_worker}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('sent_by_worker', newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        classes={{
                            root: 'autocomplete-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="" />
                        )}
                        noOptionsText="該当なし"
                    />
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="datepicker-wrapper">
                    <DatePicker 
                        selected={formik.values.completed_at}
                        onChange={(date) => {
                          formik.setFieldValue('completed_at', date)
                        }}
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        locale="ja"
                    />
                    <img
                        className="calendar-datepicker-icon"
                        src={calendarIcon}
                    ></img>
                  </div>
              </div>

              <div className="action-section">
                  <button
                      type="button"
                      className="back-step-edit-button"
                      onClick={onClose}
                  >
                    キャンセル
                  </button>
                  <button
                      type="submit"
                      className="submit-step-edit-button"
                      disabled={submitLoading}
                  >
                      {
                        submitLoading ? <CircularProgress color="inherit" size={18} /> : "登録"
                      }
                  </button>
              </div>
          </form>
        )
      }
    </CommonStyledSugorkuDialogWrapper>
  )
}

export default InvoicingModal