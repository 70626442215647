import { useState, useEffect } from "react";
import "./updateProject.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import projectsService from "../../../services/projectsService";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import SelectSearch from "react-select-search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import DatePicker, { registerLocale } from "react-datepicker";
import calendarIcon from "../../../assets/icons/calendar.png";
import plus from "../../../assets/icons/Plus.png";
import { Controller, useForm } from "react-hook-form";
import userService from "services/userService";
import CompanyService from "../../../services/clientCompanyService";
import CooperativeCompanyService from "../../../services/cooperativeCompaniesService";
import { handleFilter } from "utils/helper/filterDropdown";
import { Button } from "@mui/material";
import uploadSimple from "assets/icons/UploadSimple.svg";
import xCircle from "assets/icons/XCircle.svg";
import arrowsCounterClockwise from "assets/icons/ArrowsCounterClockwise.svg";
import { getFilePreview } from "utils/helper/file";
/* eslint-disable import/first */
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);
import createProjectValidation from "utils/validation/registers/projects/createProjectValidation";
import { useTranslation } from "react-i18next";
import { convertClientCompaniesToDropdownOptionsCreateProject } from "utils/helper/clientCompanyHelper";
import { convertCooperativeCompaniesToDropdownOptions } from "utils/helper/cooperativeCompanyHelper";
import { convertObjectToDropdownOptions } from "utils/helper/dropdownHelper";
import workersService from "services/wokersService";
import { convertUserConfigToDropdownOptions } from "utils/helper/userConfigHelper";
import { convertErrorMessagesToObject } from "utils/validation/validatorHelper";
import isEmpty from "lodash/isEmpty";
import {
  BusinessTypeEnum,
  SugorokuIndexEnum,
  WasteSugorokuIndexEnum,
} from "utils/enums";
import moment from "moment";
import { merge, keyBy, values } from "lodash";
import sugorokuService from "services/sugorokuService";
interface DropdownType {
  value: any;
  label: any;
}
const ProjectUpdate = (props) => {
  let { id } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState<any>([]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    // defaultValues: defaultValue
  });

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [projectDetail, setProjectDetail] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);

  // Configurations
  const [sugoroku, setSugoroku] = useState<any>([]);
  const [wasteSugoroku, setWasteSugoroku] = useState<any>([]);

  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [listCompanies, setListCompanies] = useState<any>([]);
  const [listCooperativeCompanies, setListCooperativeCompanies] = useState<any>(
    []
  );
  const [listWorkers, setListWorkers] = useState<any>([]);
  const [showCooperativeField, setShowCooperativeField] = useState<boolean>(
    false
  );
  const [statusFull, setStatusFull] = useState<any>([]);
  const [businessTypesFull, setBusinessTypesFull] = useState<any>([]);
  const [lastSugoroku, setLastSugoroku] = useState<any>();

  const handleStartAtChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndAtChange = (date: any) => {
    setEndDate(date);
  };

  const handleUploadFile = (files) => {
    if (!files || files.length < 0) return;

    const fileObject = {
      file: files[0],
      path: getFilePreview(files[0]),
    };

    setImageFile(fileObject);
  };

  const getListConfiguration = async () => {
    try {
      const response = await userService.getListConfiguration();
      if (response !== null && response !== undefined) {
        setSugoroku(
          Object.values(SugorokuIndexEnum).map((v) => ({
            value: v.name,
            label: v.title,
          }))
        );
        setWasteSugoroku(
          Object.values(WasteSugorokuIndexEnum).map((v, i) => ({
            value: v.name,
            label: v.title,
          }))
        );
        setBusinessTypes(
          convertUserConfigToDropdownOptions(
            response.configurations?.project?.business_types
          )
        );
        setBusinessTypesFull(response.configurations?.project?.business_types);
        setStatusFull(response.configurations?.project?.statuses);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListClientCompanies = async () => {
    try {
      const response = await CompanyService.getListCompanies({
        per_page: 999,
      });
      if (response !== null && response !== undefined) {
        setListCompanies(
          convertClientCompaniesToDropdownOptionsCreateProject(
            response.client_companies
          )
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListCooperativeCompanies = async () => {
    try {
      const response = await CooperativeCompanyService.getListCooperativeCompanies();
      if (response !== null && response !== undefined) {
        setListCooperativeCompanies(
          convertCooperativeCompaniesToDropdownOptions(
            response.cooperative_companies
          )
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListWorkers = async () => {
    try {
      const response = await workersService.getListWorkers({
        per_page: 999,
      });
      if (response !== null && response !== undefined) {
        setListWorkers(convertObjectToDropdownOptions(response.workers));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getProjectDetail = async (id) => {
    let business: DropdownType = {
      value: "",
      label: "",
    };

    let status: DropdownType = {
      value: "",
      label: "",
    };

    try {
      const response = await projectsService.getProject(id);
      if (response !== null && response !== undefined) {
        setProjectDetail(response.project);
        let start = response.project.start_at
          ? new Date(response.project.start_at)
          : null;
        let end = response.project.end_at
          ? new Date(response.project.end_at)
          : null;
        setStartDate(start);
        setEndDate(end);
        let arrayBusiness = Object.entries(businessTypesFull);
        let arrayStatus = Object.entries(statusFull);
        arrayBusiness.map((item) => {
          if (item[1] === response.project?.business_type) {
            business.value = item[0];
            business.label = item[1];
          }
        });

        arrayStatus.map((item) => {
          if (item[1] === response.project?.status) {
            status.value = item[0];
            status.label = item[1] != "none" ? item[1] : "なし";
          }
        });
        if (response?.project?.image?.image_path) {
          setImageFile({
            path: response?.project?.image?.image_path,
          });
        }
        setDefaultValue({
          assigned_project_workers_attributes: response?.project?.assigned_project_workers_attributes?.map(
            (item) => ({
              label: item?.name,
              value: item?.worker_id,
              name: item?.name,
            })
          ),
          name: response.project?.name,
          business_type: business,
          status: status,
          note: response?.project?.note,
          estimate_assigned_worker_id: response.project
            ?.estimate_assigned_worker
            ? {
                value: response.project?.estimate_assigned_worker?.id,
                label: response.project?.estimate_assigned_worker?.name,
                name: response.project?.estimate_assigned_worker?.name,
              }
            : "",
          location_address: response.project?.location_address,
          manager_worker_id: response.project?.manager_worker
            ? {
                value: response.project?.manager_worker?.id,
                label: response.project?.manager_worker?.name,
                name: response.project?.manager_worker?.name,
              }
            : "",
          start_at: response.project?.start_at
            ? new Date(response.project?.start_at)
            : undefined,
          end_at: response.project?.end_at
            ? new Date(response.project?.end_at)
            : undefined,
          location_zipcode: response.project?.location_zipcode,
          client_company_id: {
            value: response.project?.client_company?.id,
            label: response.project?.client_company?.company?.name,
            name: response.project?.client_company?.company?.name,
          },
          cooperative_company_ids: response.project?.cooperative_companies?.map(
            (item) => {
              return {
                value: item?.id,
                label: item?.company?.name,
                name: item?.company?.name,
              };
            }
          ),
        });
        let sugoroku;
        if (response?.project?.business_type == "解体") {
          sugoroku = await sugorokuService.getListOfSugoroku(Number(id));
        } else {
          sugoroku = await sugorokuService.getListOfWasteTypeSugoroku(
            Number(id)
          );
        }
        if (sugoroku?.sugoroku_events) {
          setLastSugoroku(sugoroku?.sugoroku_events?.at(-1)?.sugoroku_index);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    id !== null && id !== undefined && getProjectDetail(id);
  }, [id, statusFull]);

  useEffect(() => {
    getListConfiguration();
    getListClientCompanies();
    getListWorkers();
    getListCooperativeCompanies();
  }, [id]);

  useEffect(() => {
    if (
      getValues("business_type") &&
      (getValues("business_type").value ===
        BusinessTypeEnum.INDUSTRIAL_WASTE.name ||
        getValues("business_type").value === BusinessTypeEnum.REAL_ESTATE.name)
    ) {
      setShowCooperativeField(false);
      if (
        getValues("cooperative_company_ids") &&
        getValues("cooperative_company_ids").length > 0
      ) {
        setValue("cooperative_company_ids", []);
      }
    }

    if (
      getValues("business_type") &&
      getValues("business_type").value === BusinessTypeEnum.DISMANTLING.name
    ) {
      setShowCooperativeField(true);
    }
  }, [watch("business_type")]);

  const onUpdate = (data) => {
    const formArray = data?.assigned_project_workers_attributes.map((item) => ({
      ...item,
      inForm: true,
    }));
    const mergeArray = values(
      merge(
        keyBy(projectDetail?.assigned_project_workers_attributes, "worker_id"),
        keyBy(formArray, "value")
      )
    );
    const assinged_worker_ids = mergeArray?.map((item) => {
      if (item?.inForm && !item?.id) {
        return {
          worker_id: item.value,
        };
      }
      if (!item.inForm && item.id) {
        return {
          _destroy: true,
          id: item.id.toString(),
          worker_id: item.worker_id,
        };
      }
      return {
        id: item.id.toString(),
        worker_id: item.worker_id,
      };
    });
    return new Promise(async (resolve, reject) => {
      const cooperative_company_ids = data?.cooperative_company_ids?.map(
        (item) => item.value
      );
      const formData = new FormData();
      formData.append("name", data.name);
      if (imageFile?.file) {
        formData.append("attachment", imageFile?.file);
      }
      formData.append(
        "client_company_id",
        data?.client_company_id?.value || data?.client_company_id
      );
      formData.append("business_type", data.business_type?.value);
      formData.append("location_address", data.location_address);
      formData.append("location_zipcode", data.location_zipcode);
      formData.append("note", data.note);
      if (data.start_at) {
        formData.append(
          "start_at",
          moment(data.start_at).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        formData.append("start_at", "");
      }
      if (data.end_at) {
        formData.append(
          "end_at",
          moment(data.end_at).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        formData.append("end_at", "");
      }
      formData.append(
        "estimate_assigned_worker_id",
        data?.estimate_assigned_worker_id?.value ||
          data?.estimate_assigned_worker_id
      );
      formData.append(
        "manager_worker_id",
        data?.manager_worker_id?.value || data?.manager_worker_id
      );
      formData.append("status", data?.status?.value || "");
      cooperative_company_ids?.forEach((item, index) =>
        formData.append(`cooperative_company_ids[]`, item)
      );
      formData.append(
        "assigned_project_workers_attributes",
        JSON.stringify(assinged_worker_ids || [])
      );
      if (projectDetail?.image?.signed_id) {
        await projectsService.deleteImage(id, projectDetail?.image?.signed_id);
      }
      await projectsService
        .updateProject(formData, id)
        .then((response) => {
          navigate(`/projects/detail/${response?.project?.id}/plan`);
        })
        .catch((err) => {
          const response = err?.response;
          if (!response || typeof response === "undefined") {
            window.location.href = "/login";
            reject(err);
          }
          switch (response.status) {
            case 403:
              const messages = convertErrorMessagesToObject(
                {
                  client_company_id: "Client company",
                },
                response.data?.error
              );

              if (!isEmpty(messages)) {
                Object.keys(messages).map((key) =>
                  setError(key, { message: messages[key] })
                );
              } else {
                setError("name", {
                  message: response.data?.error,
                });
              }
              break;
            default:
          }
          reject(err);
        });
      resolve(true);
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onUpdate)}
      className="container-project-create-detail d-flex flex-row"
    >
      <div className="project-create-content d-flex flex-column">
        <div
          className="create-project-button1 d-flex flex-row"
          style={{ height: "53px" }}
        >
          <label className="create-project-title1">
            {projectDetail?.name} 編集
          </label>
          {/* <label>adasfad</label> */}
        </div>
        {/* <div className="search-workers-row auto-height d-flex flex-row" style={{marginTop: "10px"}}> */}
        {errors.request_validation && (
          <span className="error">{errors.request_validation.message}</span>
        )}
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>案件名</p>
            <input
              autoComplete="off"
              type="text"
              className="dropdown-create-user2"
              {...register("name", createProjectValidation(t).name())}
            ></input>
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>
        </div>

        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>画像</p>
            {imageFile && (
              <div
                className="uploadedFilePreviewContainer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  className="imagePreviewWrapper"
                  style={{
                    position: "relative",
                    width: "200px",
                    height: "200px",
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={imageFile.path}
                    alt="uploaded project image file"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <img
                    src={xCircle}
                    alt="delete icon"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      URL.revokeObjectURL(imageFile.path);
                      setImageFile(null);
                    }}
                  />
                </div>
                <Button
                  component="label"
                  className="uploadImageFileBtn"
                  style={{
                    backgroundColor: "#215493",
                    color: "#FFFFFF",
                    fontFamily: "Zen Kaku Gothic Antique",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "10px 18px",
                    width: "130px",
                    height: "36px",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    src={arrowsCounterClockwise}
                    alt="upload icon"
                    style={{ marginRight: "7px" }}
                  />
                  <span>画像編集</span>
                  <input
                    type="file"
                    hidden
                    style={{
                      display: "none",
                    }}
                    accept="image/*"
                    onChange={(e) => {
                      URL.revokeObjectURL(imageFile.path);
                      handleUploadFile(e.target.files);
                    }}
                  />
                </Button>
              </div>
            )}
            {!imageFile && (
              <Button
                component="label"
                className="uploadImageFileBtn"
                style={{
                  backgroundColor: "#215493",
                  color: "#FFFFFF",
                  fontFamily: "Zen Kaku Gothic Antique",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: "10px 18px",
                  width: "200px",
                  height: "36px",
                  borderRadius: "6px",
                }}
              >
                <img
                  src={uploadSimple}
                  alt="upload icon"
                  style={{ marginRight: "7px" }}
                />
                <span>画像アップロード</span>
                <input
                  type="file"
                  hidden
                  style={{
                    display: "none",
                  }}
                  accept="image/*"
                  onChange={(e) => handleUploadFile(e.target.files)}
                />
              </Button>
            )}
            {/* <input
                            autoComplete="off"
                            type="text"
                            className='dropdown-create-user2'
                            {...register('name', createProjectValidation(t).name())}
                        ></input> */}
            {/* {errors.name && <span className="error">{errors.name.message}</span>} */}
          </div>
        </div>

        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>業種</p>
            <Controller
              name="business_type"
              control={control}
              render={({ field }) => (
                <Dropdown
                  className="dropdown-create-user1 height36"
                  controlClassName="dropdown-control-user height36"
                  options={businessTypes.filter((v) => v.label !== "不動産")}
                  {...register(
                    "business_type",
                    createProjectValidation(t).business_type()
                  )}
                  {...field}
                  placeholder=""
                />
              )}
            />
            {errors.business_type && (
              <span className="error">{errors.business_type.message}</span>
            )}
          </div>
        </div>
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>状態</p>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Dropdown
                  className="dropdown-create-user1"
                  controlClassName="dropdown-control-user"
                  options={
                    getValues("business_type") &&
                    getValues("business_type").label === "解体"
                      ? sugoroku
                      : wasteSugoroku
                  }
                  {...register("status", createProjectValidation(t).status())}
                  {...field}
                  placeholder=""
                  value={
                    getValues("business_type") &&
                    getValues("business_type").label === "解体"
                      ? Object.values(SugorokuIndexEnum).find(
                          (v) => v.name === lastSugoroku
                        ) &&
                        Object.values(SugorokuIndexEnum).find(
                          (v) => v.name === lastSugoroku
                        )?.title
                      : Object.values(WasteSugorokuIndexEnum).find(
                          (v) => v.name === lastSugoroku
                        ) &&
                        Object.values(WasteSugorokuIndexEnum).find(
                          (v) => v.name === lastSugoroku
                        )?.title
                  }
                />
              )}
            />
            {errors.status && (
              <span className="error">{errors.status.message}</span>
            )}
          </div>
        </div>
        {/* </div> */}

        {/* <div className="search-workers-row auto-height d-flex flex-row"> */}
        <div
          className="create-project-input d-flex flex-column"
          style={{ position: "relative" }}
        >
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>顧客</p>
            <Controller
              name="client_company_id"
              control={control}
              render={({ field }) => (
                // <Dropdown
                //     className='dropdown-create-user1 height36'
                //     controlClassName='dropdown-control-user height36'
                //     options={listCompanies}
                //     {...register('client_company_id', createProjectValidation(t).client_company_id())}
                //     {...field}
                //     placeholder=""
                // />}
                <SelectSearch
                  options={listCompanies}
                  search
                  filterOptions={handleFilter}
                  {...register(
                    "client_company_id",
                    createProjectValidation(t).client_company_id()
                  )}
                  {...field}
                  onChange={(value) => setValue("client_company_id", value)}
                  emptyMessage={"該当なし"}
                  placeholder=""
                />
              )}
            />
            {errors.client_company_id && (
              <span className="error">{errors.client_company_id.message}</span>
            )}
          </div>
          <button
            className="create-project-button2 d-flex flex-row button-create-client"
            onClick={() => navigate("/companies/create")}
          >
            新規顧客登録
          </button>
        </div>

        {showCooperativeField && (
          <div className="create-project-input d-flex flex-column">
            <div className="input-add-user d-flex flex-column">
              <p style={{ marginTop: 0 }}>協力会社</p>
              <Controller
                name="cooperative_company_ids"
                control={control}
                render={({ field: { value } }) => {
                  if (!value) return <></>;

                  return (
                    <Autocomplete
                      multiple
                      value={value}
                      options={listCooperativeCompanies}
                      getOptionLabel={(option: any) =>
                        option.label || option.value
                      }
                      classes={{
                        root: "project-create-multiple-select-style",
                        input: "project-create-multiple-select-style",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        setValue("cooperative_company_ids", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="autocomplete-custom-textfield"
                        />
                      )}
                      noOptionsText="該当なし"
                    />
                  );
                }}
              />
              {errors.cooperative_company_ids && (
                <span className="error">
                  {errors.cooperative_company_ids.message}
                </span>
              )}
            </div>
          </div>
        )}

        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>工期予定</p>
            <div className="datepicker-wrap">
              <div className="target-datepicker-wrap d-flex flex-row">
                <div className="input-date" style={{ position: "relative" }}>
                  <Controller
                    name="start_at"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selected={startDate}
                        {...register(
                          "start_at"
                          // createProjectValidation(t).start_at()
                        )}
                        {...field}
                        onChange={(date) => {
                          field?.onChange(date);
                          handleStartAtChange(date);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsStart
                        // required={true}
                        maxDate={endDate}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        locale="ja"
                      />
                    )}
                  />
                  <img
                    className="input-date-img"
                    width={"16px"}
                    height={"16px"}
                    src={calendarIcon}
                  ></img>
                </div>
                <span className="symbol">~</span>
                <div className="input-date" style={{ position: "relative" }}>
                  <Controller
                    name="end_at"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selected={endDate}
                        {...register(
                          "end_at"
                          // createProjectValidation(t).end_at()
                        )}
                        {...field}
                        onChange={(date) => {
                          field?.onChange(date);
                          handleEndAtChange(date);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsEnd
                        // required={true}
                        minDate={startDate}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        locale="ja"
                      />
                    )}
                  />
                  <img
                    className="input-date-img"
                    width={"16px"}
                    height={"16px"}
                    src={calendarIcon}
                  ></img>
                </div>
              </div>
            </div>
            {(errors.start_at || errors.end_at) && (
              <span className="error">
                {errors.start_at?.message || errors.end_at?.message}
              </span>
            )}
          </div>
        </div>
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>見積担当</p>
            <Controller
              name="estimate_assigned_worker_id"
              control={control}
              render={({ field }) => (
                // <Dropdown
                //     className='dropdown-create-user1 height36'
                //     controlClassName='dropdown-control-user height36'
                //     options={listWorkers}
                //     {...register('estimate_assigned_worker_id', createProjectValidation(t).estimate_assigned_worker_id())}
                //     {...field}
                //     placeholder=""
                // />}
                <SelectSearch
                  options={listWorkers}
                  search
                  filterOptions={handleFilter}
                  {...register(
                    "estimate_assigned_worker_id"
                    // createProjectValidation(
                    //     t,
                    // ).estimate_assigned_worker_id(),
                  )}
                  {...field}
                  onChange={(value) =>
                    setValue("estimate_assigned_worker_id", value)
                  }
                  emptyMessage={"該当なし"}
                  placeholder=""
                />
              )}
            />
            {/* {errors.estimate_assigned_worker_id && (
                            <span className="error">
                                {errors.estimate_assigned_worker_id.message}
                            </span>
                        )} */}
          </div>
        </div>
        {/* </div> */}

        {/* <div className="search-workers-row auto-height d-flex flex-row"> */}
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>郵便番号</p>
            <input
              {...register(
                "location_zipcode"
                // createProjectValidation(t).location_zipcode()
              )}
              autoComplete="off"
              type="text"
              className="dropdown-create-user2"
            ></input>
            {errors.location_zipcode && (
              <span className="error">{errors.location_zipcode.message}</span>
            )}
          </div>
        </div>
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>住所</p>
            <input
              {...register(
                "location_address"
                // createProjectValidation(t).location_address()
              )}
              autoComplete="off"
              type="text"
              className="dropdown-create-user2"
            ></input>
            {errors.location_address && (
              <span className="error">{errors.location_address.message}</span>
            )}
          </div>
        </div>
        {/* <div className="create-project-input d-flex flex-column">
                    <div className='input-add-user d-flex flex-column'>
                        <p style={{ marginTop: 0 }}>協力会社</p>
                        <Dropdown
                            className='dropdown-create-user1'
                            controlClassName='dropdown-control-user'
                            options={listCooperativeCompanies}
                            // onChange={onSelect} 
                            // value={department} 
                            placeholder="" />
                    </div>
                </div> */}
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>担当社員</p>
            {listWorkers.length > 0 && (
              <Controller
                name="assigned_project_workers_attributes"
                control={control}
                render={({ field: { value } }) => (
                  <Autocomplete
                    multiple
                    value={value}
                    options={listWorkers}
                    getOptionLabel={(option: any) =>
                      option.label || option.value
                    }
                    classes={{
                      root: "project-create-multiple-select-style",
                      input: "project-create-multiple-select-style",
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(event, newValue) => {
                      setValue("assigned_project_workers_attributes", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="autocomplete-custom-textfield"
                      />
                    )}
                    noOptionsText="該当なし"
                  />
                )}
              />
            )}
          </div>
        </div>
        <div className="create-project-input d-flex flex-column">
          <div className="input-add-user d-flex flex-column">
            <p style={{ marginTop: 0 }}>管理者</p>
            <Controller
              name="manager_worker_id"
              control={control}
              render={({ field }) => (
                // <Dropdown
                //     className='dropdown-create-user1 height36'
                //     controlClassName='dropdown-control-user height36'
                //     options={listWorkers}
                //     {...register('manager_worker_id', createProjectValidation(t).manager_worker_id())}
                //     {...field}
                //     placeholder=""
                // />}
                <>
                  <SelectSearch
                    options={listWorkers}
                    search
                    filterOptions={handleFilter}
                    {...register(
                      "manager_worker_id"
                      // createProjectValidation(
                      //     t,
                      // ).manager_worker_id(),
                    )}
                    {...field}
                    onChange={(value) => setValue("manager_worker_id", value)}
                    emptyMessage={"該当なし"}
                    placeholder=""
                  />
                </>
              )}
            />
            {errors.manager_worker_id && (
              <span className="error">{errors.manager_worker_id.message}</span>
            )}
          </div>
        </div>
        {/* </div> */}
        <div
          className="input-add-user d-flex flex-column"
          style={{ marginTop: "12px" }}
        >
          <p style={{ marginTop: 0 }}>備考</p>
          <textarea
            autoComplete="off"
            className="dropdown-create-user2"
            {...register("note")}
          ></textarea>
        </div>

        <div
          className="modal-create-user-buttons d-flex flex-row"
          style={{ marginTop: "14px", justifyContent: "flex-start" }}
        >
          <button
            className="modal-create-user-button1 d-flex flex-row"
            style={{ width: "167.5px" }}
            onClick={() => navigate("/projects")}
          >
            戻る
          </button>
          <button
            className="modal-create-user-button2 d-flex flex-row"
            style={{ marginLeft: "10px", width: "167.5px" }}
            type="submit"
          >
            登録
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProjectUpdate;
