import { useRef } from "react";
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import calendarIcon from 'assets/icons/calendar.png';
import createNewsValidation from 'utils/validation/registers/news/createValidation';
import NewsService from 'services/newsService';
import { alertError } from 'utils/helper/appHelper';
import StyledNewsForm from '../newsFormStyle';

const EditNewsDialog = ({ open, setOpen, onFinish = () => {}, selectedNews, isDetail }) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
      defaultValues: {
        title: selectedNews.title,
        content: selectedNews.content,
        publish_at: new Date(selectedNews.publish_at),
      }
    });
    const { t } = useTranslation();
    const datepickerRef = useRef<DatePicker>(null);

    const handleClickDatepickerIcon = () => {
        const datepickerElement = datepickerRef.current;
        if(!datepickerElement) return;
        datepickerElement.setFocus(true);
    }

    const onEdit = async (input) => {
      let formData = new FormData();
      formData.append("title", input.title);
      formData.append("content", input.content);
      formData.append("publish_at", moment(input.publish_at).format('YYYY-MM-DD'));

      try {
        const data = await NewsService.updateNews(formData, selectedNews.id);
        if (data) {
          onFinish();
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.error || "";
        setOpen(false);
        alertError(errorMessage);
      }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
            <StyledNewsForm onSubmit={handleSubmit(onEdit)}>
                <div className="dialogTitle">お知らせ新規作成</div>
                
                <div className="inputRow">
                    <p>タイトル</p>
                    <input
                        autoComplete="off"
                        type="title"
                        {...register('title', createNewsValidation(t).title())}
                        disabled={isDetail}
                    />
                    {errors.title && (
                        <span className="error">{errors.title.message}</span>
                    )}
                </div>

                <div className="inputRow">
                    <p>本文</p>
                    <textarea
                        autoComplete="off"
                        style={{ height: '162px' }}
                        className="dropdown-create-user2"
                        {...register(
                            'content',
                            createNewsValidation(t).content(),
                        )}
                        disabled={isDetail}
                    ></textarea>
                    {errors.content && (
                        <span className="error">{errors.content.message}</span>
                    )}
                </div>
                <div className="inputRow">
                    <p>お知らせ日</p>
                    <div className="datepickerWrapper">
                        <Controller
                            name="publish_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    selected={watch('publish_at')}
                                    {...register(
                                        'publish_at',
                                        createNewsValidation(t).publish_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    disabled={isDetail}
                                    ref={datepickerRef}
                                />
                            )}
                        />
                        <img
                            className="calendarDatepickerIcon"
                            src={calendarIcon}
                            onClick={() => handleClickDatepickerIcon()}
                        ></img>
                    </div>
                    {errors.publish_at && (
                        <span className="error">
                            {errors.publish_at.message}
                        </span>
                    )}
                </div>
                {!isDetail && 
                    <div className="formButtonGroup">
                        <button
                            className="cancelBtn"
                            onClick={() => setOpen(false)}
                            type="button"
                        >
                            キャンセル
                        </button>
                        <button
                            type="submit"
                            className="registerBtn"
                        >
                            登録
                        </button>
                    </div>
                }
            </StyledNewsForm>
        </Dialog>
    );
};

export default EditNewsDialog;
