import { 
  Box, 
  TableContainer, 
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button
} from "@mui/material";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { calculateDays, compareDates } from "utils/helper/dateHelper";
import moment from "moment";
import { INewsSchedule } from "..";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";

const WORKER_COLUMN_WIDTH = "120px";
const SINGLE_CELL_WIDTH = `(100% - ${WORKER_COLUMN_WIDTH}) / 7`;
const TOTAL_WEEK_DAY_NUM = 7;
const HOUR = 24;
const MINUTE = 60;
const SECOND = 60;
export const SLASH_LINES_BACKGROUND = `repeating-linear-gradient(
  -45deg,
  transparent,
  transparent 4px,
  transparent 1px,
  gray 7px
),
linear-gradient(
  to bottom,
  transparent,
  transparent
)`;

interface IWholeScheduleData {
  id: number;
  text: string;
  week?: Date[];
  weekInString?: string[];
  data?: INewsSchedule[];
}

interface WeekTableProps {
  currentDate: Date;
  workerData: {
    id: number;
    text: string;
  }[];
  scheduleData: INewsSchedule[];
  setCurrentViewName: Dispatch<SetStateAction<string>>;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  onOpenDetail: (args: INewsSchedule) => void;
  handleChangeView: (selectedDate: Date) => void;
  setDefaultDate: Dispatch<SetStateAction<Date | string>>;
  setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
  setIdWorkerClicked: Dispatch<SetStateAction<number>>;
}

const StyledTableCell = styled(TableCell)({
  border: "1px solid #0000001f",
  padding: "10px 0px !important"
});

const StyledButton = styled(Button)({
  color: "rgb(28, 30, 33)",
  fontWeight: 500,
  fontSize: "14px",
  // lineHeight: "20px",
  minWidth: "58px",
  background: "rgb(231, 231, 231)",
  border: "1px solid rgba(28, 30, 33, 0.2)",
  cursor: "pointer",
  borderRadius: "4px",
  textAlign: "center",
  padding: "0px",
  marginLeft: "25px",
});

interface ScheduleCardProps {
  dataItem: INewsSchedule;
  dataIndex: number;
  week: Date[];
  weekInString: string[];
  onOpenDetail: (args: INewsSchedule) => void;
  idWorker: number;
}
const ScheduleCard: React.FC<ScheduleCardProps> = ({
  dataItem,
  dataIndex,
  week,
  weekInString,
  onOpenDetail,
  idWorker
}) => {
  const { userData } = useSelector((state: RootState) => state.users);
  const startDate = (dataItem.startDate as string).split(" ")[0];
  const startTime = (dataItem.startDate as string).split(" ")[1];
  const endDate = (dataItem.endDate as string).split(" ")[0];
  const endTime = (dataItem.endDate as string).split(" ")[1];
  const numStartDate = (new Date(startDate)).getDate();
  const numEndDate = (new Date(endDate)).getDate();

  const startPos = week.findIndex((dateItem) => numStartDate === dateItem.getDate());
  const numberOfDays: number = calculateDays(startDate, endDate);

  const startDateCompareResult = compareDates(startDate, weekInString[0]);
  const endDateCompareResult = compareDates(endDate, weekInString[weekInString.length - 1]);

  let leftValue = "";
  let widthValue = "";

  if (
    (startDateCompareResult === -1 || startDateCompareResult === 0)
    && (endDateCompareResult === 1 || endDateCompareResult === 0)
  ) {
    // full-week schedule
    leftValue = `${WORKER_COLUMN_WIDTH}`;
    widthValue = `calc(100% * 7 + 7px)`;
  } else if (
    startDateCompareResult === 1 && endDateCompareResult === -1 && compareDates(startDate, endDate) !== 1
  ) {
    // in-week schedule
    leftValue = `calc(${WORKER_COLUMN_WIDTH} + (${startPos === -1 ? 0 : startPos} * (${SINGLE_CELL_WIDTH})))`;
    widthValue = `calc(100% * ${numberOfDays})`;
  } else if (
    (startDateCompareResult === -1 || startDateCompareResult === 0)
    && (endDateCompareResult === -1)
  ) {
    // start-week, end-in-week
    const remainingDays = calculateDays(weekInString[0], endDate);
    leftValue = `${WORKER_COLUMN_WIDTH}`;
    widthValue = `calc(100% * ${remainingDays})`;
  } else {
    // start-in-week, end-week
    const inWeekNumberOfDays = TOTAL_WEEK_DAY_NUM - (startPos === -1 ? 0 : startPos);
    leftValue = `calc(${WORKER_COLUMN_WIDTH} + (${startPos === -1 ? 0 : startPos} * (${SINGLE_CELL_WIDTH})))`;
    widthValue = `calc((100% * ${inWeekNumberOfDays}) + (${inWeekNumberOfDays} * 1px))`
  }

  // check to show slash lines
  let isShowSlashLines = false;
  let assignWorker = dataItem?.worker_schedules?.filter(item => item?.worker_id === idWorker);
  if (idWorker === dataItem?.worker?.id && dataItem?.schedule_maker_is_agree === null) {
    isShowSlashLines = true;
  } else if (assignWorker[0] && assignWorker[0].is_agree === null && assignWorker[0].worker_id !== dataItem?.created_by?.id) {
    isShowSlashLines = true;
  }
  
  //check flow of acc m-terashima.ken@kyowak.co.jp not_publish
  let isNotPublish = dataItem?.not_publish && userData.worker?.id !== idWorker && userData?.email !== "m-terashima.ken@kyowak.co.jp";
  return (
    <Box
      position="absolute"
      width={widthValue || "100%"}
      left={0}
      top={`${(dataIndex * 36) + (2 + (dataIndex * 2))}px`}
      color="#fff"
      paddingX="2px"
      style={{ 
        cursor: "pointer",
        background: isShowSlashLines ? SLASH_LINES_BACKGROUND : '',
        backgroundColor: dataItem.color || "#195192",
        minHeight: "36px"
      }}
      onClick={(e) => {
        e.stopPropagation();
        onOpenDetail({...dataItem, IDWorkerCheck: idWorker})
      }}
    >
      <Typography fontSize="13px" style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>{isNotPublish ? "アポ有り" : (dataItem.title || "")}</Typography>
      {
        dataItem.allDay ? (
          <Typography fontSize="11px">{!isNotPublish ? "一日中" : ""}</Typography>
        ) : (
          <Typography fontSize="11px">{!isNotPublish ? `${startTime?.slice(0, -3)} - ${endTime?.slice(0, -3)}` : ""}</Typography>
        )
      }
    </Box>
  )
}

interface RenderWeekDaysProps {
  returnType: "dateTableHeader" | "dateObject" | "dateFormatString" | "dateTableCell";
  wholeScheduleData?: IWholeScheduleData;
}

const WeekTable: React.FC<WeekTableProps> = ({
  workerData,
  currentDate,
  scheduleData,
  setCurrentViewName,
  setCurrentDate,
  onOpenDetail,
  handleChangeView,
  setDefaultDate,
  setOpenScheduleFormDialog,
  setIdWorkerClicked
}) => {
  const weekdays = ['月', '火', '水', '木', '金', '土', '日'];
  const [now, setNow] = useState(currentDate);
  const [wholeData, setWholeData] = useState<IWholeScheduleData[]>([]);
  let days: Date[] | string[] = [];
  const navigate = useNavigate();
  const previousWeek = () => {
    setCurrentDate((current) => new Date(current.getTime() - (TOTAL_WEEK_DAY_NUM * HOUR * MINUTE * SECOND * 1000)));
  };
  const nextWeek = () => {
    setCurrentDate((current) => new Date(current.getTime() + (TOTAL_WEEK_DAY_NUM * HOUR * MINUTE * SECOND * 1000)));
  };
  const renderWeekDays = ({ returnType = "dateTableHeader", wholeScheduleData }: RenderWeekDaysProps) => {
    let dates: Date[] = [];

    // find Monday
    let dayOfWeek = now.getDay();
    let mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    let monday = new Date(now.getTime());
    monday.setDate(now.getDate() + mondayOffset);

    // generate dates for the rest of the week
    for (let i = 0; i < 7; i++) {
      let day = new Date(monday.getTime());
      day.setDate(monday.getDate() + i);
      dates.push(day);
    }

    if (returnType === "dateObject") {
      return dates;
    }
    if (returnType === "dateFormatString") {
      return dates.map((date) => moment(date).format("YYYY-MM-DD"));
    }

    days = dates; 
    if (returnType === "dateTableCell" && wholeScheduleData) {
      return dates.map((date, i) => {
        return <StyledTableCell style={{ position: "relative", cursor: "pointer" }} onClick={() => handleOpenCreateForm(date, wholeScheduleData?.id)}>
          {
            wholeScheduleData.data 
            && wholeScheduleData.data.length > 0
            && wholeScheduleData.data.map((dataItem, index) => {
              const startDate = (dataItem.startDate as string).split(" ")[0];
              const numStartDate = (new Date(startDate)).getDate();

              const startDateCompareResult = compareDates(startDate, (wholeScheduleData.weekInString || [])[0]);

              if (compareDates(startDate, moment(date).format("YYYY-MM-DD")) === 0) {
                return <ScheduleCard 
                  key={dataItem.id}
                  dataItem={dataItem}
                  dataIndex={index}
                  week={wholeScheduleData.week || []}
                  weekInString={wholeScheduleData.weekInString || []}
                  onOpenDetail={onOpenDetail}
                  idWorker={wholeScheduleData?.id}
                />
              } else if (i === 0 && numStartDate !== date.getDate() && startDateCompareResult === -1) {
                return <ScheduleCard 
                key={dataItem.id}
                dataItem={dataItem}
                dataIndex={index}
                week={wholeScheduleData.week || []}
                weekInString={wholeScheduleData.weekInString || []}
                onOpenDetail={onOpenDetail}
                idWorker={wholeScheduleData?.id}
              />
              } else {
                return <></>;
              }
            })
          }
        </StyledTableCell>
      })
    }
    return dates.map((date, i) => {
      return (<StyledTableCell style={{
        color: compareDates(moment().format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD")) === 0 ? "#e3165b" : "inherit",
        cursor: "pointer"
      }} key={i}
        onDoubleClick={() => handleChangeView(date)}
      >
        <Typography textAlign="center" fontSize="13px">
          {weekdays[i]} <br /> {date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}
        </Typography>
    </StyledTableCell>)});
  };

  const renderCurrentWeek = () => {
    if (!now) return;
    let startOfWeek = new Date(
      new Date(now).setDate(now.getDate() - now.getDay())
    );
    let dates = weekdays.map((_, i) => new Date(
      startOfWeek.setDate(startOfWeek.getDate() + 1)
    ));
    return `${moment(dates[0]).format("YYYY年MM月DD日")} — ${moment(dates[dates.length - 1]).format("YYYY年MM月DD日")}`;
  }

  const handleOpenCreateForm = (date: Date | string, idWorker: number) => {
    const dateValue = moment(date).format('YYYY/MM/DD');
    setDefaultDate(new Date(dateValue));
    setOpenScheduleFormDialog(true);
    navigate(`/calendar`, {
      state: {
        editingId: null,
      },
    });
    setIdWorkerClicked(idWorker);
  }

  useEffect(() => {
    if (workerData.length > 0 && scheduleData.length > 0) {
      const mapping = {};

      for (const item of scheduleData) {
        if (item.WorkerId) {
          if (!mapping[item.WorkerId]) {
            mapping[item.WorkerId] = [];
          }
          mapping[item.WorkerId].push(item);
        }

        if (item.ConferenceId) {
          for (const confId of item.ConferenceId) {
            if (!mapping[confId]) {
              mapping[confId] = [];
            }
            mapping[confId].push(item);
          }
        }
      }

      const newWorkerData: IWholeScheduleData[] = workerData.map((worker) => {
        const data =  mapping[worker.id]?.filter((value, index, originalArr) => originalArr.findIndex(t => (t.id === value.id)) === index);
        // if is_agree: false => not show it in calendar
        const dataNotRefuse = data?.filter(item => {
          let assignWorker = item?.worker_schedules?.filter(it => it.worker_id === worker.id);
          return !((worker.id === item?.worker?.id && item?.schedule_maker_is_agree === false) || (assignWorker[0] && assignWorker[0]?.is_agree === false))
        })
        if (mapping[worker.id]) {
          return {
            ...worker,
            week: (renderWeekDays({ returnType: "dateObject" }) || []) as Date[],
            weekInString: (renderWeekDays({ returnType: "dateFormatString" }) || []) as string[],
            data: dataNotRefuse
          };
        }
        return worker;
      });
      setWholeData(newWorkerData);
    } else {
      setWholeData(workerData);
    }
  }, [workerData, scheduleData]);

  useEffect(() => {
    if (currentDate) {
      setNow(currentDate);
    }
  }, [currentDate]);

  return (
    <div className="WeekTableContainer">
      <div className="dateNavigate relative flex w-[55%] justify-between mb-5">
        <div className="ml-4">
          <button onClick={previousWeek}>
            <ArrowBackIosOutlined style={{ color: "#0000008a" }} />
          </button>
          <button onClick={nextWeek} className="ml-3">
            <ArrowForwardIosOutlined style={{ color: "#0000008a" }} />
          </button>
          <StyledButton onClick={() => {setCurrentViewName("Day")}}>今日</StyledButton>
        </div>

        <div className="font-semibold">{renderCurrentWeek()}</div>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell scope="col" width={WORKER_COLUMN_WIDTH}></StyledTableCell>
              {renderWeekDays({ returnType: "dateTableHeader" })}
            </TableRow>
          </TableHead>
          
          <TableBody>
            {wholeData.map((worker, index) => {
              return (
                <TableRow key={worker.id} sx={{
                  height: worker.data && worker.data.length > 1 ? `${40 * worker.data.length}px` : "inherit",
                  position: "relative"
                }}>
                  <StyledTableCell component="th" scope="row" width={WORKER_COLUMN_WIDTH}>
                    <Typography textAlign="center" fontSize="13px" >{worker.text}</Typography> 
                  </StyledTableCell>
                  {renderWeekDays({ returnType: "dateTableCell", wholeScheduleData: worker })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WeekTable;
