import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridSizeChangedEvent, GridApi } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { isEmpty } from 'lodash';

import { IPageQueries } from 'models';
import { columns } from './utility';
import chatService from 'services/chatService';

export type ReceivedProps = {
    queryFilter: Record<string, any>;
};

export const ROW_HEIGHT = 50;
export const HEADER_HEIGHT = 40;

const useChat = (props: ReceivedProps) => {
    const columnDefsSetting = columns().map((item) => ({
        ...item,
        filterParams: {
            textMatcher: ({ value, filterText }: any) => {
                const filterTextLowerCase = filterText.toLowerCase();
                const valueLowerCase = value.toString().toLowerCase();
                return valueLowerCase.includes(filterTextLowerCase);
            },
        },
    }));

    const [gridApi, setGridApi] = useState<GridApi>();
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const [rowData, setRowData] = useState<any[]>([]);
    const [columnDefs] = useState<any[]>(columnDefsSetting);
    const [total, setTotal] = useState<number>(0);
    const [chatId, setChatId] = useState<number | null>(null);

    const gridRef: any = useRef();

    const containerStyle = useMemo(
        () => ({
            width: '100%',
            height: `${
                isEmpty(rowData)
                    ? 600
                    : rowData.length * ROW_HEIGHT + HEADER_HEIGHT + 1
            }px`,
        }),
        [rowData],
    );
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: false,
            floatingFilter: false,
            sortable: false,
        };
    }, []);

    const isFullWidthRow = useCallback((params) => {
        return params.rowNode.data.fullWidth;
    }, []);

    const onVirtualColumnsChanged = () => {
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    };

    const onGridReady = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
        setGridApi(params.api);
    };

    const fetchChats = useCallback(async () => {
        try {
            const { chats, meta } = await chatService.getChats({
                ...pageQueries,
                ...(props.queryFilter?.free_word && {
                    free_word: props.queryFilter.free_word,
                }),
            });

            setRowData(chats);
            setTotal(meta.total_count);
        } catch (error) {
            //
        }
    }, [pageQueries, props.queryFilter]);

    const onDeleteChat = async (closeDelete: () => void) => {
        if (!chatId) return;

        try {
            await chatService.deleteChat(chatId);
            fetchChats();
            closeDelete();
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        fetchChats();
    }, [fetchChats]);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    }, [rowData, gridApi]);

    return {
        ...props,
        containerStyle,
        gridStyle,
        defaultColDef,
        columnDefs,
        gridRef,
        rowData,
        pageQueries,
        total,
        onDeleteChat,
        setChatId,
        setPageQueries,
        onGridReady,
        isFullWidthRow,
        onVirtualColumnsChanged,
    };
};

export type Props = ReturnType<typeof useChat>;

export default useChat;
