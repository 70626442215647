import styled from 'styled-components';

export const Main = styled.div`
    padding: 15px;
    font-family: 'Zen Kaku Gothic Antique';
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%; */

    .item {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        text-align: left;

        &.item-left {
            margin-right: auto;
            margin-left: 0;

            .message {
                background: #f4f7f9;
            }

            .message-footer {
                justify-content: flex-start;
                display: flex;
            }
        }

        &.item-right {
            margin-right: 0;
            margin-left: auto;

            .message {
                background: #a3eaf7;
            }

            .message-footer {
                justify-content: flex-end;
                display: flex;
            }
        }

        .message-footer {
            width: 100%;

            p {
                font-weight: 500;
                font-size: 15px;
                font-family: 'Zen Kaku Gothic Antique';

                &:nth-child(1) {
                    margin-right: 5px;
                }
            }
        }

        .message {
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            padding: 8px 13px 10px 13px;
            font-family: 'Zen Kaku Gothic Antique';
            display: flex;
            flex-direction: column;
            margin-left: auto;
        }
    }

    .emoji-mart {
        width: 40% !important;
    }

    .rcw-messages-container {
        border-radius: 10px 10px 0 0;
        padding: 25px 30px;
    }

    .rcw-widget-container {
        display: block !important;
        margin: 0;
        position: relative;
    }

    .rcw-message-text {
        max-width: 600px;
        padding: 8px 13px 10px 13px;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .rcw-picker-btn {
        display: none;
    }

    .rcw-picker-btn,
    .rcw-new-message {
        margin-right: 20px;
        margin-left: 40px;
    }

    .rcw-sender {
        align-items: center;
    }

    .rcw-launcher {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #215493;
    }

    .rcw-widget-container {
        box-shadow: 0 2px 10px 1px #b5b5b5;
        border-radius: 10px;
    }

    .rcw-conversation-container {
        box-shadow: none;

        & > .rcw-header {
            background-color: #215493;
            display: none;
        }
    }

    .rcw-launcher {
        display: none;
    }

    .rcw-message {
        flex-direction: column;
        margin: 0;
        margin-bottom: 10px;

        &.rcw-message-client {
            display: none;
        }

        & > .rcw-response {
            background-color: #c7dcf5;
            color: white;
        }
    }
    .chat-box {
        position: relative !important;
    }
    .upload-image-chat {
        // position: absolute;
        left: 18px;
        bottom: 18px;
        z-index: 1000;
        cursor: pointer;
    }
    .preview-img-upload {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        bottom: 88px;
        left: 50px;
        z-index: 1000;
        img {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            margin-right: 5px;
        }
    }
    .img-upl-ct {
        position: relative;
    }
    .delete-image-upl {
        position: absolute;
        top: -7px;
        right: 0;
        color: red;
        cursor: pointer;
    }
    .cs-main-container {
        border: 1px solid #E3E5E8 ;
        border-radius: 10px;
    }
    .cs-message-input__content-editor-wrapper {
        margin-left: 50px !important;
    }
    .cs-message-input {
        background-color: #f4f7f9;
    }
    .cs-message-input__content-editor-wrapper {
        border-radius: 4px;
    }
    .cs-message-input {
        padding: 10px 60px 10px 10px;
    }
    .cs-message-input__content-editor-wrapper, .cs-message-input__content-editor {
        background-color: #fff!important;
    }
    .cs-button--send {
        display: none;
    }
    .cs-message-list {
        margin-bottom: 60px;
    }
    .action-container {
        position: absolute;
        background-color: rgb(244, 247, 249);
        bottom: 0px;
        width: 100%;
        z-index: 1000;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
        align-items: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .input-custom-chat {
        left: 50px;
        border-radius: 4px;
        background-color: white;
        height: 40px;
        padding: 0px 10px;
        width: calc(100% - 80px);
        z-index: 1000;
        border: none !important;
        outline: none !important;
    }
    .scrollbar-container  {
        overflow-anchor: auto !important;
        touch-action: auto !important;
    }
`;

