import { FC } from 'react';
import moment from 'moment';

import useDayList, { Props, ReceivedProps } from './hook';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const renderDateToUI = (date) => {
    if (!date) {
        return '';
    }

    return `${moment(date).format('YYYY')}年${moment(date).format(
        'MM',
    )}月${moment(date).format('D')}日`;
};

const DayListLayout: FC<Props> = ({ localList, setOpenScheduleFormDialog, listLoading }) => {
    const navigate = useNavigate();
    const { userData } = useSelector((state: RootState) => state.users);

    if (listLoading) {
      return (
        <div className="dayListView">
          <Box
            sx={{
              height: "60px",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={22} sx={{
              marginTop: "25px"
            }} />
          </Box>
        </div>
      )
    }

    return (
        <div className="dayListView">
            {localList.map((item) => {
                let workerSchedulesId = item?.worker_schedules?.map((item: { worker_id: number; }) => item.worker_id);
                const checkIsUserInSchedule = workerSchedulesId?.includes(userData.worker?.id) || item?.worker?.id === userData.worker?.id;
                const isHidden = !checkIsUserInSchedule && item?.not_publish
                return <div key={item.id}>
                    {item.customTitle && (
                        <h3 className="viewTitle">
                            {renderDateToUI(item.customTitle)}
                        </h3>
                    )}

                    {item.title && (
                        <button
                            type="button"
                            className="viewItem"
                            onClick={() => {
                                navigate(`/sp/calendar`, {
                                    state: {
                                        editingId: item.id,
                                    },
                                });
                                isHidden ? setOpenScheduleFormDialog(false) : setOpenScheduleFormDialog(true);
                            }}
                        >
                            <span className="itemLabel">
                                {moment(item.startDate).format('HH:mm')} -{' '}
                                {moment(item.endDate).format('HH:mm')}
                            </span>
                            <span className="itemTitle">{isHidden ? "アポ有り" : item.title}</span>
                        </button>
                    )}
                </div>
            })}
        </div>
    );
};

const DayList: FC<ReceivedProps> = (props) => (
    <DayListLayout {...useDayList(props)} />
);

export default DayList;
