import { useState, useEffect } from "react";
import "./adsDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import AdsService from "../../../services/adsService";
import { isImageUpload } from "utils/helper/checkImage";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { isAllowAction } from "utils/helper/workerHelper";
export interface IAds {
    id: number;
    name: string;
    start_date: string;
    files:
        | {
              signed_id: string;
              file_path: string;
              content_type: string;
          }[]
        | null
        | undefined;
    message: string | null | undefined;
}

const AdsDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ads, setAds] = useState<IAds | null>(null);
    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAllowAction(userData);

    const getAdsInf = async (id: string) => {
        try {
            const response = await AdsService.getAds(id);
            if (response !== null && response !== undefined) {
                setAds(response.ads_management);
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    useEffect(() => {
        if (id) {
            getAdsInf(id);
        }
    }, [id]);

    return (
        <div className="container-worker-detail d-flex flex-row">
            <div className="worker-detail-content d-flex flex-column">
                <div className="worker-detail-name-title client-company-header d-flex flex-row">
                    {ads?.name} 詳細
                    {isAllow && (
                        <button
                        className="create-project-button2 d-flex flex-row"
                        onClick={() => navigate(`/ads/edit/${id}`)}
                        >
                            編集
                        </button>
                    )}
                </div>
                <div className="ads-detail-inf d-flex flex-column">
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div
                            className="worker-detail-inf-row-title"
                            style={{ borderTopLeftRadius: 5 }}
                        >
                            開始日
                        </div>
                        <div className="worker-detail-inf-row-content">
                            {ads?.start_date}
                        </div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div
                            className="worker-detail-inf-row-title"
                            style={{ borderTopLeftRadius: 5 }}
                        >
                            メッセージ
                        </div>
                        <div className="worker-detail-inf-row-content">
                            {ads?.message}
                        </div>
                    </div>
                    <div className="worker-detail-inf-row d-flex flex-row">
                        <div className="worker-detail-inf-row-title">
                            アップロード
                        </div>
                        <div className="worker-detail-inf-row-content"></div>
                    </div>
                    {ads?.files && ads?.files?.length > 0 && (
                        <div className="image-list">
                            {ads?.files.map((item, id) => (
                                <div className="img-container" key={id}>
                                    {isImageUpload(
                                        item?.content_type?.split("/")
                                    ) ? (
                                        <img
                                            src={item?.file_path}
                                            className="image-ads"
                                        ></img>
                                    ) : (
                                        <video
                                            className="img-container"
                                            width="300px"
                                            height="200px"
                                            controls
                                        >
                                            <source
                                                src={item?.file_path}
                                                type="video/mp4"
                                                className="image-ads"
                                            />
                                        </video>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="worker-detail-footer d-flex flex-row client-company-bottom">
                    <button
                        className="back-button d-flex flex-row"
                        onClick={() => navigate("/ads")}
                    >
                        戻る
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdsDetail;
