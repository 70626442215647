
export const validate = (values: any) => {
    let errors = {};

    if (!values.name) {
        errors = { ...errors, name: 'は必須です。' }
    }
    if (!values.summary) {
        errors = { ...errors, summary: 'は必須です。' }
    }
    if (!values.is_parent) {
        if (values.amount === null) {
            errors = { ...errors, amount: 'は必須です。' }
        }
        if (!values.unit === null) {
            errors = { ...errors, unit: 'は必須です。' }
        }
        if (values.unit_price === null) {
            errors = { ...errors, unit_price: 'は必須です。' }
        }
        if (values.price === null) {
            errors = { ...errors, price: 'は必須です。' }
        }
    }

    if (!values.note) {
        errors = { ...errors, note: 'は必須です。' }
    }
    return errors;
};
