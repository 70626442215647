import styled from 'styled-components';

export const NumberInputWrapper = styled.div`
    font-family: 'Zen Kaku Gothic Antique';

    input {
        height: 32px;
        background: #f3f3f3;
        border-radius: 4px;
        padding: 5px 15px;
        border: 0;
        font-weight: 400;
        color: #1c1e21;
        font-size: 14px;
        box-sizing: border-box;
        width: 120px;
        &:placeholder {
            color: #1c1e21;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
`;
