import bookMarkApi from 'services/api/bookMarkApi';

  
const getListBookMark = async () => {
    const res = await bookMarkApi().get('')
    return res.data
}

const deleteBookMark = async (id) => {
  await bookMarkApi().delete(`${id}`);
}

const createBookMark = async (payload) => {
    const res =  await bookMarkApi().post('', payload, {})
    return res.data;
}

const bookMarkService = {
  getListBookMark, 
  deleteBookMark,
  createBookMark
}

export default bookMarkService;
