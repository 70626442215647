import { get } from 'lodash';
import moment from 'moment';

export const columns = () => {
    return [
        {
            headerName: '作成日',
            field: 'createdAt',
            maxWidth: 130,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{moment(e.createdAt).format('YYYY/MM/DD')}</div>
            ),
        },
        {
            headerName: 'タイトル',
            field: 'title',
            maxWidth: 240,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: '案件',
            field: 'project',
            maxWidth: 240,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => <>{e.value?.name || ''}</>,
        },
        {
            headerName: '参加者',
            field: 'who_can_join',
            maxWidth: 300,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            // cellStyle: {
            //     display: '-webkit-box',
            //     'line-height': '35px',
            //     'white-space': 'normal',
            //     overflow: 'hidden',
            //     'text-overflow': 'ellipsis',
            //     '-webkit-line-clamp': '2',
            //     'max-width': '100%',
            //     '-webkit-box-orient': 'vertical',
            //     '-webkit-box-align': 'center',
            // },
        },
        {
            headerName: '会話',
            field: 'last_message',
            maxWidth: 400,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellStyle: {
                display: '-webkit-box',
                'line-height': '35px',
                'white-space': 'normal',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'horizontal',
                '-webkit-box-align': 'center',
                'max-width': '100%',
            },
        },
    ];
};
