import React, { useState, useEffect } from 'react';
import './dashboard.css';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ProgressBar from "./components/Progressbar"
import topPageService from '../../services/topPageService'
import { useNavigate } from 'react-router-dom';
import NewsModal from './components/newsModal'
import Modal from 'react-modal';
import NewsService from 'services/newsService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import scheduleService from 'services/scheduleService';
import SlideAds from './components/SlideAds';
import adsService from 'services/adsApi';

Modal.setAppElement('#root');

declare let window: any;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    position: "fixed"
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#C7DCF5",
    color: "#1C1E21",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Zen Kaku Gothic Antique',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C1E21",

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type WorkerAttribute = {
  id: number;
  name: string;
  worker_id: number;
}
interface ISchedule {
  id: number;
  title: string;
  content: string;
  start_date: Date;
  end_date: Date;
  worker_schedule_attributes: WorkerAttribute[];
  idNews: number;
  created_by: {id: number, name: string};
  is_all_day: boolean;
}

interface IWorkerSchedule {
  id: number;
  is_agree: boolean;
}

interface ISlider {
  name: string;
  file_path: string;
  content_type: string;
  start_date: Date;
  message?: string;
}

const Dashboard = () => {
  const [hide, setHide] = useState(false)
  const listProgress = ["0%", "0%", "0%"]
  const [openNewsModal, setOpenNewsModal] = useState(false)
  const [newsList, setNewsList] = useState<any>(null)
  const [news, setNews] = useState<any>(null)
  const [requestsList, setRequestsList] = useState<any>(null)
  const [progressList, setProgressList] = useState<any>(listProgress)
  const [progressListNumber, setProgressNumber] = useState<any>([0, 0, 0])
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [scheduleDetail, setScheduleDetail] = useState<ISchedule>()
  const [listNewsSchedules, setListNewsSchedules] = useState<ISchedule[]>([]);
  const [isAgree, setIsAgree] = useState(false);
  const [isRefuse, setIsRefuse] = useState(false);
  const [typeSubmit, setTypeSubmit] = useState('');
  const [adsManagements, setAdsManagements] = useState<ISlider[]>([]);
  const [openPopupAgainFirst, setOpenPopupAgainFirst] = useState(false);
  const [nameWorkerCreate, setNameWorkerCreate] = useState('');

  const navigate = useNavigate();
  const today = formatDateTime(new Date(), 'yyyy-MM-DD');
  const { userData } = useSelector((state: RootState) => state.users);

  const handleClose = () => {
    setOpenNewsModal(false);
  }

  function getFormattedDate(dateString) {
    let date = new Date(dateString);
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return year + '-' + month + '-' + day;
  }

  const getListNews = async () => {
    try {
      // const response = await topPageService.getListNews()
      const response = await NewsService.getListNews({ page: 1, per_page: 0, include_schedule: true });
      if (response !== null && response !== undefined) {
        const todayNews = response.news.filter((item) => item.publish_at === today && !item.schedule);
        const otherDayNews = response.news.filter((item) => item.publish_at !== today && !item.schedule);
        setNewsList([...todayNews, ...otherDayNews]);

        const schedules = response.news.map((item: { schedule: ISchedule; id: number; }) => {
          return { ...item.schedule, idNews: item.id }
        });
        let listFilterNewsSchedules = schedules?.filter((it: ISchedule) => typeof (it.id) === 'number' && new Date(it.end_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
        let listFilterScheduleMarkerAgree = listFilterNewsSchedules?.filter((item: { schedule_maker_is_agree: boolean | null | string; created_by: { id: number; }; worker: { id: number; }; }) => !(item?.schedule_maker_is_agree && item?.created_by?.id === item?.worker?.id && item?.created_by?.id === userData?.worker?.id))
        setListNewsSchedules(listFilterScheduleMarkerAgree);
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const getNewsDetail = async (id: number) => {
    try {
      const res = await NewsService.getNews(id);
      setScheduleDetail(res?.new?.schedule);
      setOpenScheduleModal(true);
    } catch (error) {
      console.log(error)
    }
  }

  const updateScheduleStatus = async (isAgree: boolean) => {
    if (!scheduleDetail || !userData.worker) return;
    const params = {
      worker_id: userData?.worker?.id,
      is_agree: isAgree,
    }
    try {
      await scheduleService.updateScheduleStatus(scheduleDetail?.id, params);
    } catch (error) {
      console.log(error);
    }
  }

  const getListRequest = async () => {
    try {
      const response = await topPageService.getRequest()
      if (response !== null && response !== undefined) {
        response.top_page.map((request) => {
          if (request.created_at !== undefined && request.created_at !== null) {
            request.created_at = getFormattedDate(request.created_at)
          }
          if (request.due_date !== undefined && request.due_date !== null) {
            request.due_date = getFormattedDate(request.due_date)
          }
        })
        setRequestsList(response.top_page)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const getMax = object => {
    return Object.keys(object).filter(x => {
      return object[x] == Math.max.apply(null,
        Object.values(object));
    });
  };

  const getListProgress = async () => {
    try {
      const response = await topPageService.getProgress()
      // let response = {complete_project: 6,
      //   inprogress_project: 5,
      //   negotiating_project: 3}
      // console.log(response)
      if (response !== null && response !== undefined) {
        let obj = response
        let listKeySort = getMax(obj);

        let a = ""
        let b = ""
        let c = ""
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${key}: ${value}`);
          if (key == "negotiating_project" && key == listKeySort[0] && value !== 0) {
            a = "100%";
            b = `${(obj.inprogress_project / obj.negotiating_project) * 100}%`;
            c = `${(obj.complete_project / obj.negotiating_project) * 100}%`;
          }
          if (key == "inprogress_project" && key == listKeySort[0] && value !== 0) {
            b = "100%";
            a = `${(obj.negotiating_project / obj.inprogress_project) * 100}%`;
            c = `${(obj.complete_project / obj.inprogress_project) * 100}%`;
          }
          if (key == "complete_project" && key == listKeySort[0] && value !== 0) {
            c = "100%";
            b = `${(obj.inprogress_project / obj.complete_project) * 100}%`;
            a = `${(obj.negotiating_project / obj.complete_project) * 100}%`;
          }
        }
        // console.log([a,b,c])
        // console.log([obj.negotiating_project, obj.inprogress_project, obj.complete_project])
        setProgressList([a, b, c])
        setProgressNumber([obj.negotiating_project, obj.inprogress_project, obj.complete_project])
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const convertDateToJapan = (dateTime, isAllDay = true) => {
    let newDateTime = dateTime.replace(/ /g, "T")
    const date = new Date(newDateTime)
    var lang = 'en';
    let year = date.toLocaleString(lang, { year: 'numeric' });
    let month = date.getMonth() + 1;
    let day = date.toLocaleString(lang, { day: 'numeric' });
    let hour = date.getHours();
    let minute = date.getMinutes();
    const textDate = !isAllDay ? `${year}年${month}月${day}日 ${hour < 10 ? `${hour}0` : hour}:${minute < 10 ? `${minute}0` : minute}`  : `${year}年${month}月${day}日`;
    return textDate
  }

  const getListAdsManagements = async () => {
    try {
      const response = await adsService.getAdsManagements({ page: 1, per_page: 0 });
      if (response !== null && response !== undefined) {
        let listSliders = response?.ads_managements;
        let newSliders: ISlider[] = [];
        for (let i = 0; i < listSliders?.length; i++) {
          const arrFiles = listSliders[i]?.files;
          if (arrFiles !== null) {
            for (let j = 0; j < arrFiles.length; j++) {
              newSliders.push({ name: listSliders[i]?.name, file_path: arrFiles[j].file_path, content_type: arrFiles[j]?.content_type, start_date: listSliders[i]?.start_date, message: listSliders[i]?.message })
            }
          }
        }
        let newSlidersNotFuture = newSliders?.filter(slide => new Date(slide?.start_date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
        let maxItem = newSlidersNotFuture?.sort((a, b) => {
          return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
        })[0];
        setAdsManagements(newSlidersNotFuture?.filter(slide => new Date(slide?.start_date).setHours(0, 0, 0, 0) === new Date(maxItem?.start_date).setHours(0, 0, 0, 0)));
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleAgreeSchedule = () => {
    setOpenPopupAgainFirst(true);
    setTypeSubmit('agree');
  }

  const handleRefuseSchedule = () => {
    setOpenPopupAgainFirst(true);
    setTypeSubmit('refuse');
  }

  const handleClosePopupShedule = () => {
    setOpenScheduleModal(false);
    setIsRefuse(false);
    setIsAgree(false);
  }
  const handleClosePupupAgain = () => {
    setOpenPopupAgainFirst(false);
    setOpenScheduleModal(false);
    setTypeSubmit('');
  }
  const handleSubmitAgain = () => {
    typeSubmit === 'agree' ? updateScheduleStatus(true) : updateScheduleStatus(false);
    setOpenScheduleModal(false);
    setOpenPopupAgainFirst(false);
  }
  const handleAgreeSendApiFirst = () => {
    updateScheduleStatus(true);
    setOpenScheduleModal(false);
  }
  const handleRefuseSendApiFirst = () => {
    updateScheduleStatus(false);
    setOpenScheduleModal(false);
  }

  useEffect(() => {
    getListNews();
    getListRequest();
    getListProgress();
    getListAdsManagements();
  }, [])

  useEffect(() => {
    let path = window.location.pathname;
    if (path == '/login') {
      setHide(true)
    }
  }, []);

  return (
    <div className='dashboard'>
      <div className='dashboard-content d-flex flex-column'>
        <div className='dashboard-news d-flex flex-row'>
          <div className='dashboard-news-container d-flex flex-column'>
            <div className='dashboard-news-title'>お知らせ</div>
            <div className={`dashboard-news-content ${listNewsSchedules?.length > 3 ? "dashboard-news-content1" : "!h-fit"}`}>
              {
                newsList !== undefined && newsList !== null && newsList.length > 0 ? newsList.map((item, ind) => {
                  if (ind < 2) return (
                    <div className='dashboard-news-item d-flex flex-row' key={ind}>
                      <div className='d-flex flex-row' style={{ width: "calc(100% - 16px)", marginTop: "20px" }}>
                        <div className='news-time'>
                          <p className='news-date' style={{ marginRight: '10px' }}>{convertDateToJapan(item.publish_at)}</p>
                          <p className='news-inf'></p>
                        </div>

                        <div className='news-content-title'>
                          <p className='news-inf'>{item?.title}</p>
                          <div className='news-inf-content-ctn d-flex flex-row'>
                            <p className='news-inf-content'>{item?.content}</p>
                            <div className='news-inf-content-show-more' onClick={() => {
                              setOpenNewsModal(true);
                              setNews(item)
                            }}>
                              もっと見る
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) : <div className='empty-news-list d-flex flex-column'>
                  お知らせはありません
                </div>
              }
              {listNewsSchedules?.map((schedule, i) => {
                return (
                  <div className='dashboard-news-item d-flex flex-row' key={i}>
                    <div className='d-flex flex-row' style={{ width: "calc(100% - 16px)", marginTop: "20px" }}>
                      <div className='news-time'>
                        <p className='news-date' style={{ marginRight: '10px' }}>{convertDateToJapan(schedule?.start_date)}</p>
                        <p className='news-inf'></p>
                      </div>

                      <div className='news-content-title'>
                        <p className='news-inf'>{schedule?.title}</p>
                        <div className='news-inf-content-ctn d-flex flex-row' style={{ alignItems: "flex-end", justifyContent: "space-between", paddingRight: "16px" }}>
                          <p className='news-inf-content news-inf-content2'>{ schedule?.created_by?.name || "admin" } から{convertDateToJapan(schedule?.start_date, schedule?.is_all_day)} - {convertDateToJapan(schedule?.end_date, schedule?.is_all_day)}に{schedule?.title} {schedule?.content}の参加依頼がありました。</p>
                          <div className='news-inf-content-show-more' onClick={() => {
                            getNewsDetail(schedule?.idNews);
                            setNameWorkerCreate(schedule?.created_by?.name || "admin");
                          }}>
                            もっと見る
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              {newsList !== undefined && newsList !== null && newsList.length > 0 &&
                <div className='news-see-more-ctn d-flex flex-column'>
                  <div className='news-see-more d-flex flex-column' onClick={() => navigate("/news")}>もっと見る</div>
                </div>}
                
            </div>
            
            <Modal
              contentLabel="news modal"
              isOpen={openNewsModal}
              onRequestClose={handleClose}
              style={customStyles}
            >
              <NewsModal close={handleClose} news={news} convertDateToJapan={convertDateToJapan}></NewsModal>
            </Modal>
            <Modal
              contentLabel="schedule modal"
              isOpen={openScheduleModal}
              onRequestClose={handleClosePopupShedule}
              style={customStyles}
            >
              <NewsModal
                type='schedule'
                close={handleClosePopupShedule}
                news={scheduleDetail}
                convertDateToJapan={convertDateToJapan}
                handleAgree={handleAgreeSchedule}
                handleRefuse={handleRefuseSchedule}
                userData={userData}
                handleAgreeSendApiFirst={handleAgreeSendApiFirst}
                handleRefuseSendApiFirst={handleRefuseSendApiFirst}
                nameWorkerCreate={nameWorkerCreate}
              />
            </Modal>
            <Modal
              contentLabel="schedule modal small first"
              isOpen={openPopupAgainFirst}
              onRequestClose={() => setOpenPopupAgainFirst(false)}
              style={customStyles}
            >
              <div className='mb-[12px]'>{typeSubmit === "agree" ? "本当に承認しますか？": "本当に却下しますか？"}</div>
              <div className='flex'>
                <button className='btnCancel' onClick={handleClosePupupAgain}>キャンセル</button>
                <button className='btnOK' onClick={handleSubmitAgain}>はい</button>
              </div>
            </Modal>
          </div>

          <div className='dashboard-news-container d-flex flex-column'>
            <div className='dashboard-news-title'>今月進捗</div>
            <div className='dashboard-news-content progress-chart d-flex flex-column'>
              <div className='progress-chart-item d-flex flex-row'>
                <div className='title-progress'>見込み案件 </div>
                <div className='custom-progress d-flex flex-row'>
                  <ProgressBar width={progressList[0]}></ProgressBar>
                  <label>{progressListNumber[0]}</label>
                </div>
              </div>
              <div className='progress-chart-item d-flex flex-row'>
                <div className='title-progress'>実施中案件数</div>
                <div className='custom-progress d-flex flex-row'>
                  <ProgressBar width={progressList[1]}></ProgressBar>
                  <label>{progressListNumber[1]}</label>
                </div>

              </div>
              <div className='progress-chart-item d-flex flex-row'>
                <div className='title-progress'>完了案件</div>
                <div className='custom-progress d-flex flex-row'>
                  <ProgressBar width={progressList[2]}></ProgressBar>
                  <label>{progressListNumber[2]}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          adsManagements?.length > 0 && <SlideAds listSliders={adsManagements} />
        }  
      </div>

    </div>
  );
}
export default Dashboard;
