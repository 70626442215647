import styled from 'styled-components';

const StyledSyncfusionSchdulerContainer = styled.div`
    width 100%;
    background-color: #E3E6E8;
    padding: 28px;

    .pageContentWrapper {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 28px;
    }
`;

export default StyledSyncfusionSchdulerContainer;