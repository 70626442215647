import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel, CircularProgress } from '@mui/material'
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from "../../common/DialogHeader";
import { StyledTextField } from "components/StyledTextField";
import CommonStyledSugorkuDialogWrapper from "../../common/commonSugorokuDialogStyle";
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from "utils/constants";
import { SugorokuIndexEnum } from "utils/enums";
import sugorokuService from "services/sugorokuService";
import { formatDateTime } from 'utils/helper/dateHelper';
import { useFormik, FormikProps } from "formik";
import { IPriceNegotiationPayload } from "models";

interface IProps {
  step: number;
  onClose?: any;
  data_id?: number;
  onFinish: () => void;
}

interface IPriceNegotiationFormikValues {
  has_deal_price?: boolean | null;
  content?: string | null;
  completed_at?: Date | null;
}

const PriceNegotiationModal = (props: IProps) => {
  const { step, onClose, data_id, onFinish } = props;
  const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<any>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const hasData = !!data_id;
  const { id } = useParams();

  const formik: FormikProps<IPriceNegotiationFormikValues> = useFormik<IPriceNegotiationFormikValues>({
    initialValues: {
      has_deal_price: null,
      content: "",
      completed_at: null,
    },
    onSubmit: (values: IPriceNegotiationFormikValues) => {
      setSubmitLoading(true);
      let data: IPriceNegotiationPayload = pick(values, ['content', 'has_deal_price']);
      if (!id) {
        setSubmitLoading(false);
        return;
      }
    
      data.project_id = +id;
      data.sugoroku_index = SugorokuIndexEnum.PRICE_NEGOTIATION.value;

      if (values.completed_at) {
        data.completed_at = formatDateTime(values.completed_at, "yyyy-MM-DD");
      } else {
        data.completed_at = null;
      }
      
      if (hasData) {
        updateSugoroku(data);
      } else {
        createSugoroku(data);
      }
    }
  });

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const data = await sugorokuService.getSugorokuEventDetail(data_id, Number(id));
      if (data && data.sugoroku_event) {
        const sugorokuData = data.sugoroku_event;
        
        formik.setValues({
          'has_deal_price': sugorokuData.has_deal_price,
          'content': sugorokuData.content,
          'completed_at': sugorokuData.completed_at 
            ? new Date(sugorokuData.completed_at)
            : null,
        });
        setIsEmpty(false)
      } else {
        setIsEmpty(true)
      }
    } catch (error) {
      // error
    } finally {
      setFetchLoading(false);
    }
  }

  const createSugoroku = async (data: IPriceNegotiationPayload) => {
    try {
      const responseData = await sugorokuService.createSugorokuEvent(data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // empty
    }
  }

  const updateSugoroku = async (data: IPriceNegotiationPayload) => {
    try {
      const responseData = await sugorokuService.updateSugorokuEvent(data_id, data);
      if (responseData) {
        setSubmitLoading(false);
        onFinish();
      }
    } catch (error) {
      setSubmitLoading(false);
      // epmty
    }
  }

  const onChangeHasDealPrice = (e) => {
    if (e.target.value === formik.values.has_deal_price) {
      formik.setFieldValue('has_deal_price', null);
    } else {
      formik.setFieldValue('has_deal_price', e.target.value);
    }
  }

  useEffect(() => {
    if (data_id && id) {
      fetchData();
    }
  }, [])

  return (
    <CommonStyledSugorkuDialogWrapper>
        <DialogHeader 
          step={step}
          title={SugorokuIndexEnum.PRICE_NEGOTIATION.title}
          onClose={onClose}
          mode={mode}
          setMode={setMode}
          completed={!!formik.values.completed_at}
        />

        {
          fetchLoading && (
            <div className="d-flex justify-center">
              <CircularProgress />
            </div>
          )
        }

        {
          mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
            <>
              <div className="dialog-field-row">
                  <div className="field-label">価格交渉</div>
                  <div className="value">
                    {
                      formik.values.has_deal_price && data_id 
                        ? "あり"
                        : formik.values.has_deal_price === false && data_id
                        ? "なし"
                        : formik.values.has_deal_price === null && data_id
                        ? ""
                        : !data_id 
                        ? ""
                        : ""
                    }
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">内容</div>
                  <div className="value">{formik.values.content}</div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="value">{formatDateTime(formik.values.completed_at, 'yyyy-MM-DD')}</div>
              </div>
            </>
          )
        }

        {
          mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
            <form onSubmit={formik.handleSubmit}>
              <div className="dialog-field-row">
                  <div className="field-label">価格交渉</div>
                  <div className="radio-group-wrapper">
                      <RadioGroup
                          aria-label="あり"
                          // name="has_deal_price"
                          value={formik.values.has_deal_price}
                          // onChange={formik.handleChange}
                          className="radio-group"
                      >
                          <FormControlLabel
                              value={true}
                              control={<Radio onClick={onChangeHasDealPrice} />}
                              label="あり"
                          />
                          <FormControlLabel
                              value={false}
                              control={<Radio onClick={onChangeHasDealPrice} />}
                              label="なし"
                          />
                      </RadioGroup>
                  </div>
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">内容</div>
                  <StyledTextField 
                    className="textfield-style" 
                    name="content" 
                    value={formik.values.content} 
                    onChange={formik.handleChange}
                  />
              </div>
              <div className="dialog-field-row">
                  <div className="field-label">完了日付</div>
                  <div className="datepicker-wrapper">
                      <DatePicker
                          selected={formik.values.completed_at}
                          onChange={(date) => {
                            formik.setFieldValue('completed_at', date)
                          }}
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                      />
                      <img
                          className="calendar-datepicker-icon"
                          src={calendarIcon}
                      ></img>
                  </div>
              </div>
              <div className="action-section">
                  <button
                      type="button"
                      className="back-step-edit-button"
                      onClick={onClose}
                  >
                    キャンセル
                  </button>
                  <button 
                      type="submit"
                      className="submit-step-edit-button"
                      disabled={submitLoading}
                  >
                      {
                        submitLoading ? <CircularProgress color="inherit" size={18} /> : "登録"
                      }
                  </button>
              </div>
            </form>
          )
        }
    </CommonStyledSugorkuDialogWrapper>
  )
}

export default PriceNegotiationModal;