import { useEffect, useState } from "react"
import { DataManager, ODataV4Adaptor } from "@syncfusion/ej2-data";
import scheduleService from "services/scheduleService";
const api = process.env.REACT_APP_SERVER_API;

const useSyncfusionScheduler = () => {
    const [dataManager, setDataManager] = useState<DataManager>();
    // const data = [
    //     {
    //       Id: 1,
    //       Subject: 'Meeting',
    //       IsAllDay: false,
    //       StartTime: new Date(2023, 6, 15, 10, 0),
    //       EndTime: new Date(2023, 6, 15, 12, 30),
    //       Worker: 'worker 1',
    //     },
    //     {
    //       Id: 2,
    //       Subject: 'CAMON',
    //       IsAllDay: false,
    //       StartTime: new Date(2023, 6, 11, 2, 0),
    //       EndTime: new Date(2023, 6, 11, 8, 0),
    //       Worker: 'worker 1',
    //     },
    //     {
    //       Id: 3,
    //       Subject: 'Ok hand',
    //       IsAllDay: false,
    //       StartTime: new Date(2023, 6, 22, 13, 0),
    //       EndTime: new Date(2023, 6, 22, 14, 0),
    //       Worker: 'worker 2',
    //     },
    // ];

    useEffect(() => {
        const fetchData = async () => {
            const { schedules } = await scheduleService.getSchedules({
                page: 1,
                per_page: 9999,
            });
            console.log('schedules', schedules);
            const formattedScheduleList = schedules.map((item) => ({
                Id: item.id,
                Subject: item.title,
                StartTime: item.start_date,
                EndTime: item.end_date,
            }))
            const manager = new DataManager(formattedScheduleList);
            setDataManager(manager);
            // const manager = new DataManager({
            //     url: api + 'schedules' + '?page=1&per_page=9999',
            //     // adaptor: new ODataV4Adaptor()
            // });
            // console.log('manager', manager);
            // await manager.ready;
            // setDataManager(manager);
        };
        fetchData();
    }, []);

    return {
        dataManager
    }
}

export type Props = ReturnType<typeof useSyncfusionScheduler>;

export default useSyncfusionScheduler;