import { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import DeleteRepeatScheduleDialogWrapper from './style';
import { useFormik } from 'formik';
import { ApplyRuleEnum } from 'utils/enums';
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type IProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClick: (duplicate_apply_type: string) => void;
    deleteLoading: boolean;
};

const DeleteRepeatScheduleDialog: FC<IProps> = ({ open, setOpen, onClick, deleteLoading }) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            duplicate_apply_type: 'only_schedule',
        },
        onSubmit: values => {
          onClick(values.duplicate_apply_type);
        },
    });

    return (
        <BootstrapDialog
            className="customBootstrapDialog deleteConfirmationDialog"
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DeleteRepeatScheduleDialogWrapper>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <div className="modal-create-user-title d-flex flex-row">
                            定期的なスケジュールを削除する
                        </div>

                        <div className="wrapRadioField">
                          <FormControl fullWidth>
                            <RadioGroup
                              row
                              name="duplicate_apply_type"
                              value={formik.values.duplicate_apply_type}
                              onChange={e => {
                                formik.setFieldValue('duplicate_apply_type', e.target.value);
                              }}
                            >
                              {ApplyRuleEnum.map(category => (
                                <FormControlLabel
                                  key={category.id}
                                  value={category.id}
                                  control={
                                    <Radio
                                      checked={formik.values.duplicate_apply_type === category.id}
                                    />
                                  }
                                  label={category.name}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions className="!justify-center">
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{
                                background: '#FF5045',
                                width: 152,
                            }}
                            type="submit"
                            disabled={deleteLoading}
                        >
                            {deleteLoading ? <CircularProgress size={16} sx={{ color: "#fff" }} /> : "はい"}
                        </button>
                        <button
                            className="modal-create-plan-button1 d-flex flex-row"
                            style={{
                                width: 152,
                            }}
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            いいえ
                        </button>
                    </DialogActions>
                </form>
            </DeleteRepeatScheduleDialogWrapper>
        </BootstrapDialog>
    );
};

export default DeleteRepeatScheduleDialog;
