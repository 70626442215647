import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { fromZonedTime } from 'date-fns-tz';
import scheduleService from 'services/scheduleService';
import { CustomizedState } from '../SchedulerBox/hook';
import { validate } from './validate';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IWorkersOptions } from '../FilterBox/hook';
import { JAPANESE_NUM, TOKYO_TZ, WEEKDAY_CONSTANT } from 'utils/constants';
import { alertError } from 'utils/helper/appHelper';
import { compareTwoDateObjects, countSpecificDayBetweenDates, countWeekdaysBetweenDates, getDateInNextYears, getDaysBetweenDates, getWeekNumberInMonth } from 'utils/helper/dateHelper';
import { DuplicateOptionsEnum, DuplicateTypeEnum } from 'utils/enums';

export type ReceivedProps = {
    open: boolean;
    openDeleteConfirmationDialog: boolean;
    openDeleteRepeatScheduleDialog: boolean;
    workerOptions: {
        id: any;
        name: string;
    }[];
    activeTab: number;
    fetchSchedules: () => Promise<void>;
    fetchSchedulesForMonthAndList: () => Promise<void>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteRepeatScheduleDialog: Dispatch<SetStateAction<boolean>>;
    setCurrentItemId: Dispatch<SetStateAction<number>>;
    isAllow: boolean;
    defaultDate: Date | string;
    currentViewName: string;
    idWorkerClicked: number;
    isShowDefaultDate: boolean;
    setIsShowDefaultDate: Dispatch<SetStateAction<boolean>>;
   scheduleListMeetingRoom;
   setCurrentDate: Dispatch<any>; 
};

export const SCHEDULER_COLORS = [
    '#195192',
    '#4950F1',
    '#D83232',
    '#E3A900',
    '#002A5B',
    '#FF6231',
    '#32CED8',
    '#009F08',
    '#754D4D',
    '#FE3571',
    '#B7B7B7',
];

const useCreateScheduleDialog = (props: ReceivedProps) => {
    const location = useLocation();
    const state = location.state as CustomizedState;
    const editingId = state?.editingId;
    const { userData } = useSelector((state: RootState) => state.users);
    const [isScheduleMaker, setScheduleMaker] = useState<boolean>(false);
    const [isErrorMeetingRoom, setIsErrorMettingRoom] = useState(false);
    const [errorTimeMessage, setErrorTimeMessage] = useState("");
    const [duplicateTypes, setDuplicateTypes] = useState(Object.values(DuplicateTypeEnum));
    const [isRepeatSchedule, setIsRepeatSchedule] = useState(false);
    const [openUpdateRepeatScheduleDialog, setOpenUpdateRepeatScheduleDialog] = useState(false);
    const [pendingSubmitValues, setPendingSubmitValues] = useState<any>(null);
    const [changedDuplicateType, setChangedDuplicateType] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false); 
    const [duplicateEndDateMax, setDuplicateEndDateMax] = useState<Date | undefined>();
    const [duplicateMaxNum, setDuplicateMaxNum] = useState<number | undefined>(undefined);

    const isAllowShowNotPublic = () => {
        if (userData?.email === "m-terashima.ken@kyowak.co.jp") return true;
    }
    // const isExecutive = (worker_id: number) => {
    const isExecutive = () => {
        let check = false;
        if (userData?.email === "admin@kyowa.javis.vn") check = true;
        userData?.worker?.departments && userData?.worker?.departments?.length > 0 && userData?.worker?.departments.map((department) => {
            if (department.name === "役員") check = true
        })
        return check
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: (() => ({
            worker_id:
                props.activeTab === 0
                    ? props.workerOptions?.find(
                        (item) => item.id === (userData as any)?.worker?.id,
                    )
                    : props.isShowDefaultDate ? (props.workerOptions?.filter(worker => worker.id === props.idWorkerClicked)[0] || null) : null,
            title: '',
            start_date: props.isShowDefaultDate ? props.defaultDate : '',
            end_date: props.isShowDefaultDate ? (props.currentViewName === "Day" ? new Date(props.defaultDate)?.setTime(new Date(props.defaultDate).getTime() + 60*60*1000) : props.defaultDate) : '',
            is_all_day: props.currentViewName !== "Week" ? false : true,
            worker_schedules_attributes: [],
            color: '#195192',
            content: '',
            place: '',
            duplicate_type: duplicateTypes.length > 0 ? duplicateTypes[0].value : '',
            // is_block_duplicate: false,
            duplicate_options: DuplicateOptionsEnum.BY_CURRENTLY.value,
            duplicate_end_date: '',
            max_duplicate: 1,
            not_publish: false,
            created_by: {id: 0},
            worker: {id: 0}
        }))(),
        validate,
        onSubmit: (values) => {
          if (isRepeatSchedule && editingId && (values.duplicate_type !== DuplicateTypeEnum.DEFAULT.value)) {
            setPendingSubmitValues(values);
            setOpenUpdateRepeatScheduleDialog(true);
          } else {
            submitForm(values);
          }
        },
    });

    const handleUpdateForRepeatSchedule = (duplicate_apply_type: string) => {
      submitForm(pendingSubmitValues, duplicate_apply_type);
    }

    const fetchSchedule = useCallback(async () => {
        try {
            const { schedule } = await scheduleService.getSchedule(editingId);
            if (userData?.worker?.id === schedule?.worker?.id) setScheduleMaker(true)
            else setScheduleMaker(false)
            const worker_schedules_attributes = [
                ...schedule.worker_schedule_attributes,
            ].map((item) => ({
                ...item,
                id: item.worker_id,
            }));
            if (schedule.duplicate_type && schedule.duplicate_type !== DuplicateTypeEnum.DEFAULT.value) {
                setIsRepeatSchedule(true);
            }

            formik.setValues({
                ...schedule,
                worker_id: props.workerOptions?.find(
                    (item) => item.id === schedule?.worker?.id,
                ),
                worker_schedules_attributes,
                start_date: moment(schedule.start_date).zone('+0900').format('YYYY-MM-DD HH:mm'),
                end_date: moment(schedule.end_date).zone('+0900').format('YYYY-MM-DD HH:mm'),
            });
        } catch (error) {
            //
        }
    }, [editingId]);

    const submitForm = async (payload: Record<string, any>, duplicate_apply_type?: string) => {
        setSubmitLoading(true);
        const data: any = {
            ...payload,
            worker_id: payload.worker_id.id,
        };

        const arrMeetingRooms: number[] = [];
        // if(payload.worker_id?.departments2?.filter(item => item?.name === "会議室")?.length > 0) {
        //     arrMeetingRooms.push(payload.worker_id.id)
        // }
        arrMeetingRooms.push(payload.worker_id.id)
        if(formik.values.worker_schedules_attributes?.length > 0) {
            formik.values.worker_schedules_attributes?.map((item: IWorkersOptions) => {
                // if(!item.departments2) return;
                // if(item?.departments2?.filter(item => item?.name === "会議室")?.length > 0) {
                //     arrMeetingRooms.push(item.id)
                // }
                arrMeetingRooms.push(item.id);
            })
        }
        data.meeting_rooms = [...new Set(arrMeetingRooms)];

        if (data.is_all_day) {
            const start_date = moment(data.start_date).format('YYYY/MM/DD');
            const end_date = moment(data.end_date).format('YYYY/MM/DD');

            data.start_date = data.start_date ? moment(new Date(`${start_date}`)).zone('+0900').set({ hour: 0, minute: 0, second: 0}).toISOString(true) : null;
            data.end_date = data.end_date ? moment(new Date(`${end_date}`)).zone('+0900').set({ hour: 23, minute: 59, second: 59}).toISOString(true) : null;
        } else {
            let convertedStartDate: Date | string = fromZonedTime(data.start_date, TOKYO_TZ);
            let convertedEndDate: Date | string = fromZonedTime(data.end_date, TOKYO_TZ);
            convertedStartDate = moment(convertedStartDate).zone('+0900').toISOString(true);
            convertedEndDate = moment(convertedEndDate).zone('+0900').toISOString(true);

            data.start_date = convertedStartDate;
            data.end_date = convertedEndDate;
        }

        if (data.duplicate_end_date) {
          data.duplicate_end_date = moment(data.duplicate_end_date).format('YYYY-MM-DD')
        }
         
        // check time when create meeting room
        // let timeRanges: { start: Date, end: Date }[] = [];
        
        // let arrExitsMeetingRoom = props.scheduleListMeetingRoom?.filter(item => item?.meeting_rooms?.some(r => data.meeting_rooms?.includes(r)))
        // let isExistMeetingRoom = arrExitsMeetingRoom?.length > 0
        // for (let i = 0; i < arrExitsMeetingRoom?.length; i++) {
        //     timeRanges?.push({ start: new Date(arrExitsMeetingRoom[i]?.startDate), end: new Date(arrExitsMeetingRoom[i]?.endDate) })

        // }
        // let isSameTime = timeRanges?.some(range => new Date(data.start_date) >= range?.start && new Date(data.start_date) <= range?.end)
        
        const { worker_schedule_attributes } = data;
        const newScheduleAttr = formik.values.worker_schedules_attributes.map(
            (workerItem: any) => ({
                worker_id: workerItem.id,
                existingOnForm: true,
            }),
        );
        // validate start_time, end_time
        let errorTimer = false;
        if(!data.start_date || !data.end_date) {
            errorTimer = true;
            setErrorTimeMessage('日付は必須です');
        } else {
            if(new Date(data.start_date).getTime() > new Date(data.end_date).getTime()) {
                errorTimer = true;
                setErrorTimeMessage('終了は開始日時以降を入力してください。');
            } else if(new Date(data.start_date).getTime() === new Date(data.end_date).getTime()) {
                errorTimer = true;
                setErrorTimeMessage('終了時刻は開始時刻以降より入力してください');
            } else {
                errorTimer = false;
                setErrorTimeMessage("");
            }
        }
        const mergeArray = _.merge(
            _.keyBy(worker_schedule_attributes, 'worker_id'),
            _.keyBy(newScheduleAttr, 'worker_id'),
        );
        const newArray = _.values(mergeArray);

        data.worker_schedules_attributes = newArray.map((item) => {
            if (item.existingOnForm && !item.id) {
                return {
                    worker_id: item.worker_id,
                };
            }
            if (!item.existingOnForm && item.id) {
                return {
                    _destroy: true,
                    id: item.id.toString(),
                    worker_id: item.worker_id,
                };
            }
            return {
                id: item.id.toString(),
                worker_id: item.worker_id,
            };
        });

        if (duplicate_apply_type) {
          data.duplicate_apply_type = duplicate_apply_type;
        }

        delete data.worker_schedule_attributes;

        if (data.duplicate_type === DuplicateTypeEnum.DEFAULT.value) {
          delete data.duplicate_end_date;
          delete data.max_duplicate;
          delete data.duplicate_options;
        }

        if (data.duplicate_options === DuplicateOptionsEnum.BY_CURRENTLY.value) {
          delete data.duplicate_end_date;
          delete data.max_duplicate;
        } else if (data.duplicate_options === DuplicateOptionsEnum.BY_ON_DATE.value) {
          delete data.max_duplicate;
        } else if (data.duplicate_options === DuplicateOptionsEnum.BY_MAX_DUPLICATE.value) {
          delete data.duplicate_end_date;
        }

        try {
            if (errorTimer) {
                setTimeout(() => {
                    setErrorTimeMessage("");
                }, 1500)
                return;
            } else {
                if (!editingId) {
                    // if ((isExistMeetingRoom && isSameTime)) {
                    //     setIsErrorMettingRoom(true);
                    //     setTimeout(() => {
                    //       setIsErrorMettingRoom(false);
                    //     }, 1500)
                    //     return;
                    // }
                    await scheduleService.createSchedule(data);
                } else {
                    await scheduleService.updateSchedule(editingId, data);
                }
            }
            props.setCurrentDate(new Date(data.start_date));
            if (props.currentViewName === "Month" || props.currentViewName === "List") {
              props.fetchSchedulesForMonthAndList();
            }
        } catch (error) {
          const errorMessage = error?.response?.data?.error;
          alertError(errorMessage);
        } finally {
          setSubmitLoading(false);
          props.setOpen(false);
          if (openUpdateRepeatScheduleDialog) {
            setOpenUpdateRepeatScheduleDialog(false);
          }
        }
    };

    const replaceNumbers = (str, newNumber1, newNumber2) => {
      let numbers = str.match(/\d+/g);
      if (numbers && numbers.length >= 2) {
          str = str.replace(numbers[0], newNumber1).replace(numbers[1], newNumber2);
      }
      return str;
    }

    useEffect(() => {
        if (editingId) {
            fetchSchedule();
        }
    }, [fetchSchedule]);

    useEffect(() => {
      if (formik.values.start_date) {
        const numWeekDay = new Date(formik.values.start_date).getDay();
        const month = new Date(formik.values.start_date).getMonth();
        const date = new Date(formik.values.start_date).getDate();
        const weekNumber = getWeekNumberInMonth(new Date(formik.values.start_date));
        setDuplicateTypes((prev) => prev.map((item) => {
          if (item.value === DuplicateTypeEnum.EVERY_WEEK_BY_START_TIME.value || item.value === DuplicateTypeEnum.EVERY_MONTH_BY_START_TIME.value) {
            const breakLabel = item.label.split(" ");
            breakLabel[1] = WEEKDAY_CONSTANT[numWeekDay];
            const newLabel = breakLabel.join(" ");
            item.label = newLabel;
          }
          if (item.value === DuplicateTypeEnum.EVERY_YEAR_BY_START_TIME.value) {
            const newLabel = replaceNumbers(item.label, month + 1, date);
            item.label = newLabel;
          }
          if (item.value === DuplicateTypeEnum.EVERY_MONTH_BY_START_TIME.value) {
            const breakLabel = item.label.split(" ");
            breakLabel[0] = breakLabel[0].replace(/.$/, JAPANESE_NUM[weekNumber - 1]);
            item.label = breakLabel.join(" ");
          }
          return item;
        }))
        
        const duplicateEndDate = new Date(formik.values.duplicate_end_date);
        const result = compareTwoDateObjects(formik.values.start_date as Date, duplicateEndDate);
        if (result === 1 || result === 0) {
          let tomorrowDate = new Date(formik.values.start_date);
          tomorrowDate.setDate(new Date(formik.values.start_date).getDate() + 1);
          formik.setFieldValue("duplicate_end_date", tomorrowDate);
        }
      } else {
        formik.setFieldValue("duplicate_end_date", new Date());
      }
    }, [formik.values.start_date]);

    const handleSetDuplicateEndDateLimit = ({ duplicateType, startDate }: { duplicateType: string, startDate: Date }) => {
      let maxDupEndDate;
      let maxDupNum;

      switch (duplicateType) {
        case DuplicateTypeEnum.EVERY_DAY.value:
          maxDupEndDate = getDateInNextYears(startDate, 3);
          maxDupNum = getDaysBetweenDates(startDate, maxDupEndDate);
          break;

        case DuplicateTypeEnum.EVERY_WEEK_BY_START_TIME.value:
          maxDupEndDate = getDateInNextYears(startDate, 3);
          maxDupNum = countSpecificDayBetweenDates(startDate, maxDupEndDate, startDate.getDay());
          break;

        case DuplicateTypeEnum.EVERY_DAY_IN_WEEK.value:
          maxDupEndDate = getDateInNextYears(startDate, 3);
          maxDupNum = countWeekdaysBetweenDates(startDate, maxDupEndDate);
          break;

        case DuplicateTypeEnum.EVERY_MONTH_BY_START_TIME.value:
          maxDupEndDate = getDateInNextYears(startDate, 10);
          maxDupNum = 120;
          break;
          
        case DuplicateTypeEnum.EVERY_YEAR_BY_START_TIME.value:
          maxDupEndDate = getDateInNextYears(startDate, 50);
          maxDupNum = 51;
          break;
          
        default:
          break;
      }
      setDuplicateEndDateMax(maxDupEndDate);
      setDuplicateMaxNum(maxDupNum);

      if (formik.values.max_duplicate > maxDupNum) {
        formik.setFieldValue("max_duplicate", maxDupNum);
      }
     
      const result = compareTwoDateObjects(new Date(formik.values.duplicate_end_date), maxDupEndDate);
      if (result === 1) {
        formik.setFieldValue("duplicate_end_date", maxDupEndDate);
      }
    }

    useEffect(() => {
      if (formik.values.start_date && formik.values.duplicate_type) {
        handleSetDuplicateEndDateLimit({
          duplicateType: formik.values.duplicate_type,
          startDate: new Date(formik.values.start_date),
        })
      }
    }, [formik.values.start_date, formik.values.duplicate_type]);

    const onChangeDuplicateEndDate = (date: Date) => {
      if (!date && formik.values.start_date) {
        let tomorrowDate = new Date(formik.values.start_date);
        tomorrowDate.setDate(new Date(formik.values.start_date).getDate() + 1);
        formik.setFieldValue("duplicate_end_date", tomorrowDate);
      } else if (!date && !formik.values.start_date) {
        formik.setFieldValue("duplicate_end_date", new Date());
      } else {
        formik.setFieldValue("duplicate_end_date", new Date(date));
      }
    };

    return {
        ...props,
        formik,
        editingId,
        isAllowShowNotPublic,
        isExecutive,
        isScheduleMaker,
        isErrorMeetingRoom,
        setIsErrorMettingRoom,
        errorTimeMessage,
        setErrorTimeMessage,
        duplicateTypes,
        isRepeatSchedule,
        openUpdateRepeatScheduleDialog,
        setOpenUpdateRepeatScheduleDialog,
        handleUpdateForRepeatSchedule,
        changedDuplicateType,
        setChangedDuplicateType,
        submitLoading,
        duplicateEndDateMax,
        setDuplicateEndDateMax,
        duplicateMaxNum,
        onChangeDuplicateEndDate,
    };
};

export type Props = ReturnType<typeof useCreateScheduleDialog>;

export default useCreateScheduleDialog;
