/*
 * function convert workers to dropdown options
 *
 */
export const convertWorkersToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    label: string;
    name: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        label: item.name,
        name: item.name,
    }));
};

// enabled with user has department = 役員 and email = "admin@kyowa.javis.vn"
export const isAllowAction = (userData: any) => {
    if (userData?.role === "admin") return true;

    if (!userData?.worker || !userData?.worker?.departments || userData?.worker?.departments?.length === 0) return false;

    return userData?.worker?.departments?.filter(department => department.name === '役員').length > 0;
}

// split text by character "/"
export const splitDepartment = (value: any) => {
    if (!value) return '';
    return value.replaceAll(',', '/');
}

// remove exist element
export const checkExist = (array1, array2) => {
    if (array1.length === 0) return [];

    let notExist = array1.filter(v => {
        return !array2.find(e => {
            return e.value == v.value;
        });
    });

    return notExist;
}

export const isUserGuest = (userData) => {
    return userData?.role === "guest";
}

export const isUserAdmin = (userData) => {
    return userData?.role === "admin";
}
