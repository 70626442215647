export const validate = (values) => {
    const errors: any = {};

    if (!values.worker_id) {
        errors.worker_id = '必須です。';
    }

    if (!values.title) {
        errors.title = '必須です。';
    }

    //if (!values.start_date) {
    //    errors.start_date = '必須です。';
    //}

    //if (!values.end_date) {
    //    errors.end_date = '必須です。';
    //}

    return errors;
};
