import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { RootState } from 'store';
import messageService from 'services/messageService';

export type ReceivedProps = {
    chatRoom: any;
};

const useChatBox = (props: ReceivedProps) => {
    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state.users);
    const workerId = (userData as any)?.worker?.id;

    const [listMessage, setListMessage] = useState<any[]>([]);
    const [listImageMessage, setListImageMessage] = useState<any[]>([]);
    const [updateChat, setUpdateChat] = useState<any>(false);
    const [removeText, setRemoveText] = useState<any>(false);
    const refInput = useRef<any>(null);
    const [message, setMessage] = useState<any>('');

    const removeImage = (idx) => {
        let current = [...listImageMessage];
        current = current.filter((item, index) => index !== idx);
        setListImageMessage(current);
    };

    const handleFileChange = async (e: any) => {
        if (e.target.files.length) {
            const files = Object.values(e.target.files);
            let current = [...listImageMessage];
            if (files.length > 0) {
                if (current.length > 0) {
                    current = current.concat(files);
                } else {
                    current = files;
                }
                setListImageMessage(current);
            }
            refInput.current.value = '';
        }
    };

    const onSubmit = async (response: any) => {
        if (!userData.worker) return;
        let formData = new FormData();
        formData.append('chat_id', props.chatRoom?.id);
        formData.append('worker_id', workerId);
        if (response) {
            formData.append('body', response ? response : 'test');
        }
        if (listImageMessage.length > 0) {
            for (var x = 0; x < listImageMessage.length; x++) {
                formData.append('images[]', listImageMessage[x]!);
            }
        }

        try {
            let data = await messageService.createMessage(formData);
            if (data) setUpdateChat(!updateChat);
            setMessage('');
        } catch (error) {
            //
        } finally {
            setListImageMessage([]);
            setRemoveText(!removeText);
        }
    };

    const handleReceived = async (response) => {
        try {
            const { data } = response;
            const { message } = await messageService.getMessagesById(data.id);
            setListMessage((preState) => [...preState, message]);
        } catch (error) {}
    };

    useEffect(() => {
        var elements = document.getElementsByClassName('rcw-input');
        Array.from(elements).forEach((element) => (element.innerHTML = ''));
    }, [removeText]);
    useEffect(() => {
        (async () => {
            try {
                if (props.chatRoom?.id) {
                    const { messages } = await messageService.getMessages({
                        page: 1,
                        per_page: 99999,
                        chat_id: props.chatRoom?.id,
                    });
                    setListMessage(orderBy(messages, 'id', 'asc'));
                }
            } catch (error) {
                //
            }
        })();
    }, [location.pathname, props.chatRoom?.id, updateChat]);

    return {
        ...props,
        workerId,
        listMessage,
        setListMessage,
        onSubmit,
        setListImageMessage,
        listImageMessage,
        handleFileChange,
        refInput,
        removeImage,
        setMessage,
        handleReceived,
        setUpdateChat,
        message,
        chatRoomId: props.chatRoom?.id,
    };
};

export type Props = ReturnType<typeof useChatBox>;

export default useChatBox;
